import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import SVGComponent from "./SVGComponent";
import ChatbotToggleTooltip from "./chatbotToggleTooltip/ChatbotToggleTooltip";
import { useEffect } from "react";
import PropTypes from "prop-types";
import i18n from "./i18";

export default function Chatbot(props) {
  var {
    firstName,
    lastName,
    profileId,
    facultyIRN,
    email,
    phoneNumber,
    ipAddress,
  } = props;
  var ChatWidget;

  const serviceName = process.env.REACT_APP_CHATBOT_SERVICENAME;

  const genesysCXBUSUrl = `${process.env.REACT_APP_MYPHX_BASE_URL}/genesys-chat/resources/js/cxbus.min.js`;
  // const genesysCXWidgetsScript = `${process.env.REACT_APP_MYPHX_BASE_URL}/genesys-chat/resources/js/widgets.min.js`;
  const genesysCXWidgetsScript = `${process.env.REACT_APP_MYPHX_BASE_URL}/genesys-chat/resources/js/widgets.min.original.js`;
  // const genesysWidgetsCustom= `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/css/widgets_override.css`;
  const genesysCXWidgetsStyles = `${process.env.REACT_APP_MYPHX_BASE_URL}/genesys-chat/resources/css/widgets.min.css`;
  const webChat_dataURL = `${process.env.REACT_APP_CHATBOT_WEBCHAT_URL}/genesys/2/chat/Chat`;
  const comet_URL = `${process.env.REACT_APP_CHATBOT_WEBCHAT_URL}/genesys/cometd`;
  const api_URL = `${process.env.REACT_APP_CHATBOT_WEBCHAT_URL}/genesys/2/chat/Chat`;
  const cobrowse_URL = `${process.env.REACT_APP_CHATBOT_COBROWSE_URL}/cobrowse/`;
  const cobrowseSrc = `${process.env.REACT_APP_MYPHX_BASE_URL}/genesys-chat/resources/js/gcb.min.js`;

  const loadGenesysScripCSS = () => {
    var option = {
      location: document,
      onload: false,
      aTags: [
        {
          type: "script",
          id: "genesys-cx-widgets-script",
          path: genesysCXWidgetsScript,
        },
        /*{
			  	type: "link",
			  	id: "genesys_widgets_custom",
			  	path: genesysWidgetsCustom
			},*/
        {
          type: "link",
          id: "genesys-cx-widgets-styles",
          path: genesysCXWidgetsStyles,
        },
      ],
    };

    // only script and css
    var loadFiles = () => {
      var doc = option.location;
      option.aTags = option.aTags || [];
      for (var i = 0; i < option.aTags.length; i++) {
        var oTag = option.aTags[i];
        var fs = doc.getElementsByTagName(oTag.type)[0];
        var e;

        if (doc.getElementById(oTag.id)) return;

        e = doc.createElement(oTag.type);
        e.id = oTag.id;

        if (oTag.type == "script") {
          e.src = oTag.path;
        } else {
          e.type = "text/css";
          e.rel = "stylesheet";
          e.href = oTag.path;
        }
        fs.parentNode.insertBefore(e, fs);
      }
    };
    loadFiles();
  };

  const configGenesysWidgetObject = () => {
    if (!window._genesys.widgets) {
      var i18JSON = i18n;

      window._genesys.widgets = {
        main: {
          debug: false,
          theme: "light",
          lang: "en",
          i18n: i18JSON, //"https://my-stage.phoenix.edu/genesys-chat/resources/genesys-cx.json",
          // customStylesheetID: "genesys_widgets_custom",
          mobileMode: "auto",
          mobileModeBreakpoint: 600,
          preload: [],
        },
        webchat: {
          dataURL: webChat_dataURL,
          apikey: "",
          actionsMenu: true,
          inviteOnRestoreTimeout: true,
          enableCustomHeader: false,
          confirmFormCloseEnabled: true,
          minimizeOnMobileRestore: true,
          maxMessageLength: 475,
          charCountEnabled: true,
          cometD: {
            enabled: true,
            cometURL: comet_URL,
            channel: "/service/chatV2/Chat",
            apiURL: api_URL,
            websocketEnabled: true,
            logLevel: "info",
          },
          emojis: true,
          autoInvite: {
            enabled: false,
            timeToInviteSeconds: 5,
            inviteTimeoutSeconds: 30,
          },
          chatButton: {
            enabled: false,
            openDelay: 1000,
            effectDuration: 300,
            hideDuringInvite: true,
          },
          uploadsEnabled: false,
          ajaxTimeout: 30000,
        },
      };

      window._genesys.widgets.onReady = function (CXBus) {
        if (!window.ChatWidget) {
          ChatWidget = CXBus.registerPlugin("ChatWidget");
          window.ChatWidget = ChatWidget;
        }
      };
    }
  };

  const getBrowser = () => {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }

    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem !== null) {
        return { name: "Opera", version: tem[1] };
      }
    }

    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    tem = ua.match(/version\/(\d+)/i);
    if (tem !== null) {
      M.splice(1, 1, tem[1]);
    }

    return {
      name: M[0],
      version: M[1],
    };
  };

  const getOS = (osVal) => {
    if (osVal) {
      if (osVal.includes("Win")) return "Windows";
      if (osVal.includes("Mac")) return "Mac";
      if (osVal.includes("Linux")) return "Linux";
    }
    return "";
  };

  const scrubPhoneNumber = (phoneNumber) => {
    const match = `${phoneNumber}`.replace(/\D/g, "").match(/.{1,10}$/);
    return match ? match[0] : "";
  };

  const startChat = () => {
    var browser = getBrowser();

    const formData = {
      autoSubmit: true,
      service: serviceName,
      firstname: firstName,
      lastname: lastName,
      email: email,
      currentOS: navigator.platform,
      Browser: browser.name,
      BrowserVersion: browser.version,
      IPAddress: ipAddress,
      profileId: profileId,
      facultyIRN: facultyIRN,
    };

    const userData = {
      service: serviceName,
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: email,
      PhoneNumber: scrubPhoneNumber(phoneNumber),
      facultyIRN: facultyIRN,
      profileId: profileId,
      SourceURL: window.location.href,
      BrowserVersion: browser.version,
      Browser: browser.name,
      OperatingSystem: getOS(navigator.platform),
      IPAddress: ipAddress,
      IdentifyCreateContact: 0,
      sessionId: "",
    };

    if (window.ChatWidget) {
      window._genesys.widgets.webchat.userData = userData;
      window.ChatWidget.command("WebChat.open", {
        userData,
        form: formData,
      });
    }
  };

  const handleChatbotClick = () => {
    startChat();
  };

  useEffect(() => {
    if (!window._genesys) window._genesys = {};
    if (!window._gt) window._gt = [];

    loadGenesysScripCSS();
    configGenesysWidgetObject();

    props.chatbotStartChatRef.current = startChat;
  }, []);

  // styled componenet
  const StyledTopDivContainer = styled("div")((props) => ({
    position: "fixed",
    right: "0%",
    bottom: "0%",
    paddingRight: props.theme.spacing(4.7),
    paddingBottom: props.theme.spacing(4.2),
    zIndex: "1",
  }));

  const StyledFab = styled((props) => {
    return (
      <Fab
        aria-label="Chat with Tech Support"
        onClick={() => handleChatbotClick()}
        {...props}
      >
        <SVGComponent></SVGComponent>
      </Fab>
    );
  })(({ theme }) => ({
    "&:hover": {
      background: "none",
    },
    backgroundColor: "transparent",
    boxShadow: "none",
  }));

  //------------------------------------------Main Returned JSX----------------------------------
  return (
    <StyledTopDivContainer role="region" aria-label="Chat Container">
      <ChatbotToggleTooltip>
        <div className={`mui-fixed`}>
          <StyledFab></StyledFab>
        </div>
      </ChatbotToggleTooltip>
    </StyledTopDivContainer>
  );
}

Chatbot.defaultProps = {
  email: null,
  ipAddress: undefined,
  phoneNumber: null,
};

Chatbot.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  facultyIRN: PropTypes.string.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  ipAddress: PropTypes.string,
};
