import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // This component is only used in Desktop View
  listItem: {
    // float: "left",
  },
  link: {
    textDecoration: "none",
  },
  card: {
    //display: "flex",
    //flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "257px",
    midWidth: "200px",
    height: "179px",
    radius: "4px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(0,0,0, 0.1)",
    boxRadius: "4px",
    padding: "0px 10px",
    // padding: "32px, 10px",
    // marginRight: "20px",
    padding: "0px 10px 0px 10px",
  },
  icon: {
    height: "72px",
    width: "72px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "17px",
    fontWeight: "700",
    color: "#001823",
    alignItems: "center",
  },
  subtext: {
    fontFamily: "Roboto",
    fontSize: "14px",
    marginTop: "2px",
    color: "#3A4F59",
    alignItems: "center",
  },
}));

export default useStyles;
