import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  mainContainer: {
    minWidth: "660px",
    marginBottom: "70px",
  },

  // Mobile View
  mainContainerMobile: {
    width: "327px",
    marginBottom: "57px",
  },
});
