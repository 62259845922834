import { Typography, Grid } from "@mui/material";
import useStyles from "./WorkshopFAQs.styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import FAQcard from "./FAQcard";

const WorkshopFAQs = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const setValueFAQ = () => {
    props.setValue(1)
  }


  return (
    <>
      <Grid
        container
        xs={10}
        md={12}
        className={matches ? "" : classes.mobileContainer}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          xs={8}
          sm={12}
          className={matches ? "" : classes.mobileTitle}
        >
          <Typography
            component="h2"
            sx={
              matches
                ? {
                    fontWeight: 700,
                    fontSize: "32px",
                    marginBottom: "40px",
                  }
                : {
                    fontWeight: 700,
                    fontSize: "24px",
                    marginBottom: "60px",
                  }
            }
          >{`Workshop FAQs`}</Typography>
        </Grid>

        <FAQcard question={"Why complete a workshop?"} />
        <FAQcard setValueFAQ= {setValueFAQ} question={"How do I register for a workshop?"} />
        <FAQcard question={"How do I access a workshop?"} />
        <FAQcard question={"How do I withdraw from a workshop?"} />
        <FAQcard question={"Can I reschedule a workshop?"} />
        <FAQcard
          question={
            "Where can I find other Professional Development opportunities?"
          }
        />
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    scheduler: state.scheduler,
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(WorkshopFAQs);
