import NewSolicitationCard from "./NewSolicitationCard";
import facultyPortalStyles from "../../../../styles";
import { useEffect, useState } from "react";
import {
  getSolicitations,
  getSolicitationsAccepted,
  getSolicitationsDeclined,
} from "../../../../redux/solicitations/solicitationsActions";
import NoSolicitationsCard from "./NoSolicitationsCard";
import { connect } from "react-redux";
import { getCurrentCourses } from "../../../../redux/currentCourses/currentCoursesActions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";
import { storeErrorMessages } from "../../../../redux/displayApiErrors/displayApiErrorsActions";

const SolicitationsContainer = (props) => {
  const facultyClasses = facultyPortalStyles();
  let count = 0;
  const { handleMessageDisplay } = props;

  const [openSuccessSolicitations, setopenSuccessSolicitations] =
    useState(false);
  const [
    openSuccessSolicitationsAccepted,
    setopenSuccessSolicitationsAccepted,
  ] = useState(false);
  const [
    openSuccessSolicitationsDeclined,
    setopenSuccessSolicitationsDeclined,
  ] = useState(false);
  const [openSuccessCurrentCourses, setopenSuccessCurrentCourses] =
    useState(false);
  const [openSuccess, setOpenSuccess] = useState(false)

  useEffect(() => {
    if (props.demographics.issuerId) {
      props.getSolicitations(props.demographics.issuerId);
      props.getSolicitationsAccepted(props.demographics.issuerId);
      props.getSolicitationsDeclined(props.demographics.issuerId);
      props.getCurrentCourses(props.demographics.issuerId);
      // ---------- below are FAKE IRNS for testing purposes -------------
      // props.getSolicitations("fakeIRN");
      // props.getSolicitationsAccepted("fakeIRN");
      // props.getSolicitationsDeclined("fakeIRN");
      // props.getCurrentCourses("fakeIRN");
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.solicitations.status !== undefined &&
      props.solicitations.status !== 200
    ) {
      props.storeErrorMessages("Solicitation information not found.", "solicitation")
    }
    else {
      setopenSuccessSolicitations(false);
      setOpenSuccess(false)
    }
  }, [props.solicitations.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.acceptedSolicitations.status !== undefined &&
      props.acceptedSolicitations.status !== 200
    ) {
      props.storeErrorMessages("Accepted Solicitation information not found.", "accepted solicitations")
    }
    else {
      setopenSuccessSolicitationsAccepted(false);
      setOpenSuccess(false)
    }
  }, [props.acceptedSolicitations.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.declinedSolicitations.status !== undefined &&
      props.declinedSolicitations.status !== 200
    ) {
      props.storeErrorMessages("Declined solicitation information not found.", "declined solicitations")
    }
    else {
      setopenSuccessSolicitationsDeclined(false);
      setOpenSuccess(false)
    }
  }, [props.declinedSolicitations.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.currentCourses.status !== undefined &&
      props.currentCourses.status !== 200
    ) {
      props.storeErrorMessages("Current solicitation information not found.", "current solicitations")
    }
    else {
      setopenSuccessCurrentCourses(false);
      setOpenSuccess(false)
    }
  }, [props.currentCourses.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(props.errorMessages.length === 1 && props.errorMessages[0].type.typeOfError === "solicitation") {
      setopenSuccessSolicitations(true);
      setOpenSuccess(true)
    }
    if(props.errorMessages.length === 1 && props.errorMessages[0].type.typeOfError === "accepted solicitations") {
      setopenSuccessSolicitationsAccepted(true);
      setOpenSuccess(true)
    }
    if(props.errorMessages.length === 1 && props.errorMessages[0].type.typeOfError === "declined solicitations") {
      setopenSuccessSolicitationsDeclined(true);
      setOpenSuccess(true)
    }
    if(props.errorMessages.length === 1 && props.errorMessages[0].type.typeOfError === "current solicitations") {
      setopenSuccessCurrentCourses(true);
      setOpenSuccess(true)
    }
  }, [props.errorMessages]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      if(props.solicitations.status !== 200) {
        props.getSolicitations(props.demographics.issuerId);
      }
      if(props.acceptedSolicitations.status !== 200) {
        props.getSolicitationsAccepted(props.demographics.issuerId);
      }
      if(props.declinedSolicitations.status !== 200) {
        props.getSolicitationsDeclined(props.demographics.issuerId);
      }
      if(props.currentCourses.status !== 200) {
        props.getCurrentCourses(props.demographics.issuerId);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <>
      {props.solicitations.length >= 1 ? (
        <ul className={facultyClasses.unorderedList}>
          {props.solicitations.map((solicitation) => (
            <>
              <li>
                <NewSolicitationCard
                  previewMaterialsLinkId={`previewCourseMaterials${++count}`}
                  responseId={`responseNotification${count}`}
                  key={`${solicitation.txId}`}
                  data-testid="solicitation"
                  title="NEW SOLICITATION"
                  classTitle={`${solicitation.courseTitle}`}
                  classNum={`${solicitation.courseId}`}
                  startDate={`${solicitation.startDate}`}
                  endDate={`${solicitation.endDate}`}
                  date="Tuesday @ 6:00PM EDT"
                  campus={`${solicitation.orgName}`}
                  role={`${solicitation.schedulingRole}`}
                  card="solicitation"
                  facultyScheduleId={`${solicitation.facultyScheduleId}`}
                  handleMessageDisplay={handleMessageDisplay}
                  isCbedaCourse={solicitation.cbeda}
                />
              </li>
            </>
          ))}
        </ul>
      ) : (
        <NoSolicitationsCard
          content="You have no new solicitations at this time."
          linkName="Set Course Preferences"
          linkPath="/coursepreferences"
        />
      )}

      {props.acceptedSolicitations.length >= 1 ? (
        <ul className={facultyClasses.unorderedList}>
          {props.acceptedSolicitations.map((solicitation) => (
            <>
              <li>
                <NewSolicitationCard
                  previewMaterialsLinkId={`previewCourseMaterials${++count}`}
                  responseId={`responseNotification${count}`}
                  key={`${solicitation.txId}`}
                  data-testid="solicitation"
                  title="SOLICITATION"
                  classTitle={`${solicitation.courseTitle}`}
                  classNum={`${solicitation.courseId}`}
                  startDate={`${solicitation.startDate}`}
                  endDate={`${solicitation.endDate}`}
                  date="Tuesday @ 6:00PM EDT"
                  campus={`${solicitation.orgName}`}
                  role={`${solicitation.schedulingRole}`}
                  card="solicitation"
                  status={`${solicitation.statusDescription}`}
                  facultyScheduleId={`${solicitation.facultyScheduleId}`}
                  handleMessageDisplay={handleMessageDisplay}
                  isCbedaCourse={solicitation.cbeda}
                />
              </li>
            </>
          ))}
        </ul>
      ) : (
        ""
      )}
      {props.currentCourses.length >= 1 ? (
        <ul className={facultyClasses.unorderedList}>
          {props.currentCourses.map((course) => (
            <>
              <li>
                <NewSolicitationCard
                  previewMaterialsLinkId={`previewCourseMaterials${++count}`}
                  responseId={`responseNotification${count}`}
                  key={`${course.txId}`}
                  data-testid="solicitation"
                  title="SOLICITATION"
                  classTitle={`${course.courseTitle}`}
                  classNum={`${course.courseId}`}
                  startDate={`${course.startDate}`}
                  endDate={`${course.endDate}`}
                  date="Tuesday @ 6:00PM EDT"
                  campus={`${course.orgName}`}
                  role={`${course.schedulingRole}`}
                  card="solicitation"
                  status={`${course.statusDescription}`}
                  facultyScheduleId={`${course.facultyScheduleId}`}
                  handleMessageDisplay={handleMessageDisplay}
                  isCbedaCourse={course.cbeda}
                />
              </li>
            </>
          ))}
        </ul>
      ) : (
        ""
      )}
      {props.declinedSolicitations.length >= 1 ? (
        <ul className={facultyClasses.unorderedList}>
          {props.declinedSolicitations.map((solicitation) => (
            <>
              <li>
                <NewSolicitationCard
                  previewMaterialsLinkId={`previewCourseMaterials${++count}`}
                  responseId={`responseNotification${count}`}
                  key={`${solicitation.txId}`}
                  data-testid="solicitation"
                  title="SOLICITATION"
                  classTitle={`${solicitation.courseTitle}`}
                  classNum={`${solicitation.courseId}`}
                  startDate={`${solicitation.startDate}`}
                  endDate={`${solicitation.endDate}`}
                  date="Tuesday @ 6:00PM EDT"
                  campus={`${solicitation.orgName}`}
                  role={`${solicitation.schedulingRole}`}
                  card="solicitation"
                  status={`${solicitation.statusDescription}`}
                  reason={`${solicitation.solicitReasonDesc}`}
                  facultyScheduleId={`${solicitation.facultyScheduleId}`}
                  handleMessageDisplay={handleMessageDisplay}
                  isCbedaCourse={solicitation.cbeda}
                />
              </li>
            </>
          ))}
        </ul>
      ) : (
        ""
      )}

      {/* ------------below is dummy card for testing purposes----------------- */}
      {/* <NewSolicitationCard
        previewMaterialsLinkId={`previewCourseMaterials${++count}`}
        responseId={`responseNotification${count}`}
        key={0}
        data-testid="solicitation"
        title="NEW SOLICITATION"
        classTitle={"Test Class"}
        classNum={"CAL/102"}
        startDate={"July"}
        endDate={"March"}
        date="Tuesday @ 6:00PM EDT"
        campus={"online"}
        role={"Primary"}
        card="solicitation"
        facultyScheduleId={326536}
        handleMessageDisplay={handleMessageDisplay}
      /> */}

      {openSuccessSolicitations && (
        (props.errorMessages.length === 1) ? (
          renderSnackbar(
             "error",
             "Failed to retrieve Solicitations info!"
             ))
         : "")}
      {openSuccessSolicitationsAccepted && (
        (props.errorMessages.length === 1) ? (
          renderSnackbar(
             "error",
             "Failed to retrieve accepted Solicitations info!"
             ))
         : "")}
      {openSuccessSolicitationsDeclined && (
        (props.errorMessages.length === 1) ? (
          renderSnackbar(
             "error",
             "Failed to retrieve declined Solicitations info!"
             ))
         : "")}
      {openSuccessCurrentCourses && (
        (props.errorMessages.length === 1) ? (
          renderSnackbar(
             "error",
             "Failed to retrieve confirmed solicitations info!"
             ))
         : "")}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    solicitations: state.solicitations,
    acceptedSolicitations: state.acceptedSolicitations,
    declinedSolicitations: state.declinedSolicitations,
    currentCourses: state.currentCourses,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSolicitations: (irn) => dispatch(getSolicitations(irn)),
    getSolicitationsAccepted: (irn) => dispatch(getSolicitationsAccepted(irn)),
    getSolicitationsDeclined: (irn) => dispatch(getSolicitationsDeclined(irn)),
    getCurrentCourses: (irn) => dispatch(getCurrentCourses(irn)),
    storeErrorMessages: (message, typeOfError) => dispatch(storeErrorMessages(message, typeOfError))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationsContainer);
