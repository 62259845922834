import { GET_SUPERVISOR } from "./actionTypes";
import { getFacultySupervisor } from "../../services/faculty/v1/faculty";

export const getSupervisor = (irn) => async (dispatch) => {
  await getFacultySupervisor(irn)
    .then((response) => {
      dispatch({ type: GET_SUPERVISOR, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_SUPERVISOR, payload: error.request });
    });
};
