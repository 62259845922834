import { v4 as uuidv4 } from 'uuid';

const xCorrelationIdName = 'X-Correlation-Id';
const xRequestIdName = 'X-Request-Id';
const xCorrelationId = sessionStorage.getItem(xCorrelationIdName) || sessionStorage.getItem('mcId') || uuidv4();
sessionStorage.setItem(xCorrelationIdName, xCorrelationId);

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default config => {
    const configUpdate = config;
    configUpdate.headers[xCorrelationIdName] = xCorrelationId;
    configUpdate.headers[xRequestIdName] = uuidv4();

    return configUpdate;
};