import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  linkBox: {
    marginLeft: "5%",
    paddingTop: "2%",
    width: "220px",
    height: "24px",
  },
  backContainer: {
    display: "flex",
    width: "230px",
    whiteSpace: "nowrap",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "1100px",
    margin: "0px auto",
  },
  pageTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "3%",
    marginBottom: "5%"
  },
  mainContent: {
    display: "flex",
    marginBottom: "90px",
  },
  cardContainer: {
    margin: "0px 20px 0px 0px",
  },

  // Mobile View
  linkBoxMobile: {
    width: "100%",
    marginBottom: "37px",
    marginLeft: "26px",
  },
  backButtonAndText: {
    display: "flex",
  },
  backTextMobile: {
    position: "relative",
    right: "-6px",
  },
  mainContainerMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pageTitleMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
  },

  // Universal for Desktop and Mobile View
  arrowImage: {
    position: "relative",
    height: "24px",
    width: "27px",
    marginRight: "10px",
  },
});
