import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import * as sortByFunctions from "../../../../utils/courseHistorySortFunctions";
import { connect } from "react-redux";
import {
  getCourseHistory,
  setModifiedCourseList,
} from "../../../../redux/courseHistory/courseHistoryActions";
import {
  FormControl,
  MenuItem,
  FormHelperText,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { TextField } from "@mui/material";

const useStyles = makeStyles({
  // Desktop View
  sortByContainer: {
    width: "170px",
    height: "24px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 5px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "25px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000000",
      borderRadius: "1px",
      boxSizing: "border-box",
    },
  },

  // Mobile View
  sortByContainerMobile: {
    width: "100%",
    height: "24px",
    background: "#FFFFFF",
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 5px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "25px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000000",
      borderRadius: "1px",
      boxSizing: "border-box",
    },
  },

  // Universal for both Desktop and Mobile View
  formHelper: {
    color: "#5E7078",
    fontSize: "12px",
    lineHeight: "25px",
  },
});

const SortByBar = (props) => {
  // Allow for MUI styling
  const classes = useStyles();

  // Used useTheme and useMediaQuery to control mobile vs. desktop view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  // state to control sort selection and performance
  const [sortBy, setSortBy] = useState("");
  const [selectExpand, setSelectExpand] = useState(false);

  // array of objects for all sort by options
  const sortByChoices = [
    { id: 1, value: "idAscending", label: "Course ID Ascending" },
    { id: 2, value: "idDescending", label: "Course ID Descending" },
    { id: 3, value: "dateAscending", label: "Course Dates Ascending" },
    { id: 4, value: "dateDescending", label: "Course Dates Descending" },
    { id: 5, value: "sortRole", label: "Course Role" },
    { id: 6, value: "sortCampus", label: "Course Campus" },
  ];

  useEffect(() => {
    switch (sortBy) {
      case "Course ID Ascending":
        props.setModifiedCourseList(
          sortByFunctions.sortByCourseIdAscending(props.modifiedCourseHistory)
        );
        break;
      case "Course ID Descending":
        props.setModifiedCourseList(
          sortByFunctions.sortByCourseIdDescending(props.modifiedCourseHistory)
        );
        break;
      case "Course Dates Ascending":
        props.setModifiedCourseList(
          sortByFunctions.sortByCourseDateAscending(props.modifiedCourseHistory)
        );
        break;
      case "Course Dates Descending":
        props.setModifiedCourseList(
          sortByFunctions.sortByCourseDateDescending(
            props.modifiedCourseHistory
          )
        );
        break;
      case "Course Role":
        props.setModifiedCourseList(
          sortByFunctions.sortByCourseRole(props.modifiedCourseHistory)
        );
        break;
      case "Course Campus":
        props.setModifiedCourseList(
          sortByFunctions.sortByCampus(
            props.modifiedCourseHistory
          )
        );
        break;
      default:
        return props.modifiedCourseHistory;
    }
  }, [sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormControl>
        <FormHelperText className={classes.formHelper}>
          {`Sort by`}
        </FormHelperText>
        <TextField
          select
          className={
            matches ? classes.sortByContainer : classes.sortByContainerMobile
          }
          role="combobox"
          aria-label="sort-options-select-bar"
          aria-expanded={selectExpand}
          // onOpen={() => {
          //   setSelectExpand(true)
          // }}
          onClose={() => {
            setSelectExpand(false);
          }}
          id="sortSelectBar"
          data-testid="sortBar"
          variant="outlined"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          classes={{
            root: classes.sortRoot,
            select: classes.sortSelect,
          }}
        >
          {sortByChoices.map((item) => {
            return (
              <MenuItem
                data-testid="menuItem"
                className={classes.menuItem}
                key={item.id}
                value={item.label}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    courseHistory: state.courseHistory,
    modifiedCourseHistory: state.modifiedCourseHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCourseHistory: (irn) => dispatch(getCourseHistory(irn)),
    setModifiedCourseList: (courses) =>
      dispatch(setModifiedCourseList(courses)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortByBar);
