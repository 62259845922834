import styles from "../styles";
import facultyPortalStyles from "../../../styles";
import Box from "@mui/system/Box/Box";
import { Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Typography, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import LinkBlock from "../../../components/footer/linkBlock/LinkBlock";
import HelpCard from "../components/HelpCard";
import Working from "../../../images/Working.svg";
import AdultEducation from "../../../images/AdultEducation.svg";
import HumanServices from "../../../images/HumanServices.svg";
import SupportIcon from "../../../images/Support.svg";
import BlackArrow from "../../../images/BlackArrow.svg";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";

const HelpPage = (props) => {
  const classes = styles();
  const facultyClasses = facultyPortalStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));
  document.title = "Faculty Portal Help Page";

  const [openSuccess, setOpenSuccess] = useState(false);

  const connectBlock = [
    {
      item: "Faculty Contacts",
      link: `${process.env.REACT_APP_FACULTY_CONTACTS_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Help Desk",
      link: `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Email Student Services",
      link: `${process.env.REACT_APP_EMAIL_STUDENT_SERVICES_URL}`,
      authorization: true,
      display: true,
    },
  ];
  const engagementBlock = [
    {
      item: "University Events and Initiatives",
      link: `${process.env.REACT_APP_PHOENIXVIEW_FACULTY_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Instructional Resources",
      link: `${process.env.REACT_APP_FACULTY_RESOURCES_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Development Opportunities",
      link: `${process.env.REACT_APP_FACULTY_RESOURCES_URL}`,
      authorization: true,
      display: true,
    },
  ];
  const policyBlock = [
    {
      item: "HR Resources and Policies",
      link: `${process.env.REACT_APP_HUMAN_RESOURCES_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Diversity, Equity, Inclusion, and Belonging",
      link: `${process.env.REACT_APP_DIVERSITY_EQUITY_INCLUSION_AND_BELONGING_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Handbook",
      link: `${process.env.REACT_APP_FACULTY_HANDBOOK_URL}`,
      authorization: true,
      display: true,
    },
  ];

  useEffect(() => {
    if (props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        id="main"
        role="main"
        className={
          matches
            ? facultyClasses.pageContainer
            : facultyClasses.pageContainerMobile
        }
      >
        <Grid
          container
          className={matches ? classes.linkBox : classes.linkBoxMobile}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Link
            underline="none"
            sx={{
              color: "#001823",
              display: "flex",
              "&hover": {
                cursor: "pointer",
              },
            }}
            href="/"
          >
            <img className={classes.arrowImage} src={BlackArrow} alt="" />
            <Typography>{`Back to Course Dashboard`}</Typography>
          </Link>
        </Grid>

        <Grid
          className={matches ? classes.mainContent : classes.mainContentMobile}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="column"
            sm={12}
            xs={11}
            className={
              matches
                ? classes.pageHeaderBox
                : tabletMatches
                ? classes.pageHeaderBoxTablet
                : classes.pageHeaderBoxMobile
            }
          >
            <Grid item sm={8}>
              <Typography
                className={
                  matches
                    ? classes.helpPageHeader
                    : tabletMatches
                    ? classes.helpPageHeaderTablet
                    : classes.helpPageHeader
                }
                component="h1"
              >{`Faculty Help & Support`}</Typography>
            </Grid>
            <Grid item sm={8} md={6}>
              <Typography
                component="p"
                className={
                  !matches && !tabletMatches
                    ? classes.pageDescriptionMobile
                    : classes.pageDescription
                }
              >
                {`Get suppport and answers to all of your questions.
                  Locate or talk to someone about development opportunities, policies, and more.`}
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <hr className={classes.lineBreak} />
            </Grid>
          </Grid>

          <Grid
            className={
              matches
                ? classes.helpCardContainer
                : tabletMatches
                ? classes.helpCardContainerTablet
                : classes.helpCardContainerMobile
            }
            container
            spacing={matches ? 2 : 0}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              md={6}
              sm={10}
              xs={12}
              className={
                matches ? "" : tabletMatches ? classes.helpCardTablet : ""
              }
            >
              <HelpCard
                title={"Discover Resources"}
                subtext={
                  "Your place to find instructional resources, development opportunities, and more."
                }
                linkText={"Faculty Resources Center"}
                altText={"AdultEducation"}
                link={process.env.REACT_APP_FACULTY_RESOURCES_URL}
                icon={AdultEducation}
                biggerIcon={true}
                ariaId={"facultyResourcesCenter"}
                ariaRead={"Faculty Resources Center"}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={10}
              xs={12}
              className={
                matches ? "" : tabletMatches ? classes.helpCardTablet : ""
              }
            >
              <HelpCard
                title={"Get Involved"}
                subtext={
                  "Stay up to date on university initiatives, learn about events, and connect with colleagues."
                }
                linkText={"PhoenixView Faculty"}
                altText={"Working"}
                link={process.env.REACT_APP_PHOENIXVIEW_FACULTY_URL}
                icon={Working}
                biggerIcon={true}
                ariaId={"phoenixViewFaculty"}
                ariaRead={"PhoenixView Faculty"}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={10}
              xs={12}
              className={
                matches ? "" : tabletMatches ? classes.helpCardTablet : ""
              }
            >
              <HelpCard
                title={"Stay Connected"}
                subtext={
                  "Join communities of interest, celebrate peer achievements, and offer your perpective."
                }
                linkText={"Viva Engage"}
                altText={"HumanServices"}
                link={process.env.REACT_APP_VIVA_ENGAGE_URL}
                icon={HumanServices}
                ariaId={"Viva Engage"}
                ariaRead={"Viva Engage"}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={10}
              xs={12}
              className={
                matches ? "" : tabletMatches ? classes.helpCardTablet : ""
              }
            >
              <HelpCard
                title={"Ask for Help"}
                subtext={
                  "If you can’t find what you’re looking for, you can always reach out to your Faculty Supervisor."
                }
                linkText={"Email Your Faculty Supervisor"}
                altText={"SupportIcon"}
                link={
                  props.supervisor.businessEmail === null ||
                  props.supervisor.businessEmail === undefined
                    ? `mailto:FacultyHelpDesk@Phoenix.edu`
                    : `mailto:${props.supervisor.businessEmail}`
                }
                icon={SupportIcon}
                supervisorCard={true}
                ariaId={"emailYourFacultySupervisor"}
                ariaRead={"Open Email To Your Faculty Supervisor"}
              />
            </Grid>
          </Grid>
          <Grid
            container
            columns={1}
            spacing={matches ? 6 : tabletMatches ? 0 : 6}
            className={
              matches
                ? classes.linksContainer
                : tabletMatches
                ? classes.linksContainerTablet
                : classes.linksContainerMobile
            }
          >
            <Grid
              item
              md={4}
              sm={5}
              xs={12}
              className={matches ? "" : classes.linkSectionMobile}
            >
              <LinkBlock
                title={"Helpful Contacts"}
                listItem={connectBlock}
                helpBlock={true}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={5}
              xs={12}
              className={
                matches ? classes.communitySection : classes.linkSectionMobile
              }
            >
              <LinkBlock
                title={"Community Engagement"}
                listItem={engagementBlock}
                helpBlock={true}
                sx={{
                  marginBottom: "50px",
                }}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={5}
              xs={12}
              className={
                matches
                  ? ""
                  : tabletMatches
                  ? classes.policySectionTablet
                  : classes.linkSectionMobile
              }
            >
              <LinkBlock
                title={"Policies and Procedures"}
                listItem={policyBlock}
                helpBlock={true}
              />
            </Grid>
            <Grid
              item
              md={4}
              sm={5}
              xs={12}
              className={
                matches
                  ? classes.hideGrid
                  : tabletMatches
                  ? classes.policySectionTablet
                  : classes.hideGrid
              }
            ></Grid>
          </Grid>
        </Grid>
      </Box>
      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    supervisor: state.supervisor,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
  };
};

export default connect(mapStateToProps)(HelpPage);
