import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // This component is only used in Desktop View
  footerStyle: {
    backgroundColor: "#E2E6E7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "103px",
    // width: "534px",
    borderTop: "solid 1px #98A3A9",
  },
  mainContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65px",
  },
  leftBox: {
    textAlign: "center",
  },
  dividerColor: {
    borderColor: "#98A3A9 !important",
  },
  centerBox: {
    textAlign: "center",
  },
  rightBox: {
    textAlign: "center",
  },
});
