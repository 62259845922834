import { GET_SCHEDULER } from "./actionTypes";
import { getFacultyScheduler } from "../../services/faculty/v1/faculty";

export const getScheduler = (irn) => async (dispatch) => {
  await getFacultyScheduler(irn)
    .then((response) => {
      dispatch({ type: GET_SCHEDULER, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_SCHEDULER, payload: error.request });
    });
};
