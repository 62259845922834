import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  linkBox: {
    marginTop: "2%",
    height: "24px",
    width: "230px",
    marginLeft: "5%",
  },
  mainContent: {
    justifyContent: "center",
    margin: "0 auto",
  },
  title: {
    display: "flex",
    justifyContent: "center",
  },
  subContent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
    marginBottom: "5%",
  },

  // Mobile Styling Below

  linkBoxMobile: {
    width: "225px",
    marginBottom: "37px",
    marginLeft: "18px",
  },
  mainContentMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
  },
  mobileTitle: {
    marginBottom: "30px",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "28px",
  },
  mobileSubContent: {
    justifyContent: "center",
  },
  mobileCardContainer: {
    marginTop: "48px",
    marginBottom: "80px",
  },

  // Universal for Desktop and Mobile View
  arrowImage: {
    position: "relative",
    width: "27px",
    height: "24px",
    marginRight: "2px",
  },
}));

export default useStyles;
