import { getFeocsSurveyLink } from "../../services/sfapi/v1/sfApiFeocs";
import { GET_FEOCSINVITE } from "./actionTypes";

export const getFeocsInvite = (irn) => async (dispatch) => {
  await  getFeocsSurveyLink(irn)
    .then((response) => {
      dispatch({ type: GET_FEOCSINVITE, payload: response.data });
    })

    .catch((error) => {
      dispatch({
        type: GET_FEOCSINVITE,
        payload: error.request,
      });
    });
};