import React from "react";
import useStyles from "./Logo.styles";
import { Typography } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import uopxLogo from "../../images/uopx-logo.png";

const Logo = () => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Typography
      className={matches ? classes.logoContainer : classes.logoContainerMobile}
      component="h2"
      data-testid="phoenixLogoFooter"
    >
      <img
        src={uopxLogo}
        alt="University of Phoenix Logo"
        className={classes.uopxImage}
      />
      {`MyPhoenix Faculty`}
    </Typography>
  );
};

export default Logo;
