import { getFacultySolicitationsDeclineReasons } from "../../services/faculty/v1/faculty";
import { GET_SOLICITATIONS_DECLINE_REASONS } from "./actionTypes";

export const getFacultyDeclineReasons = () => async (dispatch) => {
  await getFacultySolicitationsDeclineReasons()
    .then((response) => {
      dispatch({
        type: GET_SOLICITATIONS_DECLINE_REASONS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_SOLICITATIONS_DECLINE_REASONS,
        payload: error.request,
      });
    });
};
