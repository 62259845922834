import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  infoContainer: {
    width: "100%",
    dipslay: "flex",
    justifyContent: "space-between",
    margin: "5px 10px 5px 10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconContainer: {
    width: "7%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "5px 5px 5px 10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkBox: {
    width: "120px",
    display: "flex",
    justifyContent: "flex-start",
  },

  // Mobile View
  infoContainerMobile: {
    width: "85%",
    dipslay: "flex",
    justifyContent: "space-between",
    margin: "5px 10px 5px 10px",
  },
  iconContainerMobile: {
    width: "15%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkContainerMobile: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "5px 10px 5px 10px",
  },

  // Universal for both Desktop and Mobile View
  expandIconRoot: {
    color: "#DC3727",
  },
  expandIconRootExpanded: {
    color: "#DC3727",
    transform: "rotate(0.5turn)",
  },
});
