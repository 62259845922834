import {
  GET_COURSE_HISTORY,
  SET_MODIFIED_COURSE_LIST,
} from "./actionTypes";
import { getFacultyCourseHistory } from "../../services/faculty/v1/faculty";

export const getCourseHistory = (irn) => async (dispatch) => {
  await getFacultyCourseHistory(irn)
    .then((response) => {
      dispatch({ type: GET_COURSE_HISTORY, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_COURSE_HISTORY, payload: error.request })
    });
};

export const setModifiedCourseList = (courses) => async (dispatch) => {
  dispatch({ type: SET_MODIFIED_COURSE_LIST, payload: courses });
};