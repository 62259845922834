import {
  acceptFacultySolicitations,
  getAcceptedSolicitations,
  getFacultySolicitations,
  declineFacultySolicitations,
  getDeclinedSolicitations,
} from "../../services/faculty/v1/faculty";

import {
  ACCEPTED_COURSES_SOLICITATIONS,
  GET_SOLICITATIONS,
  DECLINED_COURSES_SOLICITATIONS,
  ACCEPT_SOLICITATIONS,
  DECLINE_SOLICITATIONS,
} from "./actionTypes";

export const getSolicitations = (irn) => async (dispatch) => {
  await getFacultySolicitations(irn)
    .then((response) => {
      dispatch({ type: GET_SOLICITATIONS, payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: GET_SOLICITATIONS,
        payload: error.request,
      });
    });
};

export const getSolicitationsAccepted = (irn) => async (dispatch) => {
  await getAcceptedSolicitations(irn)
    .then((response) => {
      dispatch({
        type: ACCEPTED_COURSES_SOLICITATIONS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACCEPTED_COURSES_SOLICITATIONS,
        payload: error.request,
      });
    });
};

export const getSolicitationsDeclined = (irn) => async (dispatch) => {
  await getDeclinedSolicitations(irn)
    .then((response) => {
      dispatch({
        type: DECLINED_COURSES_SOLICITATIONS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: DECLINED_COURSES_SOLICITATIONS,
        payload: error.request,
      });
    });
};

export const acceptSolicitations =
  (facultyScheduleId, statusDescription) => async (dispatch) => {
    await acceptFacultySolicitations(facultyScheduleId, statusDescription)
      .then((result) => {
        const success = {
          status: result.request.status,
          message: "Success! Solicitation Accepted",
          success: true,
        };
        dispatch({ type: ACCEPT_SOLICITATIONS, payload: success });
      })
      .catch((error) => {
        const catchError = {
          status: error.request.status,
          message: "Oops! Your solicitation response wasn't saved.",
          success: false,
        };
        dispatch({ type: ACCEPT_SOLICITATIONS, payload: catchError });
      });
  };

export const declineSolicitations =
  (facultyScheduleId, reasonForDecline, statusDescription) =>
  async (dispatch) => {
    await declineFacultySolicitations(
      facultyScheduleId,
      reasonForDecline,
      statusDescription
    )
      .then((result) => {
        const success = {
          status: result.request.status,
          message: "Got it! Solicitation declined.",
          success: true,
        };
        dispatch({ type: DECLINE_SOLICITATIONS, payload: success });
      })
      .catch((error) => {
        const catchError = {
          status: error.request.status,
          message: "Oops! Your solicitation response wasn't saved.",
          success: false,
        };
        dispatch({ type: DECLINE_SOLICITATIONS, payload: catchError });
      });
  };