import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    minWidth: "500px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16.7501px",
    lineHeight: "20px",
    color: "#001823",
    marginLeft: "25px",
  },
  uopxImage: {
    height: "48px",
    width: "48px",
  },
  logoContainerMobile: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    minWidth: "641px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16.7501px",
    lineHeight: "20px",
    color: "#001823",
    marginLeft: "5px",
    marginBottom: "33px",
  },
}));

export default useStyles;
