import userManager from './userManager';

const {
   REACT_APP_OAUTH_COGNITO_LOGOUT,
   REACT_APP_AUTHDEPOT_LOGOUT,
   REACT_APP_LOGOUT_URL,
   REACT_APP_OAUTH_CLIENT_ID,
} = process.env;

export const logout = () => {
   userManager.revokeAccessToken();
   window.location.replace(`${REACT_APP_OAUTH_COGNITO_LOGOUT}?client_id=${REACT_APP_OAUTH_CLIENT_ID}&logout_uri=${REACT_APP_AUTHDEPOT_LOGOUT}?callback=${REACT_APP_LOGOUT_URL}`);
   userManager.removeUser();
};