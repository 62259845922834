import { GET_SUPERVISOR } from "./actionTypes";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
   switch(action.type) {
      case GET_SUPERVISOR:
         return action.payload;
      default:
         return state;  
   }
 }