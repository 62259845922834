import React from "react";
import useStyles from "./QuickLinkCard.styles";
import { Typography, Card, Grid } from "@material-ui/core";

const QuickLinkCard = ({ title, subtext, link, icon }) => {
  const classes = useStyles();

  return (
    <Grid item xs={4} className={classes.listItem}>
        <a
          data-testid="link"
          href={link}
          target="_blank"
          className={classes.link}
        >
            <Grid container direction="column" className={classes.card}>   
              <Grid item>
                <img
                  data-testid="icon"
                  src={icon}
                  alt=""
                  role="presentation"
                  className={classes.icon}
                />
                </Grid>
                <Grid item >
                  <Typography
                    data-testid="title"
                    className={classes.title}
                    component="h3"
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid item >
                  <Typography
                    data-testid="subtext"
                    className={classes.subtext}
                    component="p"
                  >
                    {subtext}
                  </Typography>
                </Grid>
              </Grid>
        </a>
    </Grid>
  );
};

export default QuickLinkCard;
