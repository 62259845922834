const i18n = {
  en: {
    callback: {
      CallbackTitle: "Receive a Call",
      CancelButtonText: "Cancel",
      ConfirmButtonText: "Confirm",
      CallbackFirstName: "First Name",
      CallbackPlaceholderRequired: "Required",
      CallbackPlaceholderOptional: "Optional",
      CallbackLastName: "Last Name",
      CallbackPhoneNumber: "Phone",
      CallbackQuestion: "When should we call you?",
      CallbackDayLabels: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      CallbackMonthLabels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      CallbackConfirmDescription: "You're booked in!",
      CallbackNumberDescription: "We will call you at the number provided:",
      CallbackNotes: "Notes",
      CallbackDone: "Close",
      CallbackOk: "Okay",
      CallbackCloseConfirm:
        "Are you sure you want to cancel arranging this callback?",
      CallbackNoButtonText: "No",
      CallbackYesButtonText: "Yes",
      CallbackWaitTime: "Wait Time",
      CallbackWaiTimeText: "min wait",
      CallbackOptionASAP: "As soon as possible",
      CallbackOptionPickDateTime: "Pick date & time",
      CallbackPlaceholderCalendar: "Select Date & Time",
      AriaMinimize: "Callback minimize",
      AriaMaximize: "Callback Maximize",
      AriaClose: "Callback close",
      Errors: {
        501: "Invalid parameters cannot be accepted, please check the supporting server API documentation for valid parameters.",
        503: "Missing apikey, please ensure it is configured properly.",
        1103: "Missing apikey, please ensure it is configured properly.",
        7030: "Please enter a valid phone number.",
        7036: "Callback to this number is not possible. Please retry with another phone number.",
        7037: "Callback to this number is not allowed. Please retry with another phone number.",
        7040: "Please configure a valid service name.",
        7041: "Too many requests at this time.",
        7042: "Office closed. Please try scheduling with in the office hours.",
        unknownError:
          "Something went wrong, we apologize for the inconvenience. Please check your connection settings and try again.",
        phoneNumberRequired: "Phone number is required.",
      },
    },
    cobrowse: {
      agentJoined: "Representative has joined the session",
      youLeft: "You have left the session. Co-browse is now terminated.",
      sessionTimedOut: "Session timed out. Co-browse is now terminated.",
      sessionInactiveTimedOut:
        "Session timed out. Co-browse is now terminated.",
      agentLeft:
        "Representative has left the session. Co-browse is now terminated.",
      sessionError: "Unexpected error occurred. Co-browse is now terminated.",
      sessionStarted:
        "Co-browse session started. Waiting for representative to connect to the session…",
      navRefresh: "Representative has refreshed the page. Reloading.",
      navBack: 'Representative has pressed the "Back" button. Reloading page.',
      navForward:
        'Representative has pressed the "Forward" button. Reloading page.',
      navUrl: "Representative has requested navigation. Reloading page.",
      navFailed: "Navigation request by representative has failed.",
      toolbarContent: "Session ID: {sessionId}",
      contentMasked: "Content is hidden from representative",
      contentMaskedPartially: "Some content is hidden from representative",
      exitBtnTitle: "Exit Co-browse session",
      areYouOnPhone: "Are you on the phone with our representative?",
      areYouOnPhoneOrChat:
        "Are you on the phone or chat with our representative?",
      connectBeforeCobrowse:
        "You need to be connected to our representative to continue with co-browsing. Please call us at 888-346-8679 or start a live chat with us, and then start Co-browse again.",
      sessionStartedAutoConnect:
        "Co-browse session started. Waiting for representative to connect to the session…",
      browserUnsupported:
        "Unfortunately, your browser is not currently supported.<br><br> Supported browsers are: <ul><li><a target='_blank' href='http://www.google.com/chrome'>Google Chrome</a></li><li><a target='_blank' href='http://www.firefox.com/'>Mozilla Firefox</a></li><li><a target='_blank' href='http://microsoft.com/ie'>Internet Explorer 9 and above</a></li><li><a target='_blank' href='https://www.apple.com/safari'>Safari 6 and above</a></li></ul>",
      chatIsAlreadyRunning: "Chat is already running on another page.",
      modalYes: "Yes",
      modalNo: "No",
    },
    webchat: {
      ChatButton: "Chat",
      ChatStarted: "",
      ChatEnded: "Chat Ended",
      AgentNameDefault: "Agent",
      AgentConnected: "<%Agent%> Connected",
      AgentDisconnected: "<%Agent%> Disconnected",
      BotNameDefault: "",
      BotConnected: "",
      BotDisconnected: "",
      SupervisorNameDefault: "Supervisor",
      SupervisorConnected: "<%Agent%> Connected",
      SupervisorDisconnected: "<%Agent%> Disconnected",
      AgentTyping: "is typing...",
      AgentUnavailable:
        "Sorry. There are no agents available. Please try later",
      ChatTitle: "Live Chat",
      ChatEnd: "X",
      ChatClose: "X",
      ChatMinimize: "Min",
      ChatFormFirstName: "First Name",
      ChatFormLastName: "Last Name",
      ChatFormNickname: "Nickname",
      ChatFormEmail: "Email",
      ChatFormSubject: "Subject",
      ChatFormPlaceholderFirstName: "Required",
      ChatFormPlaceholderLastName: "Required",
      ChatFormPlaceholderNickname: "Optional",
      ChatFormPlaceholderEmail: "Optional",
      ChatFormPlaceholderSubject: "Optional",
      ChatFormSubmit: "Start Chat",
      ChatFormCancel: "Cancel",
      ChatFormClose: "Close",
      ChatInputPlaceholder: "Type your message here",
      ChatInputSend: "Send",
      ChatEndQuestion: "Are you sure you want to end this chat session?",
      ChatEndCancel: "Cancel",
      ChatEndConfirm: "End chat",
      ConfirmCloseWindow: "Are you sure you want to close chat?",
      ConfirmCloseCancel: "Cancel",
      ConfirmCloseConfirm: "Close",
      ActionsDownload: "Download transcript",
      ActionsEmail: "Email transcript",
      ActionsPrint: "Print transcript",
      ActionsCobrowseStart: "Start Co-browse",
      ActionsSendFile: "Attach Files",
      ActionsEmoji: "Send Emoji",
      ActionsCobrowseStop: "Exit Co-browse",
      ActionsVideo: "Invite to Video Chat",
      ActionsTransfer: "Transfer",
      ActionsInvite: "Invite",
      InstructionsTransfer:
        "Open this link on another device to transfer your chat session</br></br><%link%>",
      InstructionsInvite:
        "Share this link with another person to add them to this chat session</br></br><%link%>",
      InviteTitle: "Need help?",
      InviteBody: "Let us know if we can help out.",
      InviteReject: "No thanks",
      InviteAccept: "Start chat",
      ChatError: "There was a problem starting the chat session. Please Retry.",
      ChatErrorButton: "OK",
      DownloadButton: "Download",
      FileSent: "has sent:",
      FileTransferRetry: "Retry",
      FileTransferError: "OK",
      FileTransferCancel: "Cancel",
      RestoreTimeoutTitle: "Chat ended",
      RestoreTimeoutBody:
        "Your previous chat session has timed out. Would you like to start a new one?",
      RestoreTimeoutReject: "No thanks",
      RestoreTimeoutAccept: "Start chat",
      EndConfirmBody: "Would you really like to end your chat session?",
      EndConfirmAccept: "End chat",
      EndConfirmReject: "Cancel",
      SurveyOfferQuestion: "Would you like to participate in a survey?",
      ShowSurveyAccept: "Yes",
      ShowSurveyReject: "No",
      UnreadMessagesTitle: "unread",
      AriaYouSaid: "You said",
      AriaSaid: "said",
      AriaSystemSaid: "System said",
      AriaMinimize: "Minimize Live Chat",
      AriaMaximize: "Maximize Live Chat",
      AriaClose: "Close live chat",
      AsyncChatEnd: "End Chat",
      AsyncChatClose: "Close Window",
      DayLabels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
      MonthLabels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      todayLabel: "Today",
      Errors: {
        102: "First name is required",
        103: "Last name is required",
        161: "Please enter your name",
        201: "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>The maximum number of attached files would be exceeded (<%MaxFilesAllowed%>)</p>",
        202: "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>Upload limit and/or maximum number of attachments would be exceeded (<%MaxAttachmentsSize%>)</p>",
        203: "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>File type is not allowed.</p>",
        204: "We're sorry but your message is too long. Please write a shorter message",
        240: "We're sorry but we cannot start a new chat at this time. Please try again later",
        364: "Invalid email address",
        ChatUnavailable:
          "We're sorry but we cannot start a new chat at this time. Please try again later",
        CriticalFault:
          "Your chat session has ended unexpectedly due to an unknown issue. We apologize for the inconvenience",
        StartFailed:
          "There was an issue starting your chat session. Please verify your connection and that you submitted all required information properly, then try again",
        MessageFailed:
          "Your message was not received successfully. Please try again",
        RestoreFailed:
          "We're sorry but we were unable to restore your chat session due to an unknown error",
        TransferFailed:
          "Unable to transfer chat at this time. Please try again later",
        FileTransferSizeError:
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'>'<%FilenameTruncated%>'</p></strong><p class='cx-advice'>File size is larger than the allowed size (<%MaxSizePerFile%>)</p>",
        InviteFailed:
          "Unable to generate invite at this time. Please try again later",
        ChatServerWentOffline:
          "Messages are currently taking longer than normal to get through. We're sorry for the delay",
        RestoredOffline:
          "Messages are currently taking longer than normal to get through. We're sorry for the delay",
        Disconnected:
          "<div style='text-align:center'>Oops! Looks like we've lost you. To reconnect, check your Internet connection, refresh your page or see if your chat session started in another browser window or tab.</div>",
        Reconnected: "<div style='text-align:center'>Connection restored</div>",
        FileSendFailed:
          "The file could not be sent.<br/><strong><p class='filename' title='<%FilenameFull%>'><%FilenameTruncated%></p></strong><p class='cx-advice'>There was an unexpected disconnection. Try again?</p>",
        Generic:
          "<div style='text-align:center'>An unexpected error occurred</div>",
        "pureengage-v3-rest-INVALID_FILE_TYPE":
          "Invalid file type. Only Images are allowed",
        "pureengage-v3-rest-LIMIT_FILE_SIZE":
          "File size is larger than the allowed size",
        "pureengage-v3-rest-LIMIT_FILE_COUNT":
          "The maximum number of attached files exceeded the limit",
        "pureengage-v3-rest-INVALID_CONTACT_CENTER":
          "Invalid x-api-key transport configuration",
        "pureengage-v3-rest-INVALID_ENDPOINT":
          "Invalid endpoint transport configuration",
        "pureengage-v3-rest-INVALID_NICKNAME": "First Name is required",
        "purecloud-v2-sockets-400":
          "Sorry, something went wrong. Please verify your connection and that you submitted all required information properly, then try again.",
        "purecloud-v2-sockets-500":
          "We're are sorry, an unexpected error occurred with the service.",
        "purecloud-v2-sockets-503":
          "We're sorry, the service is currently unavailable.",
      },
    },
  },
};
export default i18n;
