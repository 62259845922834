import { useState } from "react";
import FeedbackButton from "./feedbackButton/FeedbackButton"
import FeedbackModal from "./feedbackModal/FeedbackModal"

function FeedbackContainer() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <FeedbackModal
        open={modalOpen}
        toggleModal={(value) => setModalOpen(value)}
      />
      <FeedbackButton open={() => setModalOpen(!modalOpen)}/>
    </>
  );
}

export default FeedbackContainer;
