import { useState, useEffect } from "react";
import styles from "../styles";
import { Link } from "@mui/material";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import BlackArrow from "../../../images/BlackArrow.svg";
import facultyPortalStyles from "../../../styles";
import AccessAndInstructionsCard from "./AccessAndInstructionsCard";
import facultyPortalTheme from "../../../facultyPortalTheme";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import TipsCard from "./TipsCard";
import { connect } from "react-redux";

const ExpenseSubmissions = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));
  const facultyClasses = facultyPortalStyles();
  document.title = "Faculty Portal Expense Submissions";

  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    if (props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <div className={facultyClasses.pageContainer} role="main">
        <Grid
          container
          direction="row"
          alignItems="center"
          className={matches ? classes.linkBox : classes.linkBoxMobile}
        >
          <Link
            underline="none"
            sx={{
              color: "#001823",
              display: "flex",
              justifyContent: "space-between",
              "&hover": {
                cursor: "pointer",
              },
            }}
            href="/"
          >
            <img className={classes.arrowImage} src={BlackArrow} alt="" />
            <Typography
              className={matches ? classes.backText : classes.backTextMobile}
            >{`Back to Course Dashboard`}</Typography>
          </Link>
        </Grid>
        <Grid
          container
          direction="column"
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography
              className={matches ? classes.pageTitle : classes.pageTitleMobile}
              variant={matches ? "h1" : "h4"}
              component="h1"
            >
              {`Expense Submissions`}
            </Typography>
          </Grid>
          <Grid
            item
            className={
              matches
                ? classes.cardContainer
                : tabletMatches
                ? classes.cardContainerTablet
                : classes.cardContainerMobile
            }
            xs={12}
            sm={11}
            md={12}
          >
            <AccessAndInstructionsCard />
          </Grid>
          <Grid
            item
            className={
              matches
                ? classes.cardContainer
                : tabletMatches
                ? classes.cardContainerTablet
                : classes.cardContainerMobile
            }
            xs={12}
            sm={11}
            md={12}
          >
            <TipsCard />
          </Grid>
        </Grid>
      </div>
      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessages: state.errorMessages,
  };
};

export default connect(mapStateToProps)(ExpenseSubmissions);
