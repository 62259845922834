
export const filterAllDates = (courseList) => {
    return courseList
}

export const filterTwelveMonths = (courseList) => {
    let filteredArray = []
    if(courseList.length === undefined) {
        return filteredArray
    }

    for(let course of courseList) {
        let courseEndDate = new Date(course.endDate.split(" ")[0])
        let currentDate = new Date()
        if(currentDate.getFullYear() - courseEndDate.getFullYear() === 0) {
            filteredArray.push(course)
        }
        if(currentDate.getFullYear() - courseEndDate.getFullYear() === 1) {
            if((courseEndDate.getMonth() > currentDate.getMonth()) ||
            (courseEndDate.getMonth() === currentDate.getMonth() && 
            courseEndDate.getDate() >= currentDate.getDate())) {
                filteredArray.push(course)
            }
        }
    }
    return filteredArray
}

export const filterSixMonths = (courseList) => {
    let filteredArray = []
    if(courseList.length === undefined) {
        return filteredArray
    }

    for(let course of courseList) {
        let courseEndDate = new Date(course.endDate)
        let currentDate = new Date()
        if(currentDate.getFullYear() - courseEndDate.getFullYear() === 0) {
            if(currentDate.getMonth() - courseEndDate.getMonth() < 6) {
                filteredArray.push(course)
            }
            if((currentDate.getMonth() - courseEndDate.getMonth() === 6) &&
            (currentDate.getDate() <= courseEndDate.getDate())) {
                filteredArray.push(course)
            }
        }
        if(currentDate.getFullYear() - courseEndDate.getFullYear() === 1) {
            if((currentDate.getMonth() + 11) - courseEndDate.getMonth() <= 5) {
                filteredArray.push(course)
            }
        }
    }
    return filteredArray
}