import React from "react";
import useStyles from "./CoursePriorityCard.styles";
import { Link, Typography, FormControl } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { connect } from "react-redux";
import {
  setCoursesPreferencesState,
  registerChangeMade,
} from "../../../../redux/coursesPreferences/coursesPreferencesActions";
import { useEffect, useState } from "react";

const CoursePriorityCard = (props) => {
  // courseNumber, courseName, link
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [checked, setChecked] = useState(props.preferred);
  const [alteredPreferred, setAlteredPreferred] = useState(false);

  const handleChange = (event) => {
    setChecked(!checked);
    setAlteredPreferred(true);
  };

  useEffect(() => {
    props.coursesPreferences.forEach((course) => {
      if (course.courseId === props.courseNumber) {
        course.preferred = checked;
      }
    });
    props.setCoursesPreferencesState(props.coursesPreferences);

    if (alteredPreferred) {
      props.registerChangeMade(true);
    }
  }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

  // for small screen (e.g. mobile)
  if (matches) {
    return (
      <div>
        <div
          onClick={handleChange}
          id={props.id}
          className={
            checked
              ? classes.checkedBackgroundMobile
              : classes.mobileCardContainer
          }
        >
          <div>
            <Typography className={classes.courseNumber}>
              {props.courseNumber}
            </Typography>
            <Typography className={classes.courseName}>
              {props.courseName}
            </Typography>
          </div>
          <FormControl className={classes.checkBoxMobile}>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              className={classes.checkBox}
              inputProps={{
                "aria-label": `${props.courseNumber}: ${props.courseName}`,
              }}
            />
          </FormControl>
        </div>
      </div>
    );
  }

  // for larger screens
  return (
    <div className={classes.mainContainer}>
      <div
        id={props.id}
        className={
          checked ? classes.checkedBackground : classes.wrappingContainer
        }
      >
        <div>
          <div className={classes.courseTextContainer}>
            <Typography
              className={classes.courseText}
              id={props.headingId}
              component="h3"
            >
              {`${props.courseNumber}: ${props.courseName}`}
            </Typography>
          </div>
          <FormGroup
            sx={{
              marginLeft: "15px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.CheckBox}
                  type="checkbox"
                  aria-labelledby={`${props.headingId}`}
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label="Preferred Course"
            />
          </FormGroup>
        </div>
        <div className={classes.previewContainer}>
          <Link
            className={classes.linkContainer}
            href={props.link}
            target="_blank"
            //aria-labelledby={`${props.previewMaterialsButtonId} ${props.headingId}`}
            aria-label={`Preview course materials ${props.courseNumber}: ${props.courseName}. Opens in new window`}
          >
            <Typography
              id={props.previewMaterialsButtonId}
              className={classes.previewCourseText}
            >
              {` Preview course materials`}
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    coursesPreferences: state.coursesPreferences,
    setChangedPreferences: state.setChangedPreferences,
    registerChange: state.registerChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCoursesPreferencesState: (preferences) =>
      dispatch(setCoursesPreferencesState(preferences)),
    registerChangeMade: (change) => dispatch(registerChangeMade(change)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursePriorityCard);
