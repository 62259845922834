import styles from "./Links.styles";
import { useMediaQuery } from "@material-ui/core";
import { Link, Box } from "@mui/material";
import EmailIcon from "@material-ui/icons/MailOutline";
import { useTheme } from "@mui/material/styles";

const EmailLink = () => {
  // Allow for MUI styling
  const classes = styles();

  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to change state of Drawer
  const handleEmail = (event) => {
    window.open(`${process.env.REACT_APP_FACULTY_EMAIL_INBOX_URL}`);
  };

  return (
    <Box
      className={matches ? classes.linkContainerMobile : classes.linkContainer}
    >
      <Link
        aria-labelledby="email"
        className={classes.linkButton}
        component="button"
        underline="none"
        color="inherit"
        onClick={handleEmail}
        aria-label={matches ? "University of Phoenix Email" : null}
        id="email"
        data-testid="email"
      >
        <EmailIcon titleAccess="" role="presentation" />
        {matches ? null : `Email Inbox`}
      </Link>
    </Box>
  );
};

export default EmailLink;
