import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  desktopCard: {
    fontFamily: "Roboto",
  },
  desktopCardContent: {
    marginTop: "10px",
  },
  desktopTitle: {
    fontWeight: "700 !important",
    fontSize: "24px !important",
    color: "#000304 !important",
  },

  // Mobile View
  mobileCard: {
    width: "327px",
    marginBottom: "21px",
  },
  mobileCardContent: {
    margin: "0 5px",
  },
  mobileTitle: {
    fontWeight: "700 !important",
    fontSize: "21px !important",
    color: "#000304 !important",
  },
});
