import React from "react";
import { Typography, Card, Grid, Link } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "./QuickLinks.styles";
import QuickLinkCard from "./QuickLinkCard";
import UniversityLibrary from "../../../../images/UniversityLibrary.svg";
import Working from "../../../../images/Working.svg";
import AdultEducation from "../../../../images/AdultEducation.svg";

const cards = [
  {
    title: "PhoenixView Faculty",
    subtext: "University updates & resources",
    link: `${process.env.REACT_APP_PHOENIXVIEW_FACULTY_URL}`,
    icon: Working,
  },
  {
    title: "Faculty Resources Center",
    subtext: "Resources, tools, & contacts",
    link: `${process.env.REACT_APP_FACULTY_RESOURCES_URL}`,
    icon: AdultEducation,
  },
  {
    title: "University Library",
    subtext: "Learning resources, & tools",
    link: `${process.env.REACT_APP_UNIVERSITY_LIBRARY_URL}`,
    icon: UniversityLibrary,
  },
];

const QuickLinks = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // for smaller screens (e.g. mobile view)
  if (matches) {
    return (
      <>
        <Grid className={classes.mobileContainer} role="complementary">
          <Card className={classes.card}>
            <div className={classes.mobilePaddingContainer}>
              <Typography
                className={classes.cardTitle}
                variant="subtitle1"
                color="secondary"
                component="h2"
              >
                {`Quick Links`}
              </Typography>
              {cards.map((card) => (
                <div className={classes.linkContainer} key={card.title}>
                  <Link
                    data-testid="cardLink"
                    className={classes.cardLink}
                    href={card.link}
                    target="_blank"
                  >
                    {card.title}
                  </Link>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
      </>
    );
  }

  // for larger screens
  return (
    <>
      <Grid container className={classes.container} role="complementary">
        <Grid item>
          <Typography className={classes.title} variant="h2" component="h2">
            {`Quick Links`}
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.unorderedList}>
          {cards.map((card) => (
            <QuickLinkCard
              data-testid="cardLink"
              key={card.title}
              className={classes.QuickLinkCard}
              title={card.title}
              subtext={card.subtext}
              link={card.link}
              icon={card.icon}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default QuickLinks;
