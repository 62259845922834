import { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Radio,
  Button,
} from "@mui/material";
import {
  CloseButton,
  CloseButtonWrapper,
  ContactLabel,
  EmotionButton,
  EmotionsWrapper,
  ErrorText,
  FeedbackLabel,
  FeedbackTextField,
  StyledDialog,
  SubtitleTypography,
  TitleTypography,
} from "./FeedbackModal.styles";
import CloseIcon from "@mui/icons-material/Close";
import { postFacultyPortalFeedback } from "../../../services/faculty/v1/faculty";

function FeedbackModal(props) {
  const { open, toggleModal } = props;
  const demographics = window.demographics;

  // Required because a prop from the modal relies on breakpoints.
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const [selectedEmotion, setSelectedEmotion] = useState(undefined);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(undefined);
  const [writtenFeedback, setWrittenFeedback] = useState("");
  const [canBeContacted, setCanBeContacted] = useState(undefined);
  const [feedbackSubmissionLoading, setFeedbackSubmissionLoading] =
    useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackSubmissionError, setFeedbackSubmissionError] = useState(false);

  const postFeedback = async () => {
    setFeedbackSubmissionLoading(true);

    if (canBeContacted === undefined) {
      setCanBeContacted(false);
    }

    if (selectedFeedbackType === undefined) {
      setSelectedFeedbackType("Other");
    }

    const request = {
        irn: demographics.externalSystemIds["SUPPLEMENTAL_ID"][0],
        category: selectedFeedbackType,
        contact: canBeContacted ? "yes" : "no",
        feedback: writtenFeedback,
        rating: selectedEmotion,
    };

    const response = await postFacultyPortalFeedback(request);
      if (response.status === 200) {
        console.log(response.status, response.statusText);
        setFeedbackSubmitted(true);
      } else {
        console.log(response, response.status, response.statusText)
        setFeedbackSubmissionError(true);
      }
      setFeedbackSubmissionLoading(false);
  };

  const resetAndCloseModal = () => {
    toggleModal(false);
    setSelectedEmotion(undefined);
    setSelectedFeedbackType(undefined);
    setWrittenFeedback("");
    setCanBeContacted(undefined);
    setFeedbackSubmitted(false);
    setFeedbackSubmissionError(false);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  return (
    <StyledDialog
      id="feedback-modal"
      open={open}
      fullScreen={isMobile}
      role="dialog"
      aria-label="Feedback form"
      aria-labelledby="modal-title"
      maxWidth="sm"
      fullWidth
      onClose={feedbackSubmitted ? resetAndCloseModal : closeModal}
    >
      <CloseButtonWrapper
        sx={{ display: (feedbackSubmitted || !open) && "none" }}
      >
        <CloseButton
          variant="text"
          aria-label="Close feedback form"
          disableRipple
          onClick={closeModal}
        >
          <CloseIcon sx={{ transform: "scale(1.2)" }} />
        </CloseButton>
      </CloseButtonWrapper>
      {!feedbackSubmitted && open && (
        <>
          <Box sx={{ px: { xs: 1.5, sm: 3.5 } }}>
            <TitleTypography>
              Your opinion counts – tell us what you think.
            </TitleTypography>
            <SubtitleTypography>
              Grade your experience with Faculty Portal
            </SubtitleTypography>
          </Box>
          <Box sx={{ mt: 1, mb: 3, mx: 3 }}>
            <EmotionsWrapper>
              <EmotionButton
                sx={{
                  borderColor:
                    selectedEmotion === "Love" ? "#DB3725" : "#5E7079",
                  color: selectedEmotion === "Love" ? "#DB3725" : "#5E7079",
                }}
                onClick={() => setSelectedEmotion("Love")}
              >
                A
              </EmotionButton>
              <EmotionButton
                sx={{
                  borderColor:
                    selectedEmotion === "Like" ? "#DB3725" : "#5E7079",
                  color: selectedEmotion === "Like" ? "#DB3725" : "#5E7079",
                }}
                onClick={() => setSelectedEmotion("Like")}
              >
                B
              </EmotionButton>
              <EmotionButton
                sx={{
                  borderColor:
                    selectedEmotion === "Neutral" ? "#DB3725" : "#5E7079",
                  color: selectedEmotion === "Neutral" ? "#DB3725" : "#5E7079",
                }}
                onClick={() => setSelectedEmotion("Neutral")}
              >
                C
              </EmotionButton>
              <EmotionButton
                sx={{
                  borderColor:
                    selectedEmotion === "Dislike" ? "#DB3725" : "#5E7079",
                  color: selectedEmotion === "Dislike" ? "#DB3725" : "#5E7079",
                }}
                onClick={() => setSelectedEmotion("Dislike")}
              >
                D
              </EmotionButton>
              <EmotionButton
                sx={{
                  borderColor:
                    selectedEmotion === "Hate" ? "#DB3725" : "#5E7079",
                  color: selectedEmotion === "Hate" ? "#DB3725" : "#5E7079",
                }}
                onClick={() => setSelectedEmotion("Hate")}
              >
                F
              </EmotionButton>
            </EmotionsWrapper>
          </Box>
          <Box
            sx={{ mt: 1, mx: { xs: 2, sm: 5 }, mb: 1.25, fontFamily: "Roboto" }}
          >
            <FormControl sx={{ mb: 0 }}>
              <FormLabel>
                <Typography
                  variant="body4"
                  sx={{ color: "#001823", fontWeight: "700" }}
                >
                  What is your feedback about?
                </Typography>
              </FormLabel>
              <RadioGroup sx={{ mt: 1 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value={"Website Feedback"}
                      checked={
                        typeof selectedFeedbackType !== "undefined" &&
                        selectedFeedbackType === "Website Feedback"
                      }
                      label={
                        <Typography variant="body2">
                          Website Feedback
                        </Typography>
                      }
                      control={<Radio />}
                      onClick={() =>
                        setSelectedFeedbackType("Website Feedback")
                      }
                    />
                    <FormControlLabel
                      value={"Technical Issues"}
                      checked={
                        typeof selectedFeedbackType !== "undefined" &&
                        selectedFeedbackType === "Technical Issues"
                      }
                      label={
                        <Typography variant="body2">
                          Technical Issues
                        </Typography>
                      }
                      control={<Radio />}
                      onClick={() =>
                        setSelectedFeedbackType("Technical Issues")
                      }
                    />
                  </Grid>
                  <Grid container xs={6} direction={"column"}>
                    <FormControlLabel
                      value={"Chat"}
                      checked={
                        typeof selectedFeedbackType !== "undefined" &&
                        selectedFeedbackType === "Chat"
                      }
                      label={<Typography variant="body2">Chat</Typography>}
                      control={<Radio />}
                      onClick={() => setSelectedFeedbackType("Chat")}
                    />
                    <FormControlLabel
                      value={"Other"}
                      checked={
                        typeof selectedFeedbackType !== "undefined" &&
                        selectedFeedbackType === "Other"
                      }
                      label={<Typography variant="body2">Other</Typography>}
                      control={<Radio />}
                      onClick={() => setSelectedFeedbackType("Other")}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              mt: 2,
              mx: { xs: 2, sm: 5 },
              fontFamily: "Roboto",
              color: "#3A4F6E",
            }}
          >
            <Typography
              variant="body4"
              sx={{ color: "#001823", fontWeight: "700" }}
            >
              Please tell us more
            </Typography>
            <FormControl variant="filled" sx={{ mt: 2, width: "100%", mb: 0 }}>
              <FeedbackLabel htmlFor="feedback-input">
                Why did you choose that rating?
              </FeedbackLabel>
              <FeedbackTextField
                id="feedback-input"
                value={writtenFeedback}
                onChange={(event) => setWrittenFeedback(event.target.value)}
                fullWidth
                disableUnderline
                multiline
                rows={4}
              />
            </FormControl>
          </Box>
          <Box sx={{ mt: 1, mx: { xs: 2, sm: 5 }, fontFamily: "Roboto" }}>
            <FormControl sx={{ mb: 0 }}>
              <FormLabel>
                <ContactLabel>
                  May we contact you if we have additional questions regarding
                  your feedback?
                </ContactLabel>
              </FormLabel>
              <RadioGroup>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value
                      checked={
                        typeof canBeContacted === "boolean" && canBeContacted
                      }
                      label={
                        <Typography variant="body4">
                          Yes! Please use my email address on file.
                        </Typography>
                      }
                      control={<Radio />}
                      onClick={() => setCanBeContacted(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={false}
                      checked={
                        typeof canBeContacted === "boolean" && !canBeContacted
                      }
                      label={
                        <Typography variant="body4">No, thanks!</Typography>
                      }
                      control={<Radio />}
                      onClick={() => setCanBeContacted(false)}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Box>
          {!feedbackSubmissionError && !feedbackSubmitted && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
              <Button
                disabled={!selectedEmotion || feedbackSubmissionLoading}
                variant="contained"
                size="large"
                onClick={() => postFeedback()}
                sx={{ borderRadius: "29px", width: "180px" }}
              >
                <Typography variant="p" sx={{ textTransform: "none" }}>
                  Send feedback
                </Typography>
              </Button>
            </Box>
          )}
          {feedbackSubmissionError && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "10px auto",
                gap: "10px",
              }}
            >
              <ErrorText>
                {
                  "Oh dear! We're sorry your feedback didn't go through. Please resend."
                }
              </ErrorText>
              <Button
                disabled={!selectedEmotion || feedbackSubmissionLoading}
                variant="contained"
                size="large"
                onClick={() => postFeedback()}
                sx={{
                  borderRadius: "29px",
                  width: "180px",
                  alignSelf: "center",
                }}
              >
                <Typography variant="p" sx={{ textTransform: "none" }}>
                  Retry
                </Typography>
              </Button>
            </Box>
          )}
        </>
      )}
      {feedbackSubmitted && open && (
        <Box sx={{ px: { xs: 1.5, sm: 3.5 }, textAlign: "center", py: 10 }}>
          <TitleTypography>
            Thank you! We appreciate your feedback!
          </TitleTypography>
          <Button
            size="large"
            variant="contained"
            aria-label="Close"
            onClick={resetAndCloseModal}
          >
            Close
          </Button>
        </Box>
      )}
    </StyledDialog>
  );
}

export default FeedbackModal;
