import React from "react";
import useStyles from "./NoCurrentCourses.styles";

import { Typography } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CourseSupport from "../../../../images/CourseSupport.png";

const NoCurrentCourses = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // for small screen (e.g. mobile)
  if (matches) {
    return (
      <div className={classes.mainMobileContainer}>
        <div className={classes.topMobileContainer}>
          <div className={classes.topTextContainer}>
            <Typography variant="body1" component="p" sx={{ color: "#000304" }}>
              {`You have no current courses.`}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" component="p" sx={{ color: "#000304" }}>
              {`Solicitations for new courses are sent via email throughout the
              academic year. Keep an eye out for your next opportunity!`}
            </Typography>
          </div>
        </div>
        <img
          src={CourseSupport}
          alt="Course Support "
          className={classes.mobileCourseSupportImage}
        />
      </div>
    );
  }
  // otherwise return desktop view
  return (
    <div className={classes.mainContainer}>
      <div className={classes.topContainer}>
        <div className={classes.topTextContainer}>
        <Typography variant="body1" component="p" sx={{ color: "#000304" }}>
            {`You have no current courses.`}
          </Typography>
        </div>
        <div>
        <Typography variant="body1" component="p" sx={{ color: "#000304" }}>
            {`Solicitations for new courses are sent via email throughout the
              academic year. Keep an eye out for your next opportunity!`}
          </Typography>
        </div>
      </div>
      <img
        src={CourseSupport}
        alt="Course Support"
        className={classes.CourseSupportImage}
      />
    </div>
  );
};

export default NoCurrentCourses;
