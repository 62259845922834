import { GET_COURSE_OFFERING } from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
       case GET_COURSE_OFFERING:
          return action.payload;
       default:
          return state;  
    };
};