import axios from "axios";
import idHeaders from "../interceptors/idHeaders";
import csrfHeaders from "../interceptors/csrfHeaders";
import tokenAuthorization from "../interceptors/tokenAuthorization";
import store from '../../redux/store';
// import AuthzClient from "../authorization";

export const baseClient = axios.create({});
// const authz = new AuthzClient();

export const proxyClient = () => {
  const client = axios.create({});
  client.interceptors.request.use(idHeaders);
  client.interceptors.request.use((config) => tokenAuthorization(config, store.getState));
  client.interceptors.request.use(csrfHeaders);
  // client.interceptors.request.use((config) => authz.sign(config));
  client.interceptors.request.use((config) => {
    config.url = `${process.env.REACT_APP_MICROSERVICES_BASE_URL}${config.url}`;
    return config;
  });

  return client;
};

export const proxyClientInstance = proxyClient();
