import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  greyText: {
    color: " #5E7079",
  },
  checkboxContainerMobile: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
}));

export default useStyles;
