import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    width: "auto",
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    margin: "0px",
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  retryButton: {
    fontWeight: "400",
    textDecoration: "underline",
    textTransform: "none",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      textDecoration: "underline",
    },
    "&:focus": {
      backgroundColor: theme.palette.error.dark,
      textDecoration: "underline",
    },
  },
  retryButtonRoot: {
    padding: "0px",
  },
  closeButtonRoot: {
    padding: "0px",
    margin: "0px",
  },
  closeButton: {
    padding: "5px",
    margin: "0px",
  },
  hide: {
    display: "none",
  },
}));

export default function SnackbarContentWrapper(props) {
  const classes = useStyles1();
  const {
    className,
    message,
    onClose,
    variant,
    noRetryButton,
    handleRetryClick,
    ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
          <div>
            <Button
              variant="text"
              className={
                noRetryButton && variant === "error"
                  ? classes.hide
                  : variant === "error"
                  ? classes.retryButton
                  : classes.hide
              }
              classes={{
                root: classes.retryButtonRoot,
              }}
              onClick={handleRetryClick}
            >
              {`Retry`}
            </Button>
          </div>
        </span>
      }
      // action={[
      //   <IconButton
      //     key="close"
      //     aria-label="close"
      //     color="inherit"
      //     onClick={onClose}
      //     className={classes.closeButton}
      //     classes={{
      //       root: classes.closeButtonRoot,
      //     }}
      //   >
      //     <CloseIcon className={classes.icon} />
      //   </IconButton>,
      // ]}
      // {...other}
    />
  );
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
};
