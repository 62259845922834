import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userManager from '../utils/userManager';
import LoadingModal from './LoadingModal';

const AuthedRoute = ({ component: Component, path, location, ...rest }) => {
  	const { user, isLoadingUser } = useSelector(state => state.oidc);
  	console.log('isLoadingUser', isLoadingUser);
  	const isLoggedIn = user && !user.expired;
  	console.log('isLoggedIn', isLoggedIn);

  	if (!isLoggedIn) {
		console.log('RedirectUrl', `${location.pathname}${location.search}`);
    	userManager.signinRedirect({
      		data: { redirectUrl: `${location.pathname}${location.search}` },
    	});
    	// Returning LoadingModal here to avoid flickering between the redirect and the next page.
    	return <LoadingModal />;
  	}

  	const render = props => (isLoadingUser || !isLoggedIn ? <LoadingModal /> : <Component {...props} />);
  	return <Route path={path} render={render} {...rest} />;
};

AuthedRoute.propTypes = {
  	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  	path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  	location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }),
};

AuthedRoute.defaultProps = {
  	component: <></>,
  	path: '/',
  	location: {},
};

export default AuthedRoute;