import Box from "@mui/system/Box";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import TeachingIcon from "../images/TeachingIcon.svg";
import TimeIcon from "../images/TimeIcon.svg";
import Website2Icon from "../images/Website2Icon.svg";
import CalendarIcon from "../images/CalendarIcon.svg";
import InfoIcon from "@mui/icons-material/Info";

import styles from "./DescriptionBox.styles";

const DescriptionBox = (props) => {
  const { startDate, endDate, date, campus, role, groupId, courseCard } = props;
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  function capitalizeFirstLetter(string) {
    return string.charAt(0) + string.toLowerCase().slice(1);
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const checkDate = (date) => {
    if (date === "Invalid Date") {
      return " ";
    }
  };

  const getMonthString = (month) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month - 1];
  };

  const toggleDatabaseDate = new Date(process.env.REACT_APP_TOGGLE_DATE);
  const currentDate = new Date();
  const updateDateFormat = (date) => {
    if (
      (props.offeringType !== "WS") ||
      //(props.wsUser && props.offeringType !== "WS" && !props.onlyDate) ||
      (!props.wsUser && currentDate < toggleDatabaseDate)
    ) {
      const [month, day, year] = date.split("/");
      return `${getMonthString(month)} ${day}, ${year}`;
    } else {
      const [year, month, day] = date.split("-");
      return `${getMonthString(month)} ${day}, ${year}`;
    }
  };

  return (
    <Box className={matches ? classes.desktopContent : classes.mobileContent}>
      <Box className={matches ? classes.desktopRow : classes.mobileRow}>
        <img
          src={CalendarIcon}
          alt="dates"
          className={matches ? "" : classes.iconMobile}
        />
        <Typography
          data-testid="courseDuration"
          variant="body2"
          sx={{
            marginLeft: "11px",
            marginTop: "4px",
            color: "#000304",
          }}
        >
          {`${updateDateFormat(startDate)} - ${updateDateFormat(endDate)}`}
        </Typography>
      </Box>
      <div>
        <div className={campus === "hideCampus" ? classes.hideDiv : ""}>
          <Box className={matches ? classes.desktopRow : classes.mobileRow}>
            {campus === "ONLINE" ? (
              <img
                src={Website2Icon}
                alt=""
                className={matches ? "" : classes.iconMobile}
              />
            ) : (
              <img
                src={TimeIcon}
                alt=""
                className={matches ? "" : classes.iconMobile}
              />
            )}

            <Typography
              variant="body2"
              sx={{
                marginLeft: "11px",
                marginTop: "3px",
                width: `${matches ? "405px" : "247px"}`,
                color: "#000304",
              }}
            >
              {capitalizeFirstLetter(campus) === "Online"
                ? `${capitalizeFirstLetter(campus)}`
                : checkDate(updateDateFormat(date)) === undefined
                ? `${capitalizeFirstLetter(campus)}`
                : updateDateFormat(date) | `${toTitleCase(campus)}`}
            </Typography>
          </Box>
        </div>

        <Box className={matches ? classes.desktopRow : classes.mobileRow}>
          <img
            src={TeachingIcon}
            alt=""
            className={matches ? "" : classes.iconMobile}
          />
          <Typography
            variant="body2"
            sx={{
              marginLeft: "11px",
              marginTop: "5px",
              color: "#000304",
            }}
          >
            {toTitleCase(role)}
          </Typography>
        </Box>

        <div className={groupId === 'null' || !groupId? classes.hideDiv : ""}>
          <Box className={matches ? classes.desktopRow : classes.mobileRow}>
            <InfoIcon
              className={matches ? classes.infoIcon : classes.infoIconMobile}
            />
            <Typography
              variant="body2"
              sx={{
                marginLeft: "11px",
                marginTop: "5px",
                color: "#000304",
              }}
            >
              {`Group ID: ${groupId}`}
            </Typography>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default DescriptionBox;
