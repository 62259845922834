import {GET_ISSUETYPES} from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
       case GET_ISSUETYPES :
          return action.payload;   
       default:
          return state;  
    }
  }

