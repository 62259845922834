import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  linkBox: {
    marginTop: "2%",
    height: "24px",
    width: "230px",
    marginLeft: "5%",
  },
  mainContent: {
    justifyContent: "center",
    margin: "0 auto",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5%", 
  },
  subContent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
    marginBottom: "5%",
  },
  tab: {
    background: "none",
    textTransform: "none",
  },

  // Mobile Styling Below

  linkBoxMobile: {
    width: "225px",
    marginBottom: "37px",
    marginLeft: "18px",
  },
  mainContentMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
  },
  mobileTitle: {
    marginBottom: "30px",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "28px",
  },
  mobileSubContent: {
    justifyContent: "center",
  },
  mobileCardContainer: {
    marginTop: "48px",
    marginBottom: "80px",
  },
  redText: {
    color: "#DC3727",
  },
  blackText: {
    color: "#000000",
  },

  // Universal for Desktop and Mobile View
  arrowImage: {
    position: "relative",
    width: "27px",
    height: "24px",
    marginRight: "2px",
  },
  hide: {
    display: "none",
  },

  // Mobile View
  infoContainerMobile: {
    width: "85%",
    // display: "flex",
    justifyContent: "space-between",
    margin: "5px 10px 5px 10px",
  },
  iconContainerMobile: {
    // width: "15%",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linkContainerMobile: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "5px 10px 5px 10px",
  },
  dropdownContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  tabContainer: {
    display: "flex",
    marginLeft: "20%",
    marginBottom: "10%",
  },
  tabletTabContainer: {
    display: "flex",
    // marginLeft: "8%",
    // marginBottom: "10%",
    paddingBottom: "10%",
  },

  // Universal for both Desktop and Mobile View
  expandIconRoot: {
    color: "#DC3727",
  },
  expandIconRootExpanded: {
    color: "#DC3727",
    transform: "rotate(0.5turn)",
  },
}));

export default useStyles;
