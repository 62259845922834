import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingBottom: "65px",
    justifyContent: "center",
    paddingLeft: "40px", // TEMPORARY UNTIL MANAGE COOKIE PREFERENCES RETURNS
  },
  tabletContainer: {
    display: "flex",
    paddingBottom: "30px",
    justifyContent: "center",
    paddingLeft: "60px",
  },
  listItem1: {
    /* Copyright */
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#5e7078",
    margin: "0px 10px",
  },
  listItem2: {
    /* CA residents */
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#5e7078",
    margin: "0px 10px",
  },
}));

export default useStyles;
