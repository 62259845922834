import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  card: {
    // width: "491px",
    display: "flex",
    boxShadow: "0px 2px 4px rgba(0,0,0, 0.15)",
    border: "1px solid #CACACA",
    boxSizing: "border-box",
    padding: "8px",
    margin: "2px",
  },
  textAndLinkContainer: {
    margin: "20px 20px 20px 20px",
  },
  icon: {
    maxWidth: "60px",
    height: "auto",
    marginLeft: "17px",
    marginRight: "2px",
    position: "relative",
    top: "-30.5px",
  },
  bigIcon: {
    maxWidth: "60px",
    height: "auto",
    transform: "scale(1.25)",
    marginLeft: "17px",
    marginRight: "2px",
    position: "relative",
    top: "-35.5px",
  },

  // Mobile View
  mobileCard: {
    width: "327px",
    minHeight: "187px",
    display: "flex",
    boxShadow: "0px 2px 4px rgba(0,0,0, 0.15)",
    border: "1px solid #CACACA",
    boxSizing: "border-box",
    marginBottom: "20px",
  },
  mobileTextAndLinkContainer: {
    margin: "20px 24px 24px 24px",
  },

  // Universal for both Desktop and Mobile View
  titleContainer: {
    marginBottom: "6px",
  },
  subtextContainer: {
    marginBottom: "13px",
  },
}));

export default useStyles;
