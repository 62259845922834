import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from "../../images/background.png";

export default makeStyles({
  // Desktop View
  pageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "2%",
    marginLeft: "5%",
    marginBottom: "48px",
    height: "24px",
    width: "100%",
  },
  pageHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeader: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "40px",
    lineHeight: "48px",
  },
  pageIntroContainer: {
    width: "969px",
  },
  mainInfoContainer: {
    width: "969px",
  },
  textFieldsBox: {
    display: "flex",
    flexDirection: "column",
    width: "513px",
    marginRight: "56px",
    marginBottom: "40px",
    boxSizing : "borderBox",
  },

  // Tablet view
  pageContainerTablet: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContentTablet: {
    width: "740px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerTablet: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "48px",
  },
  pageHeaderTablet: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "40px",
  },
  textFieldsBoxTablet: {
    display: "flex",
    flexDirection: "column",
    width: "380px",
    marginRight: "56px",
    marginBottom: "40px",
  },

  textContent: {
    marginBottom: "14px", // Add some spacing between text elements
  },

  // Mobile View
  pageContainerMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 2%",
    overflow: "hidden",
  },
  mainPageContentMobile: {
    width: "327px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerMobile: {
    width: "100%",
    margin: "40px 0px",
  },
  pageHeaderContainerMobile: {
    // for both mobile and tablet
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeaderMobile: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "24px",
    lineHeight: "32px",
  },
  pageIntroContainerMobile: {
    // for both mobile and tablet
    width: "100%",
  },
  mainInfoContainerMobile: {
    // for both mobile and tablet
    width: "100%",
  },
  textFieldsBoxMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "40px",
  },

  // Universal (used in desktop, tablet and mobile)
  arrowImage: {
    width: "27px",
    height: "24px",
    marginRight: "10px",
  },
  backToDashboardLink: {
    display: "flex",
    color: "#001823",
    "&hover": {
      cursor: "pointer",
      color: "#001823",
    },
  },
  labelInputTextField:{
    width: "513px", 
    background:"#ffffff",
    height: "150px",
    border: "1px solid",
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
  },

  labelInputMobileTextField:{
    width: "100%", 
    //marginBottom: "64px", 
    borderRadius: "4px",
    // width: "969px",

     height: "150px",
    background: "#ffffff",
    border: "1px solid",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
   },

  inputTextFieldLocked:{
    width: "100%",
    background: "#E3E6E7",
    border: "1px solid",
    marginBottom: "24px",
    borderRadius: "4px",
    '& .MuiFormLabel-root.Mui-disabled': {
      opacity: "1",
      color: "#000000",
    },
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: "1",
      color: "#000000"
    },
  },
  numberedItem: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00638C",
    color: "white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    lineHeight: "24px",
    textAlign: "center",
    marginRight: "8px",
    fontWeight: "bold",
    fontSize: "12px"
  },

  inputTextField: {
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid",
    borderRadius: "4px",
    "& .MuiFormLabel-root": {
      opacity: "1",
      color: "#000000"
    },
    color: "#000000"
  },
  menuItem: {
    top :"56px",
    padding: "16px, 32px, 16px, 32px",
    height:"56px",
  },
  commentsTextArea: {
    width: "100%",
    height: "112px",
    border: "1px solid",
    borderRadius: "4px",
    background: "#ffffff",
    marginTop: "-40px",
  },
  commentsTextAreaError: {
    width: "100%",
    height: "112px",
    border: "1px solid",
    borderRadius: "4px 4px 0 0",
    borderColor: "#B21F13",
    borderBottom: "none",
    background: "#ffffff",
    marginTop: "-40px",
    "&::placeholder": {
      color: "#B21F13",
      fontWeight: "700",
      padding: "12px 0 0 12px",
    },
  },
  submitButton: {
    width: "240px",
    height: "56px",
    color: "#ffffff",
    background: "#DB3725",
    border: "none",
    borderRadius: "32px",
    textTransform: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
      background: "#DB3725",
    },
    "&.Mui-disabled": {
        backgroundColor: "#E3E6E7",
        color: "#5E7079",
      },
  },
  errorbox: {
    display: "flex",
    height: "34px",
    backgroundColor: "#FBEBEA",
    border: "1px solid",
    borderColor: "#B21F13",
    borderRadius: "4px",
  },
  errorboxComments: {
    display: "flex",
    maxWidth: "513px",
    height: "34px",
    backgroundColor: "#FBEBEA",
    border: "1px solid",
    borderColor: "#B21F13",
    borderRadius: "0 0 4px 4px",
  },
  errorboxText: {
    color: "#B21F13",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
    padding: "9px 0 7px 0",
  },
  hide: {
    display: "none",
  },
});
