// General use functions

export const romanTest = (title) => {
  const titleArray = title.split(" ");
  const pattern =
    /^(M{1,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})|M{0,4}(CM|C?D|D?C{1,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})|M{0,4}(CM|CD|D?C{0,3})(XC|X?L|L?X{1,3})(IX|IV|V?I{0,3})|M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|I?V|V?I{1,3}))$/;

  const retVal = titleArray.map((word) =>
    pattern.test(word)
      ? word
      : word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
  );

  return retVal.join(" ");
};

export const capitalizeFoundParameter = (inputString, parameters) => {
  let retVal = inputString;

  parameters.map((parameter) => {
    const regexPattern = new RegExp(`\\b${parameter}\\b`, "i"); // 'i' flag makes the search case-insensitive
    const match = inputString.match(regexPattern);
    if (match) {
      const capitalizedParameter = match[0].toUpperCase();
      retVal = retVal.replace(regexPattern, capitalizedParameter);
    }
  });

  return retVal;
};

export const fixTimezoneDifference = (startDate) => {
  const newTime = new Date(startDate).setHours(24);
  return new Date(newTime);
};
