import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from  "../../images/background.png"

export default makeStyles({
  // Desktop View
  linkBox: {
    marginLeft: "5%",
    paddingTop: "2%",
    height: "24px",
  },
  mainContent: {
    width: "1100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
  },
  pageTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "66px",
    marginBottom: "37px",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
  },
  backText: {
    marginLeft : '10px'
  },

  // Mobile View
  linkBoxMobile: {
    marginLeft: "25px",
    marginTop: "24px"
  },
  backButtonAndText: {
    display: "flex",
  },
  backTextMobile: {
    position: "relative",
    right: "-6px",
  },
  mainContentMobile: {
    width: "375px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
  },
  pageTitleMobile: {
    marginTop:"35px",
    marginBottom: "25px"
  },
  cardContainerMobile: {
    marginBottom: '30px'
  },

  backTextMobile: {
    marginLeft : '5px',
  },

   // Tablet view
  cardContainerTablet: {
    marginBottom: "45px",
  },
 
  // Universal for Both Desktop and Mobile View
  arrowImage: {
    position: "relative",
    height: "24px",
    width: "27px",
  },
});
