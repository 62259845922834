import { getPersonProfile } from "../../services/persons/v1/persons";
import { getCourseOffering } from "../../services/courses/v1/courses";
import { GET_STUDENT_PROFILE, GET_COURSE_OFFERING } from "./actionTypes";

export const getStudentProfile = (profileId) => async (dispatch) => {
    await getPersonProfile(profileId)
    .then((response) => {
        dispatch({ type: GET_STUDENT_PROFILE, payload: response.data });
    })
    .catch((error) => {
        dispatch({ type: GET_STUDENT_PROFILE, payload: error.request });
    });
};

export const getCourseOfferingInfo = (courseOfferingId) => async (dispatch) => {
    await getCourseOffering(courseOfferingId)
    .then((response) => {
        dispatch({ type: GET_COURSE_OFFERING, payload: response.data });
    })
    .catch((error) => {
        dispatch({ type: GET_COURSE_OFFERING, payload: error.request });
    });
};