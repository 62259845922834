import React from "react";
import useStyles from "./NoWorkshopsCard.styles";

import { Typography, Grid, Box } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card, CardContent } from "@mui/material";
import CardLink from "../../../components/CardLink";

const NoWorkshopsCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClick = () => {
    props.setValue(1);
  };

  const handleClickMobile = () => {
    props.setTitle("Upcoming workshops");
  };

  return (
    <>
      <Grid xs={12} sm={11} md={8} lg={6}>
        <Card className={matches ? "" : classes.mobileCard}>
          <CardContent>
            <Typography className={classes.description}>
              You have no current workshops.
            </Typography>
            <Box onClick={matches ? handleClick : handleClickMobile}>
              <CardLink content={"View upcoming workshops"} nohref={true} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default NoWorkshopsCard;
