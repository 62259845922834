import { Card, CardActions, CardContent, Typography } from "@mui/material";
import DescriptionBox from "../../../../components/DescriptionBox";
import { Box } from "@mui/system";
import CardLink from "../../../../components/CardLink";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ResponseButton from "./ResponseButton";
import ResponseNotification from "./ResponseNotification";
import ResponsePopover from "./ResponsePopover";
import styles from "./NewSolicitationCard.styles";
import { romanTest, capitalizeFoundParameter } from "../../../../utils/library";
import { connect } from "react-redux";

const NewSolicitationCard = (props) => {
  const {
    previewMaterialsLinkId,
    responseId,
    title,
    classTitle,
    classNum,
    courseOfferingType,
    courseOfferingId,
    date,
    campus,
    role,
    startDate,
    endDate,
    card,
    status,
    reason,
    facultyScheduleId,
    handleMessageDisplay,
    isCbedaCourse,
  } = props;
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [responseDisplay, setResponseDisplay] = useState("response");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // console.log(status);
    if (status === "Accepted") {
      setResponseDisplay("Accepted");
    }
    if (status === "Confirmed") {
      setResponseDisplay("Confirmed");
    }
    if (
      status === "Declined_by_Faculty" ||
      status === "Declined_by_Scheduler"
    ) {
      setResponseDisplay("Declined");
    }
    if (status === "Solicited") {
      setResponseDisplay("response");
    }
  }, []);

  const handleResponseButtonClick = (event) => {
    // console.log(status);
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setExpanded(true);
  };

  const handleResponseDisplayChange = (displayValue) => {
    if (displayValue.status === 200) {
      displayValue.statusDescription.includes("Declined")
        ? setResponseDisplay("Declined")
        : setResponseDisplay(displayValue.statusDescription);
    }
    handleMessageDisplay(displayValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const createFullOfferingId = () => {
  //   if(courseOfferingType !== "WS") {
  //     return `OSIRIS:${courseOfferingId}`
  //   } else {
  //     return `FWS:${courseOfferingId}`
  //   }
  // };

  // const getPreviewCourseLink = () => {
  //   if(classNum.includes("PREVIEW")) {
  //     return previewCourseLink(classNum, createFullOfferingId(), props.demographics.issuerId);
  //   } else {
  //     return previewCourseLink(classNum, createFullOfferingId());
  //   }
  // }

  // console.log(props.blackboardLink);

  return matches ? (
    <Card
      className={
        status === undefined
          ? classes.desktopCard
          : classes.desktopCardResponded
      }
    >
      <CardContent className={classes.desktopCardContent}>
        <Box className={classes.leftColumn}>
          <Typography
            sx={{
              marginTop: "10px",
              fontWeight: 900,
              fontSize: "14px",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              color: "#3A4F59",
            }}
            component="h2"
          >
            {title}
          </Typography>

          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 300,
              marginTop: "15px",
              marginBottom: "7px",
            }}
            component="h3"
          >
            {classNum}:{" "}
            {capitalizeFoundParameter(romanTest(classTitle), ["ai"])}
          </Typography>
          <DescriptionBox
            data-testid="solicit-descript-box"
            startDate={startDate}
            endDate={endDate}
            date={date}
            campus={campus}
            role={role}
            card={card}
          ></DescriptionBox>
        </Box>
        <Box className={classes.rightColumn}>
          <Box sx={{ marginTop: "10px" }}>
            <Box
              className={
                responseDisplay === "response" ? classes.show : classes.hide
              }
            >
              <ResponseButton
                content="Respond to solicitation"
                onClick={handleResponseButtonClick}
                expanded={expanded}
              />
            </Box>
            <Box
              className={
                responseDisplay === "Accepted" ? classes.show : classes.hide
              }
            >
              <ResponseNotification
                content="Accepted"
                value="Accepted"
                responseId={`accepted${responseId}`}
                classTitle={`${classNum}: ${capitalizeFoundParameter(
                  romanTest(classTitle),
                  ["ai"]
                )}`}
              />
            </Box>
            <Box
              className={
                responseDisplay === "Declined" ? classes.show : classes.hide
              }
            >
              <ResponseNotification
                content="Declined"
                reason={reason}
                value="Declined"
                responseId={`declined${responseId}`}
                classTitle={`${classNum}: ${capitalizeFoundParameter(
                  romanTest(classTitle),
                  ["ai"]
                )}`}
              />
            </Box>

            <Box
              className={
                responseDisplay === "Confirmed" ? classes.show : classes.hide
              }
            >
              <ResponseNotification
                content="Confirmed"
                value="Confirmed"
                responseId={`confirmed${responseId}`}
                classTitle={`${classNum}: ${capitalizeFoundParameter(
                  romanTest(classTitle),
                  ["ai"]
                )}`}
              />
            </Box>
          </Box>
          <ResponsePopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleResponseDisplay={handleResponseDisplayChange}
            facultyScheduleId={facultyScheduleId}
            setExpanded={setExpanded} 
          />

          <CardLink
            externalLink={true}
            content={"Preview Course Materials"}
            linkId={previewMaterialsLinkId}
            link={
              isCbedaCourse
                ? `${process.env.REACT_APP_D2LCLASS_URL}`
                : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseId=${classNum}_preview&irn=${props.demographics.issuerId}`
            }
            ariaId={previewMaterialsLinkId}
            ariaRead={`Open Preview Course materials for Course ${classNum} ${classTitle}`}
          />
        </Box>
      </CardContent>
    </Card>
  ) : (
    <Card
      className={
        status === undefined ? classes.mobileCard : classes.mobileCardResponded
      }
    >
      <CardContent className={classes.mobileCardContent}>
        <Box className={classes.mobileLeftColumn}>
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: "14px",
              letterSpacing: "0.1em",
              textTransform: "uppercase",
              color: "#5E7078",
            }}
            component="h2"
          >
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: "21px", fontWeight: 700, marginTop: "5px" }}
            component="h3"
          >
            {classNum}:{" "}
            {capitalizeFoundParameter(romanTest(classTitle), ["ai"])}
          </Typography>
          <DescriptionBox
            startDate={startDate}
            endDate={endDate}
            date={date}
            campus={campus}
            role={role}
            card={card}
          />
          <div className={classes.previewLink}>
            <CardLink
              content={"Preview Course Materials"}
              linkId={previewMaterialsLinkId}
              link={
                isCbedaCourse
                  ? `${process.env.REACT_APP_D2LCLASS_URL}`
                  : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseId=${classNum}_preview&irn=${props.demographics.issuerId}`
                }
              ariaId={previewMaterialsLinkId}
              ariaRead={`Open Preview Course materials for Course ${classNum} ${classTitle}`}
            />
          </div>
        </Box>
      </CardContent>
      <CardActions className={classes.mobileRightColumn}>
        <Box
          className={
            responseDisplay === "response" ? classes.show : classes.hide
          }
        >
          <ResponseButton
            content="Respond to solicitation"
            onClick={handleResponseButtonClick}
            expanded={expanded}
          />
        </Box>
        <Box
          className={
            responseDisplay === "Accepted" ? classes.show : classes.hide
          }
        >
          <ResponseNotification
            content="Accepted"
            value="Accepted"
            responseId={`accepted${responseId}`}
            classTitle={`${classNum}: ${capitalizeFoundParameter(
              romanTest(classTitle),
              ["ai"]
            )}`}
          />
        </Box>
        <Box
          className={
            responseDisplay === "Declined" ? classes.show : classes.hide
          }
        >
          <ResponseNotification
            content="Declined"
            reason={reason}
            value="Declined"
            responseId={`declined${responseId}`}
            classTitle={`${classNum}: ${capitalizeFoundParameter(
              romanTest(classTitle),
              ["ai"]
            )}`}
          />
        </Box>

        <Box
          className={
            responseDisplay === "Confirmed" ? classes.show : classes.hide
          }
        >
          <ResponseNotification
            content="Confirmed"
            value="Confirmed"
            responseId={`confirmed${responseId}`}
            classTitle={`${classNum}: ${capitalizeFoundParameter(
              romanTest(classTitle),
              ["ai"]
            )}`}
          />
        </Box>
        <ResponsePopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleResponseDisplay={handleResponseDisplayChange}
          facultyScheduleId={facultyScheduleId}
          setExpanded={setExpanded}
        />
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    responseSolicitations: state.responseSolicitation,
  };
};

export default connect(mapStateToProps)(NewSolicitationCard);
