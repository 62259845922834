import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from "../../images/background.png";

export default makeStyles({
  // Desktop View
  pageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "2%",
    marginLeft: "5%",
    marginBottom: "48px",
    height: "24px",
    width: "100%",
  },
  pageHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeader: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "40px",
    lineHeight: "48px",
  },
  subHeaderDesktop: {
    fontFamily: "Roboto",
    fontSize: "32px", 
    fontWeight: "700", 
    lineHeight: "40px",
  },


  // Tablet view
  pageContainerTablet: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContentTablet: {
    width: "740px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerTablet: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "48px",
  },
  pageHeaderTablet: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "40px",
  },
  subHeaderTablet: {
    fontFamily: "Roboto",
    fontSize: "24px", 
    fontWeight: "700", 
    lineHeight: "32px",
  },
  

  // Mobile View
  pageContainerMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 2%",
    overflow: "hidden",
  },
  mainPageContentMobile: {
    width: "327px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerMobile: {
    width: "100%",
    margin: "40px 0px",
  },
  pageHeaderContainerMobile: { // for both mobile and tablet
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeaderMobile: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "24px",
    lineHeight: "32px",
  },


  // Universal (used in desktop, tablet and mobile)
  arrowImage: {
    width: "27px",
    height: "24px",
    marginRight: "10px",
  },
  backToDashboardLink: {
    display: "flex",
    color: "#001823",
    "&hover": {
        cursor: "pointer",
        color: "#001823",
    },
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
  },
});