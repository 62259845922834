import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Fragment } from "react";
import styles from "./ResponseNotification.styles";
import { Box } from "@material-ui/core";

const ResponseNotification = (props) => {
  const { value, content, responseId, reason, classTitle } = props;
  const classes = styles();

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#001823",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#001823",
      fontSize: "16px",
      font: "Roboto",
      lineHeight: "20px",
      width: "161px",
      padding: "14px 16px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const borderColor = (value) => {
    if (value === "Accepted") {
      return "2px solid #008A68";
    }
    if (value === "Declined") {
      return "2px solid #3A4F59";
    }
    if (value === "Confirmed") {
      return;
    }
  };

  const backgroundColor = (value) => {
    if (value === "Confirmed") {
      return "#94CBA3";
    }
    if (value === "Declined") {
      return "#FFFFFF";
    }
    if (value === "Accepted") {
      return "#FFFFFF";
    }
  };

  const displayNotification = (value) => {
    if (value === "Accepted") {
      return (
        <Box
          id={responseId}
          role="button"
          tabIndex={0}
          aria-label={`${classTitle} accepted`}
          data-testid="response-note"
          disableElevation
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `${borderColor(value)}`,
            background: `${backgroundColor(value)}`,
            borderRadius: "3px",
            width: "115px",
            height: "33px",
            textTransform: "none",
            padding: 0,
            "&:focus": {
              outline: "auto",
              outlineColor: "#000000",
              backgroundColor: "#d3d3d3",
            },
            "&:hover": {
              background: "#FFFFFF",
            },
          }}
        >
          <Fragment>
            <CheckCircleOutlineIcon fontSize="small" color="action" />
          </Fragment>
          <Typography
            id="contentId"
            sx={{
              color: `${value === "Confirmed" ? "#000304" : "#3A4F59"}`,
              margin: "1px 0 0 5px",
            }}
          >
            {content}
          </Typography>
        </Box>
      );
    }
    if (value === "Declined") {
      return (
        <BootstrapTooltip
          title={reason}
          placement="bottom"
          arrow
          role="tooltip"
        >
          <Box
            id={responseId}
            role="button"
            tabIndex={0}
            data-testid="response-note"
            elementType="button"
            aria-label={`${classTitle} declined. reason: ${reason} `}
            disableElevation
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `${borderColor(value)}`,
              background: `${backgroundColor(value)}`,
              borderRadius: "3px",
              width: "115px",
              height: "33px",
              textTransform: "none",
              padding: 0,
              "&:focus": {
                outline: "auto",
                border: "4px solid #3A4F59",
              },
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
          >
            <Fragment>
              <InfoIcon className={classes.infoIcon} />
            </Fragment>
            <Typography
              sx={{
                color: `${value === "Confirmed" ? "#000304" : "#3A4F59"}`,
                margin: "1px 0 0 5px",
              }}
            >
              {content}
            </Typography>
          </Box>
        </BootstrapTooltip>
      );
    }
    if (value === "Confirmed") {
      return (
        <Box
          id={responseId}
          role="button"
          tabIndex={0}
          data-testid="response-note"
          aria-label={`${classTitle} confirmed`}
          disableElevation
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `${borderColor(value)}`,
            background: `${backgroundColor(value)}`,
            borderRadius: "3px",
            width: "115px",
            height: "33px",
            textTransform: "none",
            padding: 0,
            "&:focus": {
              outline: "auto",
              outlineColor: "#000000",
              color: "#000000",
            },
          }}
        >
          <Typography
            sx={{
              color: `${value === "Confirmed" ? "#000304" : "#3A4F59"}`,
              margin: "1px 0 0 5px",
            }}
          >
            {content}
          </Typography>
        </Box>
      );
    }
  };

  return <Fragment>{displayNotification(value)}</Fragment>;
};

export default ResponseNotification;
