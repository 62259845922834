import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "flex-end",
  },
  filledButton: {
    borderRadius: "30px !important",
    padding: `${theme.breakpoints.up("sm") ? "8px 32px" : "2px 16px"}`,
    marginRight: "16px",
    width: "240px !important",
    height: "51px !important",
    backgroundColor: "#DC3727",
    color: "white",
    textTransform: "none",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
    "&:hover": {
      backgroundColor: `#DC3727`,
      outline: "auto",
      outlineColor: "#000000",
    },
  },
  outlinedButton: {
    width: "240px !important",
    height: "51px !important",
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 32px",
    backgroundColor: "#FFFFFF",
    border: "2px solid #DC3727",
    boxSizing: "border-box",
    borderRadius: "30px !important",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
  waitlistMessage: {
    fontStyle: "italic !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#68757c",
  },
}));

export default useStyles;
