import { GET_NOTIFICATIONS } from "./actionTypes";
import { getNotificationMessages } from "../../services/communication/v1/communication";

export const getNotifications = (personId) => async (dispatch) => {
    await getNotificationMessages(personId)
      .then((response) => {
        dispatch({ type: GET_NOTIFICATIONS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: GET_NOTIFICATIONS, payload: error.request });
      });
};