import { GET_WORKSHOPS } from "./actionTypes";

// eslint-disable-next-line 
export default (state = [], action) => {
   switch(action.type) {
      case GET_WORKSHOPS:
         return action.payload;
      default:
         return state;
   };
 };