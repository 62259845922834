import React from "react";
import { useState, useEffect } from "react";
import { Link } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import useStyles from "./SideBar.styles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from "@material-ui/icons/MailOutline";
import { connect } from "react-redux";
import {getTK20Authorization} from "../services/authorization/v1/authorization";


const handleLogout = (event) => {
  window.location.href = "/logout";
};

const SideBar = (props) => {
  const classes = useStyles();
  const { onClose } = props;

  const [hasTK20Auth, setHasTK20Auth] = useState(false);
  const [wsUser, setWsUser] = useState(false);

  const tk20Url = encodeURI(`${process.env.REACT_APP_TK20_LTI_URL}`)

  const list = [
    {
      title: "Account",
      section: [
        {
          item: "Human Resources",
          link: `${process.env.REACT_APP_HUMAN_RESOURCES_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Change Password",
          link: `${process.env.REACT_APP_CHANGE_PASSWORD_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Faculty Credentials",
          link: `${process.env.REACT_APP_UPDATE_YOUR_CREDENTIALS_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Logout",
          link: `/logout`,
          authorization: true,
          display: true,
        },
      ],
    },
    {
      title: "Faculty Resources",
      section: [
        {
          item: "Faculty Workshops",
          link: "/facultyworkshops",
          authorization: true,
          display: (new Date() >= new Date(process.env.REACT_APP_TOGGLE_DATE) || wsUser),
        },
        {
          item: "PhoenixView Faculty",
          link: `${process.env.REACT_APP_PHOENIXVIEW_FACULTY_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Faculty Resources Center",
          link: `${process.env.REACT_APP_FACULTY_RESOURCES_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "SEOCS Results",
          link: `${process.env.REACT_APP_SEOCS_RESULTS_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Microsoft Office 365",
          link: `${process.env.REACT_APP_MICROSOFT_OFFICE_365_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Viva Engage",
          link: `${process.env.REACT_APP_VIVA_ENGAGE_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "University Learning Systems",
          link: `${process.env.REACT_APP_UNIVERSITY_LEARNING_SYSTEMS_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Submit Expenses",
          link: "/expensesubmissions",
          authorization: true,
          display: true,
        },
        {
          item: "Research Hub",
          link: `${process.env.REACT_APP_RESEARCH_HUB_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Assessment Management Tool",
          link: `${process.env.REACT_APP_SSO_CALLBACK_URL}${tk20Url}`,
          authorization: hasTK20Auth,
          display: true,
        },
        {
          item: "PhoenixStore",
          link: `${process.env.REACT_APP_PHOENIX_STORE_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Faculty Credentials Resources",
          link: `${process.env.REACT_APP_FACULTY_CREDENTIALS_RESOURCES}`,
          authorization: true,
          display: true,
        },
      ],
    },
    {
      title: "Library Resources",
      section: [
        {
          item: "University Library",
          link: `${process.env.REACT_APP_UNIVERSITY_LIBRARY_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Citation Generator",
          link: `${process.env.REACT_APP_CITATION_GENERATOR_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Center for Collaboration",
          link: `${process.env.REACT_APP_CENTER_FOR_COLLABORATION_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Center for Writing Excellence",
          link: `${process.env.REACT_APP_CENTER_FOR_WRITING_EXCELLENCE_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Center for Math Excellence",
          link: `${process.env.REACT_APP_CENTER_FOR_MATH_EXCELLENCE_URL}`,
          authorization: true,
          display: true,
        },
        {
          item: "Voter Registration",
          link: `${process.env.REACT_APP_VOTER_REGISTRATION_URL}`,
          authorization: true,
          display: true,
        },
      ],
    },
    {
      title: "Connect",
      section: [
        {
          item: "Faculty Help Desk",
          link: "/helpdesksubmission",
          authorization: true,
        },
        {
          item: "Faculty Supervisor",
          link:
            props.supervisor.businessEmail === undefined
              ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
              : props.supervisor.businessEmail === null
              ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
              : `mailto:${props.supervisor.businessEmail} `,
          authorization: true,
        },
        {
          item: "My Scheduler",
          link:
            (!props.scheduler || !props.scheduler.length || !props.scheduler[0].email)
              ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
              : `mailto:${props.scheduler.filter(sched => sched.email).map(sched => sched.email).join(',') } `,
          authorization: true,
        },
      ],
    },
  ];

  const getTK20Auth = async (profileId) => {
    await getTK20Authorization(profileId)
    .then((response) => {
      setHasTK20Auth(response.data)
    })
    .catch((error) => console.log(error))
  };

  useEffect(() => {
    if (props.demographics.username) {
      process.env.REACT_APP_WS_USERS_USERNAME_LIST.includes(
        props.demographics.username
      )
        ? setWsUser(true)
        : setWsUser(false);
    }
  }, [props.demographics.username]); // eslint-disable-line react-hooks/exhaustive-deps
   
  useEffect(() => {
    if (props.demographics.externalSystemIds) {
      getTK20Auth(props.demographics.externalSystemIds[`PROFILE_ID`][0])
    }
  }, [props.demographics])

  return (
    <div className={classes.container}>
      <div className={classes.closeContainer}>
        <Button
          onClick={onClose}
          aria-label="close navigation menu"
          className={classes.closeButton}
        >
          <CloseIcon data-testid="closeIcon" className={classes.closeIcon} />
        </Button>
      </div>
      <div>
        <ul className={classes.unorderedList}>
          {list.map((listItem) => (
            <div key={listItem.title} className={classes.sectionContainer}>
              <Typography className={classes.title} variant="h2">
                {listItem.title}
              </Typography>

              {listItem.title === "Connect"
                ? listItem.section.map(
                    (sectionItem) =>
                      sectionItem.authorization && (
                        <div className={classes.logoContainer}>
                          {sectionItem.item !== "Faculty Help Desk" && (
                            <EmailIcon
                              data-testid="emailOutlineIcon"
                              className={classes.icon}
                            />
                          )} 
                          <Link href={sectionItem.link} color="inherit">
                            <Typography
                            className={
                               sectionItem.item !== "Faculty Help Desk" ?
                                 classes.iconText
                                 : classes.linkContainer
                            }
                            >
                              {sectionItem.item}
                            </Typography>
                          </Link>
                        </div>
                      )
                  )
                : listItem.section.map(
                    (sectionItem) =>
                      sectionItem.authorization &&
                      sectionItem.display && 
                      (sectionItem.item === "Logout" ? (
                        <div className={classes.logoContainer}>
                          <LogoutIcon
                            data-testid="logoutIcon"
                            className={classes.icon}
                          />
                          <Link
                            href={sectionItem.link}
                            color="inherit"
                            onClick={handleLogout}
                          >
                            <Typography className={classes.iconText}>
                              {sectionItem.item}
                            </Typography>
                          </Link>
                        </div>
                      ) : (
                        <div
                          key={sectionItem.item}
                          className={classes.linkContainer}
                        >
                          <li>
                            <Link
                              className={classes.link}
                              href={sectionItem.link}
                              target={
                                sectionItem.link === "/expensesubmissions" ||
                                sectionItem.item === "Change Password" ||
                                sectionItem.link === "/facultyworkshops"
                                  ? "_self"
                                  : "_blank"
                              }
                            >
                              <Typography className={classes.linkText}>{sectionItem.item}</Typography>
                            </Link>
                          </li>
                        </div>
                      ))
                  )}
            </div>
          ))}
          <div className={classes.bottomContainer}>
            <div className={classes.logoContainer}>
              <HelpOutlineIcon
                data-testid="helpOutlineIcon"
                className={classes.icon}
              />
              <Link href="/help">
                <Typography
                  className={classes.iconText}
                >{`Help & Support`}</Typography>
              </Link>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    scheduler: state.scheduler,
    supervisor: state.supervisor,
  };
};

export default connect(mapStateToProps)(SideBar);
