import { getFacultyCurrentCourses } from "../../services/faculty/v1/faculty";
import { GET_CURRENTCOURSES } from "./actionTypes";

export const getCurrentCourses = (irn) => async (dispatch) => {
  await getFacultyCurrentCourses(irn)
    .then((response) => {
      dispatch({ type: GET_CURRENTCOURSES, payload: response.data });
    })

    .catch((error) => {
      dispatch({
        type: GET_CURRENTCOURSES,
        payload: error.request,
      });
    });
};