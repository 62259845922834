import styles from "./Links.styles";
import { useMediaQuery } from "@material-ui/core";
import { Link, Box } from "@mui/material";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { useTheme } from "@mui/material/styles";

const LogoutLink = () => {
  // Allow for MUI styling
  const classes = styles();

  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // Function to change state of Drawer
  const handleLogout = (event) => {
    // return null
    window.location.href = "/logout";
  };

  return (
    <Box
      className={matches ? classes.linkContainerMobile : classes.linkContainer}
    >
      <Link
        aria-labelledby="logout"
        className={classes.linkButton}
        component="button"
        underline="none"
        color="inherit"
        onClick={handleLogout}
        id="logout"
        data-testid="logout"
      >
        <LogoutIcon titleAccess="" role="presentation" />
        {matches ? null : `Logout`}
      </Link>
    </Box>
  );
};

export default LogoutLink;
