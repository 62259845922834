import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  mainClassContainer: {
    //width: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    margin: "10px 0px 20px 0px",
  },
  courseDetailsButton: {
    height: "35px",
    // width: "260px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 32px",
    background: "#FFFFFF",
    border: "2px solid #DC3727",
    boxSizing: "border-box",
    borderRadius: "40px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
    "&:focus": {
      border: "2px solid #000000",
      outline: "none",
      color: "#000000",
    },
    "&:selected": {
      border: "2px solid #000000",
      outline: "none",
      color: "#000000",
    },
  },

  // Mobile View
  mainClassContainerMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    margin: "24px 0px 24px 0px",
  },
  courseDetailsButtonMobile: {
    width: "271px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 32px",
    background: "#FFFFFF",
    border: "2px solid #DC3727",
    boxSizing: "border-box",
    borderRadius: "40px",
    color: "#DC3727",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },

  // Universal for both Desktop and Mobile View
  courseIdentityBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  courseIdentity: {
    width: "500px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
    margin: "0px 0px 0px 0px",
  },
  infoBoxList: {
    listStyle: "none",
    paddingLeft: "0px",
    margin: "0px 0px 0px 0px",
  },
  courseInfo: {
    display: "flex",
  },
  infoDesc: {
    color: "#5E7078",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "25px",
  },
});
