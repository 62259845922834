import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from "../../images/background.png";

export default makeStyles({
  // Desktop View
  container: {
    height: "100%",
    width: "100vw",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "25% 0%",
  },
  linkBox: {
    marginTop: "2%",
    marginLeft: "5%",
    height: "24px",
  },
  backContainer: {
    width: "230px",
  },
  mainContent: {
    justifyContent: "center",
    margin: "0 auto",
  },
  pageHeader: {
    color: "#000304",
    fontSize: "38px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "300",
    lineHeight: "45px",
  },
  pageTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
  subContent: {
    display: "flex",
    flexDirection: "row",
    margin: "5% 0",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  pageHeaderBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "5%",
  },
  cardContainer: {
    marginLeft: "10px",
  },

  // Tablet View
  pageHeaderBoxTablet: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "50px",
  },
  pageHeaderTablet: {
    color: "#000304",
    fontSize: "38px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "300",
    lineHeight: "45px",
    margin: "40px 0px 20px 0px",
  },

  // Mobile View
  containerMobile: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F2F3F3",
    paddingTop: "24px",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 2%",
  },
  pageHeaderBoxMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "15px",
    padding: "0 17.5px",
  },
  courseHistoryContainerMobile: {
    flexDirection: "column",
  },
  linkBoxMobile: {
    paddingLeft: "15.5px",
  },
  backButtonAndText: {
    display: "flex",
    marginLeft: "-10px",
  },
  backTextMobile: {
    position: "relative",
    right: "-6px",
  },
  mainContentMobile: {
    justifyContent: "center",
    margin: "0 auto",
  },
  titleContainerMobile: {
    marginBottom: "31px",
  },
  pageHeaderMobile: {
    color: "#000304",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "300",
    lineHeight: "45px",
    margin: "30px 0px 20px 0px",
  },
  quickLinksContainerMobile: {
    marginTop: "28px",
  },
  cardsContainer: {
    marginBottom: "70px",
  },

  courseHistoryContainerTablet: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainerTablet: {
    marginBottom: "70px",
  },

  // Universal for both Desktop and Mobile View
  arrowImage: {
    position: "relative",
    width: "27px",
    height: "24px",
    marginRight: "5px",
  },
});
