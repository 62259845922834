import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  mainContainer: {
    marginBottom: "20px",
  },
  wrappingContainer: {
    //width: "745px",
    minHeight: "87px",
    border: "solid 1px #D2D7DA",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkedBackground: {
    //width: "745px",
    minHeight: "87px",
    border: "solid 1px #D2D7DA",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    background: "#DEEBFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  courseTextContainer: {
    margin: "0px 0px 0px 15px",
    maxWidth: "500px",
  },
  courseText: {
    fontWeight: "bold",
    fontSize: "17px",
    fontStyle: "normal",
    lineHeight: "24px",
  },
  previewContainer: {
    float: "right",
  },
  linkContainer: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  previewCourseText: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "16px",
    borderBottom: "solid 3px",
    marginRight: "15px",
  },

  // Mobile View
  checkedBackgroundMobile: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    alignContent: "space-between",
    justifyContent: "space-between",
    border: "solid 1px #D2D7DA",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    cursor: "pointer",
    background: "#DEEBFF",
    "&:hover": {
      background: "#ebecf0",
    },
  },
  mobileCardContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    alignContent: "space-between",
    justifyContent: "space-between",
    cursor: "pointer",
    border: "solid 1px #D2D7DA",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
  },
  courseNumber: {
    display: "inline-block",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
    borderBottom: "solid 3px",
    paddingTop: "11px",
    marginLeft: "10px",
  },
  courseName: {
    paddingTop: "8px",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    paddingBottom: "11px",
    marginLeft: "10px",
    maxWidth: "220px", 
  },
  checkBoxMobile: {
    marginRight: "5px",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
      border: "2px solid",
    },
  },

  // Universal used in both Desktop and Mobile View
  CheckBox: {
    "&.Mui-focusVisible": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
}));

export default useStyles;
