import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import styles from "./InfoFooter.styles";

const InfoFooter = (props) => {
  const { students, credits, weeks, courseOfferingType, unitType } = props;
  const classes = styles();

  return (
    <Box className={classes.footerStyle}>
      <Grid container spacing={1} className={classes.mainContent}>
        <Grid item sm={3} className={classes.leftBox}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {students}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Students`}
            </Typography>
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={classes.dividerColor}
        />
        <Grid item sm={3} className={classes.centerBox}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {credits}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {unitType}
              {/* {courseOfferingType === "DA" ? `Units` : `Credits`} */}
            </Typography>
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={classes.dividerColor}
        />
        <Grid item sm={3}  className={classes.rightBox}>
          <Typography
            component="p"
            sx={{ fontWeight: "700", lineHeight: "28px", fontSize: "24px" }}
          >
            {weeks}
            <Typography
              sx={{ lineHeight: "21px", fontSize: "15px" }}
              component="p"
            >
              {`Weeks`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoFooter;
