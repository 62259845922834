import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  desktopCard: {
    marginBottom: "20px",
    fontFamily: "Roboto",
    borderLeft: "5px solid #008A68",
  },
  desktopCardResponded: {
    marginBottom: "20px",
    fontFamily: "Roboto",
    borderLeft: "5px solid #98A3A9",
  },
  desktopCardContent: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "14px",
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
  },
  rightColumn: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  // Mobile View
  mobileCard: {
    width: "322px",
    marginBottom: "20px",
    borderLeft: "5px solid #008A68",
  },
  mobileCardResponded: {
    width: "322px",
    marginBottom: "20px",
    borderLeft: "5px solid #98A3A9",
  },
  moibleCardContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  mobileLeftColumn: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
  },
  mobileRightColumn: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
  },
  previewLink: {
    marginTop: "20px",
  },

  // Universal for both Desktop and Mobile View
  hide: {
    display: "none",
  },
  show: {
    display: "flex",
    justifyContent: "flex-end",
  },
});
