import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from "../../images/background.png";

export default makeStyles({
  // Desktop View
  pageContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "25% 0%",
  },
  linkBox: {
    marginTop: "2%",
    marginLeft: "5%",
    height: "24px",
  },
  backContainer: {
    display: "flex",
  },
  mainContent: {
    maxWidth: "1002px",
    justifyContent: "center",
    margin: "0 auto",
  },
  pageHeaderBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  helpCardContainer: {
    margin: "75px 0 75px 0px",
    flexDirection: "row",
  },
  linksContainer: {
    marginBottom: "131px",
    marginLeft: "-15px",
  },
  communitySection: {
    margin: "0 50px 0 -50px",
  },
  hideGrid: {
    display: "none",
  },

  // Tablet view
  pageHeaderBoxTablet: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "50px",
  },
  helpCardContainerTablet: {
    justifyContent: "center",
    marginBottom: "75px",
  },
  helpCardTablet: {
    marginBottom: "15px",
  },
  helpPageHeaderTablet: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "48px",
    color: "#001823",
    margin: "40px 0px 20px 0px",
  },
  linksContainerTablet: {
    marginBottom: "115px",
    justifyContent: "center",
  },
  pageDescription: {
    fontFamily: "IBM Plex Serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#001823",
    textAlign: "center",
  },
  policySectionTablet: {
    marginTop: "50px",
  },

  // Mobile View
  pageContainerMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    paddingTop: "24px",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 2%",
  },
  linkBoxMobile: {
    paddingLeft: "35.5px",
  },
  backContainerMobile: {
    display: "flex",
    marginLeft: "24px",
  },
  mainContentMobile: {
    justifyContent: "center",
  },
  pageHeaderBoxMobile: {
    marginBottom: "50px",
    padding: "0 17.5px",
  },
  helpCardContainerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "75px",
  },
  linksContainerMobile: {
    marginBottom: "115px",
    justifyContent: "center",
    padding: "0 27.5px",
  },
  linkSectionMobile: {
    marginLeft: "20px",
  },
  pageDescriptionMobile: {
    fontFamily: "IBM Plex Serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#001823",
  },

  // Universal for both Desktop and Mobile
  helpPageHeader: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "48px",
    color: "#001823",
    margin: "20px 0px",
  },
  lineBreak: {
    width: "92px",
    height: "3px",
    background: "#DB3725",
    border: "none",
    margin: "25px 0px 0px 0px",
  },
  arrowImage: {
    position: "relative",
    width: "27px",
    height: "24px",
    marginRight: "10px",
  },
});
