import React from "react";
import useStyles from "./Frame3.styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography, Link } from "@material-ui/core";

const Frame3 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return matches ? (
    <>
      <div className={classes.container}>
        <Typography className={classes.listItem1} component="p">
          {`Copyright © 2020 University of Phoenix`}
        </Typography>
        <Link
          className={classes.listItem2}
          href={`${process.env.REACT_APP_CA_RESIDENTS_URL}`}
          target="_blank"
          underline="always"
        >
          {`CA residents: Do not sell my personal information`}
        </Link>
      </div>
    </>
  ) : (
    <>
      <div className={classes.tabletContainer}>
        <Typography className={classes.listItem1} component="p">
          {`Copyright © 2020 University of Phoenix`}
        </Typography>
        <Link
          className={classes.listItem2}
          href={`${process.env.REACT_APP_CA_RESIDENTS_URL}`}
          target="_blank"
          underline="always"
        >
          {`CA residents: Do not sell my personal information`}
        </Link>
      </div>
    </>
  );
};

export default Frame3;
