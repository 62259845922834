import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  mainContainer: {
    //width: "807px",
    overflow: "auto",
    radius: "3px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
  },
  paddingContainer: {
    paddingTop: "28px",
    paddingLeft: "28px",
    paddingRight: "34px",
  },
  titleContainer: {
    marginBottom: "10px",
  },
  subtextContainer: {
    paddingBottom: "28px",
  },
  buttonContainer: {
    paddingTop: "33px",
    marginBottom: "80px",
  },
  saveButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 48px",
    width: "196px",
    height: "51px",
    color: "white",
    borderRadius: "40px !important",
    float: "right",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
  savedButtonBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "-33px",
  },

  // Mobile View
  mobileMainContainer: {
    width: "327px",
    overflow: "auto",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
  },
  mobilePaddingContainer: {
    width: "285px",
    paddingTop: "24px",
  },
  mobileTitleContainer: {
    paddingBottom: "12px",
  },
  mobileSubtextContainer: {
    paddingBottom: "26px",
  },
  mobileButtonContainer: {
    paddingTop: "33px",
    marginBottom: "28px",
  },
  mobileSaveButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 48px",
    width: "285px",
    height: "51px",
    borderRadius: "40px !important",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
  mobileSavedButtonBox: {
    display: "flex",
    justifyContent: "center",
  },

  // Universal for Desktop and Mobile View
  saveChangesText: {
    height: "19px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19px",
    textTransform: "none",
    color: "white",
  },
  disabledText: {
    height: "19px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19px",
    textTransform: "none",
    color: "#3A4F59 !important",
  },
  credentialsLink: {
    width: "210px",
    display: "flex",
    float: "right",
    marginBottom: "30px",
  },
}));

export default useStyles;
