import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import userManager from '../utils/userManager';
import LoadingModal from './LoadingModal';

const CallbackComponentWrapper = ({ dispatch }) => {
  	const [isErrored, setIsErrored] = React.useState(false);
  	const successCallback = user => {
    	const returnUrl = (user.state || {}).redirectUrl || '/';
		console.log('RedirectUrl', returnUrl );
		dispatch(push(returnUrl));
  	};

  	const errorCallback = error => {
    	setIsErrored(true);
		console.log('Error', error );
  	};

	return (
		<CallbackComponent
		  	userManager={userManager}
		  	successCallback={successCallback}
		  	errorCallback={errorCallback}>
		  	<LoadingModal />
		</CallbackComponent>
	);
};

CallbackComponentWrapper.propTypes = {
  	dispatch: PropTypes.func.isRequired,
};

export default connect()(CallbackComponentWrapper);