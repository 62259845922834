const csrfHeaderName = 'CSRF-Token';
const csrfCookieName = 'CSRF-Token';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default config => {
    const configUpdate = config;
    configUpdate.withCredentials = true;
    configUpdate.xsrfCookieName = csrfCookieName;
    configUpdate.xsrfHeaderName = csrfHeaderName;
    return configUpdate;
};