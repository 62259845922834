import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  cardContainer: {
    maxWidth: "817px",
    background: "white",
    padding: "30px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    marginBottom: "70px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  emailButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 48px",
    background: "white",
    height: "51px",
    borderRadius: "40px !important",
    marginRight: "20px",
    border: "2px solid #DC3725",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
    "&:hover": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
  submitExpenseButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 48px",
    background: "#DC3727",
    height: "51px",
    color: "white",
    borderRadius: "40px !important",
    border: "none",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
    "&:hover": {
      background: "#DC3727",
      outline: "auto",
      outlineColor: "#000000",
    },
  },

  // Mobile View
  mobileCardContainer: {
    width: "297px",
    background: "white",
    padding: "30px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  centerButtonContainerMobile: {
    display: "flex",
    justifyContent: "center",
  },
  submitExpenseButtonMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 48px",
    background: "#DC3727",
    width: "267px",
    height: "51px",
    marginTop: "20px",
    color: "white",
    borderRadius: "40px !important",
    border: "none",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
  emailButtonMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 48px",
    background: "white",
    height: "51px",
    width: "267px",
    borderRadius: "40px !important",
    border: "2px solid #DC3725",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },

  // Universal for Desktop and Mobile View
  title: {
    color: "#5E7078",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "16px",
  },
  subHeading: {
    color: "#001823",
    fontSize: "24px",
    lineHeight: "28px",
    marginTop: "16px",
    marginBottom: "25px",
  },
  bottomMargin: {
    marginBottom: "35px",
  },
  listItem: {
    marginBottom: "15px",
    fontFamily: "Roboto",
    fontStyle: "normal",
  },
  submitButtonText: {
    height: "19px",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "19px",
    textTransform: "none",
    color: "white",
  },
  emailButtonText: {
    height: "19px",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "19px",
    textTransform: "none",
    color: "#DC3725",
  },
});
