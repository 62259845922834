import facultyPortalStyle from "../../../styles";
// import Notification from "./Notification";
// import NotificationParticipationRequested from "./NotificationParticipationRequested";
// import NotificationActionRequired from "./NotificationActionRequired";
import { useState, useEffect } from "react";
import NotificationsCard from "./NotificationsCard";
import { connect } from "react-redux";
import { getNotifications } from "../../../redux/notifications/updateNotificationsActions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { storeErrorMessages } from "../../../redux/displayApiErrors/displayApiErrorsActions";

const Notifications = (props) => {
  // Allow for MUI styling
  const facultyClasses = facultyPortalStyle();
  const [openSuccess, setOpenSuccess] = useState(false);

  // Utilize useMediaQuery to create UI view for Mobile view
  let count = 0;

  useEffect(() => {
    if (props.demographics.personId) {
      props.getNotifications(props.demographics.personId);
      // props.getNotifications("a12312312358938"); // fake IRN for testing purposes
    }
  }, [props.demographics.personId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.updateNotifications.status !== undefined &&
      props.updateNotifications.status !== 200
    ) {
      props.storeErrorMessages(
        "We couldn't find your notifications.",
        "notifications"
      );
    } else {
      setOpenSuccess(false);
    }
  }, [props.updateNotifications.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "notifications"
    ) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      props.getNotifications(props.demographics.personId);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <>
      {props.updateNotifications.notifications === undefined ? null : (
        <ul className={facultyClasses.unorderedList}>
          {props.updateNotifications.notifications.map((notification) => (
            <li>
              <NotificationsCard
                title={
                  notification.title ? notification.title : "Action Required"
                }
                link={notification.link}
                notificationAriaLabel={`${notification.title}`}
                ariaLinkId={`view_message_link_${count}`}
              />
            </li>
          ))}
        </ul>
      )}
      {openSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar("error", "We couldn't find your notifications.")
          : "")}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    updateNotifications: state.updateNotifications,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (personId) => dispatch(getNotifications(personId)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
