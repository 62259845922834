import { SET_MODIFIED_COURSE_LIST } from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
        case SET_MODIFIED_COURSE_LIST:
            return action.payload;
        default:
            return state;
    }
}