import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  buttonContainer: {
    display:"flex",
    alignItems:"flex-end"
  }
}));

export default useStyles;