import { GET_SCHEDULER } from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
  switch (action.type) {
    case GET_SCHEDULER:
      return action.payload;
    default:
      return state;
  }
};
