import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  headerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    position: "sticky",
    top: "0px",
    zIndex: "999",
  },

  subheaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  // Mobile View
  headerContainerMobile: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    position: "fixed",
    zIndex: "999",
    background: "#E2E6E7",
  },
  navigateContainerMobile: {
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF",
  },
});
