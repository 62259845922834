import { ACTIONS } from './types';

const requestLogout = () => ({
  type: ACTIONS.REQUEST_LOGOUT,
  payload: {},
});

const requestLogoutCanceled = () => ({
  type: ACTIONS.REQUEST_LOGOUT_CANCELED,
  payload: {},
});

const loggingOut = () => ({
  type: ACTIONS.LOGGINGOUT,
  payload: {},
});

const loggingOutError = error => ({
  type: ACTIONS.LOGGINGOUT_ERROR,
  payload: { error },
});

export const logoutUser = function logoutUser(tokenId) {
  // return async (dispatch, getState, { Services }) => {
    return async (dispatch, getState,) => {
    dispatch(requestLogout());
    try {
      if (tokenId) {
        // Services.logoutService.logout(tokenId);
    sessionStorage.removeItem("userToken");
    sessionStorage.clear("token"); 
    window.localStorage.clear("token");
        logoutUser(tokenId);
        dispatch(loggingOut());
      } else {
        dispatch(requestLogoutCanceled());
      }
    } catch (error) {
      dispatch(loggingOutError(error));
    }
  };
};
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  logoutUser,
};
