import { makeStyles } from "@material-ui/core";

export default makeStyles({
    // Desktop View
    mainContainer: {
        height: '77px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '3px',
        background: '#FFFFFF',
        marginBottom: '20px',
    },
    infoContainer: {
        width: '100%',
        margin: '0px 33px 0px 30px',
    },

    // Mobile View
    mainContainerMobile: {
        width: '322px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: '3px',
        background: '#FFFFFF',
        marginBottom: '20px',
    },
    responseMobile: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    linkMobile: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    }
});