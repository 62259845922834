import { makeStyles } from "@material-ui/core";
import BackgroundPhoenix from "../../images/background.png";

export default makeStyles({
  // Desktop View
  pageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "2%",
    marginLeft: "5%",
    marginBottom: "48px",
    height: "24px",
    width: "100%",
  },
  pageHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeader: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "40px",
    lineHeight: "48px",
  },
  pageIntroContainer: {
    width: "969px",
  },
  mainInfoContainer: {
    width: "969px",
  },
  subHeader: {
    fontFamily: "Roboto",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "40px",
  },
  textFieldsBox: {
    display: "flex",
    flexDirection: "column",
    marginRight: "56px",
    marginBottom: "40px",
  },

  // Tablet view
  pageContainerTablet: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "350px 400px",
    backgroundPosition: "10% 0%",
    overflow: "hidden",
  },
  mainPageContentTablet: {
    width: "740px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerTablet: {
    width: "100%",
    marginTop: "40px",
    marginBottom: "48px",
  },
  pageHeaderTablet: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "40px",
  },

  // Mobile View
  pageContainerMobile: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F3F3",
    backgroundImage: `url(${BackgroundPhoenix})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "317px 396px",
    backgroundPosition: "center 2%",
    overflow: "hidden",
  },
  mainPageContentMobile: {
    width: "327px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 auto",
  },
  linkContainerMobile: {
    width: "100%",
    margin: "40px 0px",
  },
  pageHeaderContainerMobile: {
    // for both mobile and tablet
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "64px",
  },
  pageHeaderMobile: {
    fontFamily: "Roboto",
    fontWeight: "300",
    fontSize: "24px",
    lineHeight: "32px",
  },
  pageIntroContainerMobile: {
    // for both mobile and tablet
    width: "100%",
  },
  mainInfoContainerMobile: {
    // for both mobile and tablet
    width: "100%",
  },
  subHeaderMobile: {
    // for both mobile and tablet
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  textFieldsBoxMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "40px",
  },

  // Universal (used in desktop, tablet and mobile)
  arrowImage: {
    width: "27px",
    height: "24px",
    marginRight: "10px",
  },
  backToDashboardLink: {
    display: "flex",
    color: "#001823",
    "&hover": {
      cursor: "pointer",
      color: "#001823",
    },
  },
  inputTextField: {
    maxWidth: "100%",
    background: "#E3E6E7",
    border: "1px solid",
    marginBottom: "24px",
    '& .MuiFormLabel-root.Mui-disabled': {
      opacity: "1",
      color: "#000000", 
    },
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: "1",
      color: "#000000"
    },
  },
  checkboxFieldset: {
    maxWidth: "609px",
    border: "0px",
    padding: "0px",
    marginBottom: "64px",
  },
  fieldsetLegend: {
    fontSize: "32px",
    fontFamily: "Roboto",
    fontWeight: "700",
    lineHeight: "40px",
    marginBottom: "20px",
  },
  fieldsetLabel: {
    fontSize: "16px",
    fontFamily: "Roboto",
    fontWeight: "700",
    lineHeight: "24px",
  },
  checkboxListContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: "0px",
    boxSizing: "border-box",
    flexBasis: "0%",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "12px",
  },
  checkbox: {
    width: "24px",
    height: "24px",
    margin: "12px 12px 12px 0px",
    borderRadius: "0px",
    cursor: "pointer",
    boxSizing: "border-box"
  },
  checkboxLabel: {
    width: "auto",
    fontSize: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  commentsTextArea: {
    width: "100%",
    height: "112px",
    border: "1px solid",
    borderRadius: "4px",
    background: "#ffffff",
    marginTop: "-40px",
    '& .MuiFormLabel-root': {
      color: "#000000",
      opacity: "1",
    },
  },
  submitButton: {
    width: "240px",
    height: "56px",
    color: "#ffffff",
    background: "#DB3725",
    border: "none",
    borderRadius: "32px",
    textTransform: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    "&:hover": {
      cursor: "pointer",
      color: "#ffffff",
      background: "#DB3725",
    },
    "&.Mui-disabled": {
      backgroundColor: "#E3E6E7",
      color: "#000000",
    },
  },
  infoTextOne: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    marginBottom: "24px",
    marginLeft: "-15px",
  },
  infoTextTwo: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginBottom: "20px",
  },
  infoTextTwoImportant: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    marginBottom: "20px",
    color: "#B21F13",
  },
  infoTextThree: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    fontStyle: "italic",
    marginBottom: "24px",
  },
  errorbox: {
    display: "flex",
    height: "34px",
    backgroundColor: "#FBEBEA",
    border: "1px solid",
    borderColor: "#B21F13",
    borderRadius: "4px",
    marginTop: "15px",
  },
  errorboxComments: {
    display: "flex",
    height: "34px",
    backgroundColor: "#FBEBEA",
    border: "1px solid",
    borderColor: "#B21F13",
    borderRadius: "0 0 4px 4px",
  },
  errorboxText: {
    color: "#B21F13",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "18px",
    padding: "9px 0 7px 0",
  },
  hide: {
    display: "none",
  },
});
