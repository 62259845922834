import { Typography, Button } from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const useStyle = makeStyles((theme) => ({
  // Universal for Desktop and Mobile View
  hide: {
    display: "none",
  },
  reset: {
    display: "block",
  },
}));

const ResponseFormSubmitButton = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { submitDisplay, submitVal, bg, responseValue } = props;
  return (
    <Button
      data-testid="submit-button"
      type="submit"
      value="submit"
      variant={submitVal ? "container" : "disabled"}
      tabIndex={submitVal ? 0 : -1}
      className={responseValue !== null ? classes.reset : classes.hide}
      sx={
        matches
          ? {
              width: "116px",
              height: "35px",
              margin: "28px 0 6px 0",
              color: "white",
              backgroundColor: `${bg}`,
              borderRadius: "40px",
              textTransform: "none",
              display: `${submitDisplay}`,
              "&:focus": {
                outline: "auto",
                outlineColor: "#000000",
              },
              "&:hover": {
                backgroundColor: `${bg}`,
                outline: "auto",
                outlineColor: "#000000",
              },
              "&.Mui-disabled": {
                backgroundColor: "#E3E6E7",
                color: "#5E7079",
              },
            }
          : {
              width: "225px",
              height: "35px",
              margin: "28px 0 6px 0",
              color: "white",
              backgroundColor: `${bg}`,
              borderRadius: "40px",
              textTransform: "none",
              display: `${submitDisplay}`,
              "&:focus": {
                outline: "auto",
                outlineColor: "#000000",
              },
              "&:hover": {
                backgroundColor: `${bg}`,
                outline: "auto",
                outlineColor: "#000000",
              },
              "&.Mui-disabled": {
                backgroundColor: "#E3E6E7",
                color: "#5E7079",
              },
            }
      }
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "16px",
        }}
      >
        {`Submit`}
      </Typography>
    </Button>
  );
};

export default ResponseFormSubmitButton;
