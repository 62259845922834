export const sortByCourseIdAscending = (courseHistoryList) => {
  if (courseHistoryList.length === undefined) {
    return []
  } 
  else if (courseHistoryList.length <= 1) {
    return courseHistoryList;
  } 
  else {
    let left = [];
    let right = [];
    let newArray = [];
    let pivot = courseHistoryList.pop();
    for (let course of courseHistoryList) {
      if (course.courseId <= pivot.courseId) {
        left = [...left, course];
      } else {
        right = [...right, course];
      }
    }
    return newArray.concat(
      sortByCourseIdAscending(left),
      pivot,
      sortByCourseIdAscending(right)
    );
  }
};

export const sortByCourseIdDescending = (courseHistoryList) => {
  if (courseHistoryList === undefined) {
    return []
  } 
  else if (courseHistoryList.length <= 1) {
    return courseHistoryList;
  } 
  else {
    let left = [];
    let right = [];
    let newArray = [];
    let pivot = courseHistoryList.pop();
    for (let course of courseHistoryList) {
      if (course.courseId >= pivot.courseId) {
        left = [...left, course];
      } else {
        right = [...right, course];
      }
    }
    return newArray.concat(
      sortByCourseIdDescending(left),
      pivot,
      sortByCourseIdDescending(right)
    );
  }
};

export const sortByCourseDateAscending = (courseHistoryList) => {
  if (courseHistoryList.length === undefined) {
    return []
  } 
  else if (courseHistoryList.length <= 1) {
    return courseHistoryList;
  } 
  else {
    let left = [];
    let right = [];
    let newArray = [];

    let pivot = courseHistoryList.pop();
    let pivotStartDate = new Date(pivot.startDate.split(" ")[0]);

    for (let i = 0; i < courseHistoryList.length; i++) {
      let courseStartDate = new Date(courseHistoryList[i].startDate.split(" ")[0]);
      if (courseStartDate <= pivotStartDate) {
        left = [...left, courseHistoryList[i]];
      } else {
        right = [...right, courseHistoryList[i]];
      }
    }
    return newArray.concat(
      sortByCourseDateAscending(left),
      pivot,
      sortByCourseDateAscending(right)
    );
  }
};

export const sortByCourseDateDescending = (courseHistoryList) => {
  if (courseHistoryList.length === undefined) {
    return []
  } 
  else if (courseHistoryList.length <= 1) {
    return courseHistoryList;
  } 
  else {
    let left = [];
    let right = [];
    let newArray = [];

    let pivot = courseHistoryList.pop();
    let pivotStartDate = new Date(pivot.startDate.split(" ")[0]);

    for (let i = 0; i < courseHistoryList.length; i++) {
      let courseStartDate = new Date(courseHistoryList[i].startDate.split(" ")[0]);
      if (courseStartDate >= pivotStartDate) {
        left = [...left, courseHistoryList[i]];
      } else {
        right = [...right, courseHistoryList[i]];
      }
    }
    return newArray.concat(
      sortByCourseDateDescending(left),
      pivot,
      sortByCourseDateDescending(right)
    );
  }
};

export const sortByCourseRole = (courseHistoryList) => {
  if (courseHistoryList.length === undefined) {
    return []
  } 
  else if (courseHistoryList.length <= 1) {
    return courseHistoryList;
  } 
  else {
    let left = [];
    let right = [];
    let newArray = [];
    let pivot = courseHistoryList.pop();
    for (let course of courseHistoryList) {
      if (course.schedulingRole <= pivot.schedulingRole) {
        left = [...left, course];
      } else {
        right = [...right, course];
      }
    }
    return newArray.concat(
      sortByCourseRole(left),
      pivot,
      sortByCourseRole(right)
    );
  }
};

export const sortByCampus = (courseHistoryList) => {
  if (courseHistoryList.length === undefined) {
    return []
  } 
  else if (courseHistoryList.length <= 1) {
    return courseHistoryList;
  } 
  else {
    let left = [];
    let right = [];
    let newArray = [];
    let pivot = courseHistoryList.pop();
    for (let course of courseHistoryList) {
      if (course.orgName <= pivot.orgName) {
        left = [...left, course];
      } else {
        right = [...right, course];
      }
    }
    return newArray.concat(
      sortByCampus(left),
      pivot,
      sortByCampus(right)
    );
  }
};
