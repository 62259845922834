import { useState, useEffect } from "react";
import { Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, Typography, Grid } from "@material-ui/core";
import CoursePrefCard from "../../coursePreferences/components/CoursePrefCard";
import ContactCard from "../../../components/SchedulerCard";
import CourseHistory from "./courseHistoryMain/Accordion";
import QuickLinks from "../../dashboard/components/quickLinksContainer/QuickLinks";
import SupervisorCard from "../../../components/SupervisorCard";
import styles from "../styles";
import facultyPortalStyles from "../../../styles";
import BlackArrow from "../../../images/BlackArrow.svg";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";

const MyCoursesPage = (props) => {
  const classes = styles();
  const facultyClasses = facultyPortalStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  document.title = "Faculty Portal My Courses";

  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    if (props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        id="main"
        role="main"
        className={
          matches
            ? facultyClasses.pageContainer
            : facultyClasses.pageContainerMobile
        }
      >
        <Grid
          className={matches ? classes.mainContent : classes.mainContentMobile}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            className={matches ? classes.linkBox : classes.linkBoxMobile}
            direction="row"
            alignItems="center"
          >
            <Link
              underline="none"
              sx={{
                color: "#001823",
                display: "flex",
                justifyContent: "space-between",
                "&hover": {
                  cursor: "pointer",
                },
              }}
              href="/"
            >
              <img className={classes.arrowImage} src={BlackArrow} alt="" />
              <Typography>{`Back to Course Dashboard`}</Typography>
            </Link>
          </Grid>
          <Grid
            container
            direction="column"
            sm={12}
            xs={11}
            className={
              matches ? classes.pageHeaderBox : classes.pageHeaderBoxMobile
            }
          >
            <Grid item sm={8}>
              <Typography
                className={
                  matches ? classes.pageHeader : classes.pageHeaderMobile
                }
                component="h1"
              >{`Course History`}</Typography>
            </Grid>
          </Grid>

          <Grid>
            <Grid
              container
              direction="row"
              className={
                matches
                  ? classes.courseHistoryContainer
                  : classes.courseHistoryContainerMobile
              }
              spacing={3}
            >
              <Grid item md={9}>
                <CourseHistory />
              </Grid>

              {matches ? (
                <Grid
                  item
                  sm={4}
                  md={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={matches ? classes.cardContainer : ""}
                >
                  <CoursePrefCard
                    data-testid="coursePreference"
                    title="COURSE PREFERENCES"
                    content="Improve solicitation results, by keeping your teaching preferences up to date."
                    linkName="Edit preferences"
                    link="/coursepreferences"
                  />
                  <SupervisorCard data-testid="universityContacts" />
                  <ContactCard />
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item className={matches ? classes.cardContainer : ""}>
                    <CoursePrefCard
                      data-testid="coursePreference"
                      title="COURSE PREFERENCES"
                      content="Improve solicitation results, by keeping your teaching preferences up to date."
                      linkName="Edit preferences"
                      link="/coursepreferences"
                    />
                    <SupervisorCard data-testid="universityContacts" />
                    <ContactCard />
                    <Grid
                      container
                      className={classes.quickLinksContainerMobile}
                    >
                      <QuickLinks />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessages: state.errorMessages,
  };
};

export default connect(mapStateToProps)(MyCoursesPage);
