import { GET_WORKSHOPS,  GET_SECTIONS_FOR_IRN } from "./actionTypes";
import { getWorkshops, getSectionsForIrn } from "../../services/faculty/v1/faculty";

export const getWorkshopList = (workshopObject) => async (dispatch) => {
    console.log(workshopObject)
    await getWorkshops(workshopObject)
        .then((response) => {
            dispatch({ type: GET_WORKSHOPS, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: GET_WORKSHOPS, payload: error.request });
        });
};

export const getSectionsByIrn = (irn) => async (dispatch) => {
    await getSectionsForIrn(irn)
        .then((response) => {
            dispatch({ type: GET_SECTIONS_FOR_IRN, payload: response.data });
        })
        .catch((error) => {
            dispatch({ type: GET_SECTIONS_FOR_IRN, payload: error.request });
        });
  };