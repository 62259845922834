import React from "react";
import useStyles from "./LinkBlock.styles";
import { Typography, Link } from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import facultyPortalStyles from "../../../styles";

const LinkBlock = ({ title, listItem, helpBlock }) => {
  const classes = useStyles();
  const facultyClasses = facultyPortalStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={helpBlock ? classes.helpTitleContainer : ""}>
          <Typography
            className={
              helpBlock ? facultyClasses.linkHeading1 : classes.title
            }
            component="h2"
          >
            {title}
          </Typography>
        </div>
        <ul className={classes.blockList}>
          {listItem.map((item) => (
            item.authorization &&
            item.display &&
            <li
              className={helpBlock ? classes.helpListItem : classes.listItem}
              key={item.item}
            >
              <Link
                className={helpBlock ? classes.helpLink : classes.link}
                href={item.link}
                target={
                  item.link === "/help" || 
                  item.link === "/expensesubmissions" || 
                  item.link === "/logout" || 
                  item.item === "Change Password" ||
                  item.link === "/facultyworkshops" ||
                  item.link === "/helpdesksubmission"
                    ? "_self"
                    : "_blank"
                }
              >
                {item.link === "/logout" && 
                  <LogoutIcon data-testid="logoutIcon"/>
                }
                {item.item}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LinkBlock;
