import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import { getSupervisor } from "../redux/supervisor/supervisorActions";
import CardLink from "./CardLink";

const useStyle = makeStyles(() => ({
  // Desktop View
  desktopCard: {
    width: "257px",
    marginBottom: "20px",
  },
  desktopCardContent: {
    padding: "0px !important",
    margin: "25px",
  },

  // Mobile View
  mobileCard: {
    width: "327px",
    marginBottom: "24px",
  },
  mobileCardContent: {
    padding: "0px !important",
    margin: "25px",
  },
}));

const SupervisorCard = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const truncateString = (string = '', maxLength = 50) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string
    
  const firstName = toTitleCase(
    `${props.supervisor.firstName}`
  );

  return (
    <Card className={matches ? classes.desktopCard : classes.mobileCard}>
      <CardContent
        className={
          matches ? classes.desktopCardContent : classes.mobileCardContent
        }
      >
        <Typography
          component="h2"
          data-testid="universityContacts"
          variant="subtitle1"
          color="secondary"
        >
          {`University Contacts`}
        </Typography>
        <Box
          sx={
            matches
              ? { marginTop: "16px", marginBottom: "2px" }
              : { marginTop: "14px", marginBottom: "2px" }
          }
        >
          <Typography
            component="h3"
            variant="subtitle2"
            data-testid="universityContacts"
          >
            {`Faculty Supervisor`}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "15px",
            letterSpacing: "-0.01em",
            marginTop: "10px",
          }}
        >
          {props.supervisor.firstName === null
            ? "No Supervisor information available currently"
            : props.supervisor.firstName === undefined
            ? "No Supervisor information available currently"
            : toTitleCase(
                `${props.supervisor.firstName} ${props.supervisor.lastName}`
              )}
        </Typography>
        <Box sx={{ color: "#3A4F59" }}>
          {(props.supervisor.businessEmail !== null && props.supervisor.businessEmail !== undefined) ?
            props.supervisor.businessEmail.length > 25 ?
              <Tooltip 
                title={`${props.supervisor.businessEmail}`}
                placement="right"
              >
                <Typography 
                  sx={{ fontSize: "15px", letterSpacing: "-0.01em" }}
                  className={classes.emailText}
                  aria-label={`${props.supervisor.businessEmail}`}
                >
                  {truncateString(`${props.supervisor.businessEmail}`, 25)}
                </Typography>
              </Tooltip>
            :
              <Typography 
                sx={{ fontSize: "15px", letterSpacing: "-0.01em" }}
                className={classes.emailText}
                aria-label={`${props.supervisor.businessEmail}`}
              >
                {`${props.supervisor.businessEmail}`}
              </Typography>
          : null
          }
        </Box>
        <Box sx={{ marginTop: "10px" }}>
          {(props.supervisor.businessEmail !== null && props.supervisor.businessEmail !== undefined) &&
            <CardLink
              content={ props.supervisor.firstName ? `Email ${firstName}` : null}
              link={`mailto:${props.supervisor.businessEmail}`}
            />
          }
        </Box>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    supervisor: state.supervisor,
    demographics: state.demographics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSupervisor: (irn) => dispatch(getSupervisor(irn)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorCard);
