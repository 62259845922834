import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import CardLink from "../../../components/CardLink";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const useStyle = makeStyles(() => ({
  // Desktop View
  desktopCard: {
    width: "257px",
    marginBottom: "20px",
    fontFamily: "Roboto",
  },
  desktopCardContent: {
    margin: "12px 6px 0 12px",
  },
  desktopLink: {
    display: "flex",
    marginTop: "32px",
  },

  // Mobile View
  mobileCard: {
    width: "327px",
    height: "178px",
    marginBottom: "24px",
  },
  mobileCardContent: {
    margin: "4px 5px",
    paddingBottom: "0 !important",
  },
  mobileLink: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const CoursePrefCard = (props) => {
  const { title, content, linkName, link } = props;
  const classes = useStyle();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Card className={matches ? classes.desktopCard : classes.mobileCard}>
      <CardContent
        className={
          matches ? classes.desktopCardContent : classes.mobileCardContent
        }
      >
        <Typography component="h2" variant="subtitle1" color="secondary">
          {title}
        </Typography>
        <Typography
          sx={
            matches
              ? { marginTop: "13px", marginBottom: "23px" }
              : { marginTop: "13px", marginBottom: "18px" }
          }
          variant="body1"
        >
          {content}
        </Typography>
        <Box className={matches ? classes.desktopLink : classes.mobileLink}>
          <CardLink
            link={link}
            content={linkName}
            externalLink={linkName === "Human Resources" ? true : false}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CoursePrefCard;
