import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";
import { OidcProvider } from 'redux-oidc';
import userManager from "./utils/userManager";
import facultyPortalTheme from "./facultyPortalTheme";
import { ThemeProvider } from '@mui/material/styles';

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <ThemeProvider theme={facultyPortalTheme}>
        <App />
      </ThemeProvider>
	  </OidcProvider>
  </Provider>,
  document.getElementById('root')
);