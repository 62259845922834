import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  centerTopContainer: {
    margin: "0 auto",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "75px 0px 75px 0px",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },

  bottomContainerTablet: {
    justifyContent: "space-evenly",
  },

  // mobile styling below
  mobileCardWrapper: {
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    padding: "20px 25px",
  },
}));

export default useStyles;
