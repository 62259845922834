import { useState } from "react";
import { Typography, Grid, Link } from "@mui/material";
import useStyles from "./FAQcard.styles";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FAQcard = (props) => {
  const classes = useStyles();

  const [expand1, setExpand1] = useState(false);

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
          borderBottom: "2px solid #DEE1E2",
        }}
      >
        <Typography
          className={expand1 ? classes.redText : ""}
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            margin: "2px 0 22px 0",
          }}
          component="h3"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => setExpand1(!expand1)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                setExpand1(!expand1);
              }
            }}
            role="button"
            tabIndex="0"
            aria-expanded={expand1}
          >
            {props.question}
            {expand1 ? (
              <RemoveIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: "#DB3725",
                  cursor: "pointer",
                }}
              />
            ) : (
              <AddIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: "#DB3725",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        </Typography>

        <Grid
          item
          sm={11}
          className={
            expand1 && props.question === "Why complete a workshop?"
              ? classes.cardContainer
              : classes.hide
          }
        >
          <Typography className={expand1 ? "" : classes.hide} display="inline">
            {`Completing a faculty workshop is an invaluable opportunity for educators to enhance their teaching skills and stay current in our ever-evolving educational environment. UOPX workshops provide a platform for professional development, fostering a collaborative environment where faculty members can share best practices, learn from one another, and create community.`}
          </Typography>
        </Grid>

        <Grid
          item
          sm={11}
          className={
            expand1 && props.question === "How do I register for a workshop?"
              ? classes.cardContainer
              : classes.hide
          }
        >
          <Typography className={expand1 ? "" : classes.hide} display="inline">
            {`Visit the `}
            <Link sx={{
                  cursor: "pointer",
                }}
              onClick={props.setValueFAQ}
            >
              {`registration page`}
            </Link>
            {` to get started. Once registered, you'll receive a confirmation email
            with further instructions. Don't forget to mark the workshop dates
            on your calendar. We don’t want you to miss the workshop!`}
          </Typography>
        </Grid>

        <Grid
          item
          sm={11}
          className={
            expand1 && props.question === "How do I access a workshop?"
              ? classes.cardContainer
              : classes.hide
          }
        >
          <Typography className={expand1 ? "" : classes.hide} display="inline">
            {`Your workshop will show in `}
            <Link href="https://faculty.phoenix.edu/" target="_blank">
              {`MyPhoenix Faculty`}
            </Link>
            {` on the homepage, just like a class.`}
          </Typography>
        </Grid>

        <Grid
          item
          sm={11}
          className={
            expand1 && props.question === "How do I withdraw from a workshop?"
              ? classes.cardContainer
              : classes.hide
          }
        >
          <Typography className={expand1 ? "" : classes.hide} display="inline">
            {`Schedules change and we recognize that you may need to cancel your
            registration. To withdraw from a workshop, reach out to the `}
            <Link href="mailto:faculty.trainingteam@phoenix.edu">
              {`Faculty Training Team`}
            </Link>
            {` via email. (Please do let us know so someone else can take your
            spot.)`}
          </Typography>
        </Grid>

        <Grid
          item
          sm={11}
          className={
            expand1 && props.question === "Can I reschedule a workshop?"
              ? classes.cardContainer
              : classes.hide
          }
        >
          <Typography className={expand1 ? "" : classes.hide} display="inline">
            {`Yes, you have the flexibility to arrange or adjust your workshop
            schedule as needed. To do so, visit the `}
            <Link
              href={
                "https://ecampus.phoenix.edu/Content/MyWorkshops/home/CategoryPage/Facilitation%20Skills"
              }
              target="_blank"
            >
              {`registration page`}
            </Link>
            {` to register for another workshop time. Please remember to cancel
            your current registration by reaching out to the `}
            <Link href={"mailto:faculty.trainingteam@phoenix.edu"}>
              {`Faculty Training Team`}
            </Link>
            {` via email.`}
          </Typography>
        </Grid>

        <Grid
          item
          sm={11}
          className={
            expand1 &&
            props.question ===
              "Where can I find other Professional Development opportunities?"
              ? classes.cardContainer
              : classes.hide
          }
        >
          <Typography className={expand1 ? "" : classes.hide} display="inline">
            {`The Training & Development section in the `}
            <Link
              href="https://multimedia.phoenix.edu/faculty-resources/"
              target="_blank"
            >
              {`Faculty Resources Center`}
            </Link>
            {` offers more information about training options such as a list of
            self-paced options, facilitated workshops, and Live Webinars. Be
            sure to click on the `}
            <Link
              href="https://multimedia.phoenix.edu/faculty-resources/events/"
              target="_blank"
            >
              {`full training calendar`}
            </Link>
            {` to see all of the upcoming events.`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    scheduler: state.scheduler,
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(FAQcard);
