import { GET_CASETYPES} from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
       case GET_CASETYPES :
          return action.payload;  
       default:
          return state;  
    }
  }

