import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  // Desktop View
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    background: "#E2E6E7",
    borderRadius: "3px",
    borderBottom: "1px solid",
    padding: "30px",
    //width: "600px",
    // width: "100%",
  },
  filterContainer: {
    width: "100%",
    display: "flex",
  },
  filterSelectForm: {
    maxWidth: "173px",
  },
  floatingLabelFocusStyle: {
    color: "#000000",
  },
  // root: {
  //   "& .MuiOutlinedInput-root": {
  //     background: "#FFFFFF",
  //   },
  // },
  inputRoot: {
    root: {
      "& .MuiOutlinedInput-root": {
        background: "#FFFFFF",
      },
      // background: "#FFFFFF",
    },
  },

  // MuiInputBase-root-MuiOutlinedInput-root

  // Mobile View
  mainContainerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    background: "#E2E6E7",
    borderRadius: "3px",
    borderBottom: "1px solid",
    padding: "28px",
    width: "100%",
  },
  filterContainerMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "space-evenly",
    margin: "0px 0px 20px 0px",
  },
  filterSelectFormMobile: {
    width: "100%",
  },

  // Universal for Desktop and Mobile View
  searchBarPaperContainer: {
    //display: "flex",
    // height: "43px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    // borderRadius: "3px",
    // width: "100%",
    background: "#FFFFFF",
  },
  searchBarRoot: {
    width: "100%",
    "&&& $div": {
      padding: "0px",
      background: "#FFFFFF",
      border: "none",
    },
    "&&& $button": {
      padding: "0px",
      PointerEvents: "none",
    },
  },
  searchBarTextfieldRoot: {
    borderRadius: "3px",
    "&&& $input": {
      padding: "0px 0px 0px 5px",
      height: "43px",
      "&::placeholder": {
        color: "#A9A9AC",
        opacity: "100",
      },
    },
  },
  searchButton: {
    // borderBottom: "1px solid #000000",
    // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    // borderRadius: "0px",
    "&:focus": {
      border: "2px solid #000000",
    },
  },
  searchIcon: {
    transform: "scale(1.4)",
  },
  toggleButtonContainer: {
    marginRight: "40px",
  },
  filterButton: {
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "flex-start",
    borderBottom: "3px solid",
    borderRadius: "1px",
    width: "123px",
    height: "21px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    margin: "1px 0px 20px 0px",
    textTransform: "none",
    "&:hover": {
      background: "none",
    },
  },
  filterButtonRoot: {
    padding: "0px",
  },
  filterIcon: {
    transform: "scale(1.3)",
    margin: "0px 7px 5px 5px",
  },
  selectContainer: {
    display: "flex",
    justifyContent: "left",
    padding: "0px",
  },
  filterSelect: {
    background: "#E2E6E7",
    height: "21px",
    fontSize: "15px",
    lineHeight: "21px",
    fontWeight: "700",
    fontStyle: "normal",
    color: "#000000",
  },
  filterSelectRoot: {
    padding: "0px",
    borderBottom: "3px solid #DC3727",
    fontWeight: "bold",
  },
  filterDropDownIcon: {
    color: "#DC3727",
  },
  menuItem: {
    fontWeight: "400",
    // fontStyle: "normal",
    // fontSize: "15px",
    // fontWeight: "400",
    // color: "#000304",
    // background: "#FFFFFF",
    // padding: "6px 16px",
    // '&:hover': {
    //   background: "#F3F3F3",
    // },
  },
  formHelper: {
    color: "#1E333F",
    fontSize: "15px",
    lineHeight: "24px",
    margin: "0px",
  },
}));
