import { GET_COURSE_HISTORY} from "./actionTypes";

// eslint-disable-next-line
export default (state = {}, action) => {
    switch(action.type) {
        case GET_COURSE_HISTORY: 
            return action.payload;
        default:
            return state;
    }
}