import { connect } from "react-redux";
import { logoutUser } from "../redux/logout/actions";
import { logout } from "../utils/logout";

const mapStateToProps = (state) => ({
  tokenId:
    state.oidc && state.oidc.user && state.oidc.user.id_token
      ? state.oidc.user.id_token
      : null,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (tokenId) => {
    dispatch(logoutUser(tokenId));
  },
});

const LogoutContainer = connect(mapStateToProps, mapDispatchToProps)(logout);

export default LogoutContainer;
