import { Tooltip, Typography } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
//import styles from './ChatbotToggleTooltip.styles'
import { styled } from "@mui/material/styles";
export default function ChatbotToggleTooltip(props) {
  //const classes = styles();
  const StyledToolTipDiv = styled("div")((props) => ({
    "& p": {
      fontSize: "1.6rem",
      color: props.theme.palette.common.white,
      lineHeight: "2rem",
    },
  }));
  const StyledToolTip = styled((props) => {
    return <Tooltip classes={{ popper: props.className }} {...props} />;
  })(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: "1.5rem",
      "&:before": {
        color: theme.palette.text.primary,
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      background: theme.palette.text.primary,
      color: theme.palette.common.black,
      boxShadow:
        "0 0.2rem 0.4rem rgba(211,211,211,0.2), 0 0.2rem 0.4rem rgba(0,0,0,0.2)",
      width: "12.5rem",
      padding: theme.spacing(1.6),
      top: "0.4rem",
    },
    [`& .${tooltipClasses.popper}`]: {
      marginRight: theme.spacing(2),
    },
  }));
  /////////////////MAIN/////////////
  return (
    <div>
      <StyledToolTip
        title={
          <StyledToolTipDiv>
            <Typography color="inherit">Chat with Tech Support</Typography>
          </StyledToolTipDiv>
        }
        placement="left"
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={4000}
      >
        {props.children}
      </StyledToolTip>
    </div>
  );
}
