import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  desktopCard: {
    width: "316px",
    border: "1px solid #E2E6E7",
  },

  // Mobile View
  mobileCard: {
    width: "287px",
    border: "1px solid #E2E6E7",
  },

  // Universal for Desktop and Mobile View
  responseBox: {
    marginLeft: "14px",
  },
});
