import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import styles from './LoadingModal.styles';

function LoadingModal() {
  	const classes = styles();

	return (
		<Modal open className={classes.modal}>
		<div>
		<span className={classes.screenReaderText} role="alert" aria-busy="true" id="loading-modal-title">Loading</span>
			<Box className={classes.box}>
				<CircularProgress size={75} />
			</Box>
		</div>
		</Modal>
	);
}

export default LoadingModal;