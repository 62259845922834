import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  classListContainer: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    padding: "30px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  },

  // Mobile View
  classListContainerMobile: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    padding: "28px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  },

  // Universal for both Desktop and Mobile View
  searchContainer: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  },
  courseContainerList: {
    paddingLeft: "0px",
  },
  courseContainer: {
    listStyle: "none",
  },
  pageSetter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px 0px 0px",
  },
  nextButton: {
    color: "#DC3727",
    "&:focus": {
      border: "2px solid #000000",
      borderRadius: "4px",
    },
  },
  pageNumbersRoot: {
    "& .MuiPaginationItem-root": {
      color: "#DC3727",
      width: "25px",
      height: "25px",
      padding: "0px",
      margin: "0px",
      borderRadius: "0px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "24px",
      cursor: "pointer",
    },
  },
});
