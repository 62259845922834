import { useState, useEffect } from "react";
import styles from "./ClassContainer.styles";
import facultyPortalStyles from "../../../../styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography } from "@material-ui/core";
import { Button } from "@mui/material";
import { connect } from "react-redux";

const ClassContainer = (props) => {
  const classes = styles();
  const facultyClasses = facultyPortalStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [completeFirstRender, setCompleteFirstRender] = useState(false)

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // const createFullOfferingId = () => {
  //   if(props.courseOfferingType !== "WS") {
  //     return `OSIRIS:${props.courseOfferingId}`
  //   } else {
  //     return `FWS:${props.courseOfferingId}`
  //   }
  // };

  // const getPreviewCourseLink = () => {
  //   if(props.isCbedaCourse) {
  //     window.location.href = `${process.env.REACT_APP_D2LCLASS_URL}`
  //   } else {
  //     if(props.courseId.includes("PREVIEW")) {
  //       getBlackboardCourseLink(props.courseId, createFullOfferingId(), props.demographics.issuerId);
  //     } else {
  //       getBlackboardCourseLink(props.courseId, createFullOfferingId());
  //     };
  //   };
  // };

  // useEffect(() => {
  //   console.log("hello")
  //   if(completeFirstRender) {
  //     console.log("world")
  //     window.location.href = props.blackboardLink;
  //   } else {
  //     setCompleteFirstRender(true);
  //   }
  //   // console.log(props.blackboardLink)
  // }, [props.blackboardLink]);

  // const linkToSite = async () => {
  //   window.location.href = props.blackboardLink
  // }

  return matches ? (
    <Box className={classes.mainClassContainerMobile}>
      <Box className={classes.courseIdentityBox}>
        <Typography
          id={props.headingId}
          className={classes.courseIdentity}
          component="h3"
        >
          {`${props.courseId}: ${props.courseTitle}`}
        </Typography>
      </Box>
      <br />
      <Box>
        <ul className={classes.infoBoxList}>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Dates:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {props.dates}
            </Typography>
          </li>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Campus:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {props.learningCenter}
            </Typography>
          </li>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Role:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {toTitleCase(props.role)}
            </Typography>
          </li>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Group ID:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {props.groupId}
            </Typography>
          </li>
        </ul>
      </Box>
      <br />
      <Box>
        <Button
          id={props.viewCourseDetailsId}
          data-testid="courseDetailsButton"
          className={classes.courseDetailsButtonMobile}
          variant="outlinedMobile"
          //aria-labelledby={`viewCourseDetails ${props.headingId}`}
          // may need to update in the future to account for Workshop Courses.
          aria-label={`View course ${props.courseId}: ${props.courseTitle}. Opens in new window`}
          href={
            props.isCbedaCourse
              ? `${process.env.REACT_APP_D2LCLASS_URL}`
              : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=OSIRIS:${props.courseOfferingId}&irn=${props.demographics.issuerId}`
          }
          target="_blank"
        >
          {`View course`}
        </Button>
      </Box>
    </Box>
  ) : (
    <Box className={classes.mainClassContainer}>
      <Box className={classes.courseIdentityBox}>
        <Typography
          id={props.headingId}
          className={classes.courseIdentity}
          component="h3"
        >
          {`${props.courseId}: ${props.courseTitle}`}
        </Typography>
        <Button
          id={props.viewCourseDetailsId}
          data-testid="courseDetailsButton"
          //aria-labelledby={`viewCourseDetails ${props.headingId}`}
          aria-label={`View course ${props.courseId}: ${props.courseTitle}. Opens in new window`}
          variant="outlined"
          href={
            props.isCbedaCourse
              ? `${process.env.REACT_APP_D2LCLASS_URL}`
              : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=OSIRIS:${props.courseOfferingId}&irn=${props.demographics.issuerId}`
          }
          target="_blank"
        >
          {`View course`}
        </Button>
      </Box>
      <Box>
        <ul className={classes.infoBoxList}>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Dates:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {props.dates}
            </Typography>
          </li>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Campus:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {props.learningCenter}
            </Typography>
          </li>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Role:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {toTitleCase(props.role)}
            </Typography>
          </li>
          <li className={classes.courseInfo}>
            <Typography className={classes.infoDesc}>{`Group ID:`}</Typography>
            <Typography className={facultyClasses.body3}>
              {props.groupId}
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(ClassContainer);
