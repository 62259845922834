import Cookies from 'js-cookie';
import STATUS from './globalStatuses';

const statusTemplate = {
  getStatus: STATUS.UNFETCHED,
  modifyStatus: STATUS.UNFETCHED,
  errorData: null,
};

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  csrf: {
	// Actual token stored in cookie, to avoid multi tab browsing issues
	status: !Cookies.get('CSRF-Token') ? STATUS.UNFETCHED : STATUS.FETCHED,
  },
  supervisor: {
  },
  solicitations: [],
  courseHistory: [],
  modifiedCourseHistory: [],
  currentCourses: [],
  coursesPreferences: [],
  registerChange: {
  },
  declineReasons: [],
  demographics: {
  },
  setChangedPreferences: {
  },
  scheduler: {
  },
  errors: {
  },
  navigateOff: true,
  updateNotifications: {
  },
};