import { connect } from "react-redux";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { navigateOffPage } from "../../redux/coursesPreferences/coursesPreferencesActions";
import { Link } from "@mui/material";
import uopxLogo from "../../images/uopx-logo.png";

const useStyles = makeStyles({
  // Desktop View
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "22px",
    margin: "10px 10px",
    cursor: "pointer",
  },
  uopxImage: {
    height: "50px",
    width: "50px",
  },

  // Mobile View
  logoContainerMobile: {
    width: "auto",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "22px",
    marginLeft: "20px",
  },
  uopxImageMobile: {
    height: "50px",
    width: "50px",
  },
});

const Logo = (props) => {
  const handlePageChange = () => {
    if (props.registerChange === true) {
      let confirmAction = window.confirm(
        "\nYou have unsaved changes! \n\nAre you sure you wish to navigate off this page?"
      );
      if (confirmAction === false) {
        props.navigateOffPage(false);
      }
      if (confirmAction) {
        props.navigateOffPage(true);
      }
    }
    if (props.registerChange === false) {
      props.navigateOffPage(true);
    }
  };

  // Allow application for MUI styling
  const classes = useStyles();

  // Utilize MUI's useMediaQuery to create mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div onClick={handlePageChange}>
        <Link
          underline="none"
          data-testid="phoenixLogoHeader"
          sx={{
            color: "#001823",
            display: "flex",
            justifyContent: "space-between",
            "&hover": {
              cursor: "pointer",
            },
            "&:focus": {
              outline: "auto",
              outlineColor: "#000000",
            },
          }}
          href={props.navigateOff ? "/" : null}
        >
          <Typography
            className={
              matches ? classes.logoContainerMobile : classes.logoContainer
            }
            variant="h4"
            component="span"
          >
            <img
              className={matches ? classes.uopxImageMobile : classes.uopxImage}
              src={uopxLogo}
              alt="University of Phoenix Logo"
            />
            {`MyPhoenix Faculty`}
          </Typography>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    registerChange: state.registerChange,
    navigateOff: state.navigateOff,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateOffPage: (change) => dispatch(navigateOffPage(change)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
