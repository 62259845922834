import { GET_FACULTY_CONTACTS } from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
       case GET_FACULTY_CONTACTS:
          return action.payload;
       default:
          return state;  
    }
  }