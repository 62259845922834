import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  cardContainer: {
    maxWidth: "817px",
    background: "white",
    padding: "30px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    marginBottom: "119px"
  },

  // Mobile View
  mobileCardContainer: {
    width: "297px",
    background: "white",
    padding: "30px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },

  // Universal for Desktop and Mobile View
  title: {
    color: "#001823",
    fontSize: "24px",
    lineHeight: "28px",
    marginTop: "16px",
    marginBottom: "25px",
  },
  bottomMargin: {
    marginBottom: "35px",
  },
  listItem: {
    marginBottom: "15px",
    fontFamily: "Roboto",
    fontStyle: "normal",
  },
});
