export const ACTIONS = {
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',
  REQUEST_LOGOUT_CANCELED: 'REQUEST_LOGOUT_CANCELED',
  LOGGINGOUT: 'LOGGINGOUT',
  LOGGINGOUT_ERROR: 'LOGGINGOUT_ERROR',
};

export const STATUS = {
  UNDEFINED: 'UNDEFINED',
  LOGOUT_REQUESTED: 'LOGOUT_REQUESTED',
  LOGOUT_REQUEST_CANCELED: 'LOGOUT_REQUEST_CANCELED',
  LOGGED_OUT: 'LOGGED_OUT',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
};
