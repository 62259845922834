import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	box: {
		backgroundColor: 'white',
		borderRadius: theme.spacing(0.25),
		padding: theme.spacing(10),
		outline: 'none',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	/* Text meant only for screen readers. */
	screenReaderText: {
		border: 0,
		clip: 'rect(1px, 1px, 1px, 1px)',
		clipPath: 'inset(50%)',
		height: theme.spacing(0.1),
		margin: theme.spacing(-0.1),
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		width: theme.spacing(0.1),
		wordWrap: 'normal !important',
	},
}));