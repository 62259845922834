import { makeStyles, Typography } from '@material-ui/core';
import AvatarMUI from '@material-ui/core/Avatar';
import { connect } from "react-redux";

const userStyles = makeStyles({
    // This component is only for Desktop View
    root: {
        height: '50px',
        width: '50px',
        background: '#5E7078',
        left: '0',
        top: '0',
        fontSize: '18px',
        fontFamily: "Roboto",
        fontWeight: '700',
        lineHeight: "21.73px",
        color: '#FFFFFF',
    }
});

const Avatar = (props) => {

    // Allow for MUI Styling
    const classes = userStyles()
    
    // Fill Avatar with profile Picture or Initials
    let avatar;
    // if (avatarContentType && encodedAvatar) { // Encoded Image
    //   avatar = <img src={`data:${avatarContentType};base64,${encodedAvatar}`} alt="Profile Avatar" aria-hidden="true" />;
    // } 
    if (props.demographics.firstName && props.demographics.lastName) { // Initials
      avatar = (
        <Typography
          aria-hidden="true"
          sx={{
            fontStyle: 'normal',
            fontSize: '19px',
            lineHeight: '22px',
            color: '#FFFFFF',
          }}
        >
          {`${props.demographics.firstName.substring(0, 1)}${props.demographics.lastName.substring(0, 1)}`}
        </Typography>
      );
    }
    // } else { // Icon
    //   avatar = <Icon icon={icon} color={theme.palette.common.white} aria-hidden="true" />;
    // }

    return (
        <AvatarMUI className={classes.root}>{avatar}</AvatarMUI>
    )
}

const mapStateToProps = state => {
    return {
        demographics: state.demographics
    }
};

export default connect(mapStateToProps)(Avatar);