import { NAVIGATE_OFF } from "./actionTypes";

// eslint-disable-next-line
export default (state = true, action) => {
  switch (action.type) {
    case NAVIGATE_OFF:
      return action.payload;
    default:
      return state;
  }
};
