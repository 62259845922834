import { makeStyles, useMediaQuery, Box, Typography } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";

const userStyles = makeStyles({
  // Desktop View
  infoContainer: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "22px 0px 18px 7px",
    textAlignment: "left",
    fontStyle: "normal",
  },
  usersName: {
    width: "100%",
    color: "#001823",
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "18px",
  },
  irnContainer: {
    width: "100%",
    height: "18px",
    display: "flex",
    alignItems: "center",
    color: "#3A4F59",
    fontSize: "12px",
    lineHeight: "14px",
  },

  // Mobile View
  infoContainerMobile: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
  },
  usersNameMobile: {
    color: "#000304",
    fontSize: "12px",
    lineHeight: "14px",
  },
  irnContainerMobile: {
    color: "#3A4F59",
    fontSize: "12px",
    lineHeight: "14px",
    marginLeft: "2%",
  },

  // Universal used for both Desktop and Mobile View
  copyImage: {
    transform: "scale(.5)",
  },
});

const ProfileInfo = (props) => {
  // Allow for application for MUI styling
  const classes = userStyles();

  // Use useMediaQuery to create mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const copyIrn = () => {
    navigator.clipboard.writeText(props.demographics.issuerId);
    // alert("Copied the text: " + props.demographics.issuerId);
  };

  return matches ? (
    <Box className={classes.infoContainerMobile}>
      <Typography className={classes.usersNameMobile}>
        {Object.keys(props.demographics).length !== 0
          ? `${props.demographics.firstName} ${props.demographics.lastName}`
          : null}
      </Typography>
      <Typography className={classes.irnContainerMobile}>
        {Object.keys(props.demographics).length !== 0
          ? `${props.demographics.issuerId}`
          : null}
      </Typography>
      {Object.keys(props.demographics).length !== 0 && (
        <IconButton onClick={copyIrn} aria-label="Copy I R N">
          <CopyIcon alt="Copy I R N Button" className={classes.copyImage} />
        </IconButton>
      )}
    </Box>
  ) : (
    <Box className={classes.infoContainer}>
      <Typography className={classes.usersName}>
        {Object.keys(props.demographics).length !== 0
          ? `${props.demographics.firstName} ${props.demographics.lastName}`
          : null}
      </Typography>
      <Typography className={classes.irnContainer} id="irn">
        {Object.keys(props.demographics).length !== 0
          ? `${props.demographics.issuerId}`
          : null}
        {Object.keys(props.demographics).length !== 0 && (
          <IconButton onClick={copyIrn} aria-label="Copy I R N">
            <CopyIcon
              titleAccess="Copy IRN Button"
              alt="Copy I R N Button"
              className={classes.copyImage}
            />
          </IconButton>
        )}
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(ProfileInfo);
