import React, { useState } from "react";
import {
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandIcon from "@mui/icons-material/Add";
import CollapseIcon from "@mui/icons-material/Remove";
import LogoutIcon from "@mui/icons-material/Logout";
import useStyles from "./MobileLinkBlock.styles";

const MobileLinkBlock = ({ title, itemList }) => {
  const classes = useStyles();

  const [expand, setExpand] = useState(false);

  return (
    <div>
      <Accordion
        className={classes.accordionContainer}
        expanded={expand}
        onChange={() => {
          setExpand(!expand);
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryHeader,
          }}
          data-testid="dropDownList"
          expandIcon={
            expand ? (
              <CollapseIcon
                sx={{
                  color: "#DC3727",
                }}
              />
            ) : (
              <ExpandIcon
                sx={{
                  color: "#DC3727",
                }}
              />
            )
          }
        >
          <Typography className={classes.title} component="h3">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.accordionDetailsBox,
          }}
        >
          <ul className={classes.linkList}>
            {itemList.map((linkItem) => (
              linkItem.authorization &&
              linkItem.display &&
              <li>
                <Link 
                  key={linkItem.item} 
                  href={linkItem.link}
                  color="inherit"
                  className={classes.linkItem}
                  target={
                    linkItem.link === "/help" || 
                    linkItem.link === "/expensesubmissions" || 
                    linkItem.link === "/logout" || 
                    linkItem.item === "Change Password" ||
                    linkItem.link === "/facultyworkshops" ||
                    linkItem.link === "/helpdesksubmission"
                    ? "_self"
                    : "_blank"
                  }
                >
                  {linkItem.link === "/logout" && 
                    <LogoutIcon data-testid="logoutIcon" />
                  }
                  <Typography className={classes.linkText} component="h4">
                    {linkItem.item}
                  </Typography>
                </Link>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MobileLinkBlock;
