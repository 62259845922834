import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // This component only for Mobile View
  accordionContainer: {
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
  },
  accordionSummaryHeader: {
    padding: "0px 5px",
    "&:focus": {
      border: "2px solid #000000",
      background: "#FFFFFF",
    },
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "17px",
    lineHeight: "20px",
    color: "#001823",
  },
  accordionDetailsBox: {
    padding: "0px 5px",
  },
  linkList: {
    listStyle: "none",
    padding: "0px",
    margin: "0px",
  },
  linkItem: {
    display: "flex",
  },
  linkText: {
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "17px",
    lineHeight: "20px",
    paddingBottom: "15px",
    marginTop: "2px",
    color: "#001823",
  },
}));

export default useStyles;
