import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  mainContainer: {
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    // width: "534px",
    height: "434px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
  },
  topContainer: {
    margin: "28px 64px 0px 28px",
  },
  CourseSupportImage: {
    width: "250px",
    paddingTop: "60px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  // Mobile View
  mainMobileContainer: {
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    width: "327px",
    height: "298px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    marginBottom: "24px",
  },
  topMobileContainer: {
    margin: "24px 21px 0px 21px",
  },
  mobileCourseSupportImage: {
    width: "140px",
    paddingTop: "5px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  // Universal for both desktop and Mobile View
  topTextContainer: {
    marginBottom: "15px",
  },
}));

export default useStyles;
