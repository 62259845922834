import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Universal for Desktop and Mobile View
  radioColor: {
    color: "#3A4F59",
  },
  acccept: {
    width: "42px",
    "&.Mui-checked": { color: "black" },
  },
  decline: {
    "&.Mui-checked": { color: "black" },
  },
});
