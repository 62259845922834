import { InputLabel, FormControl } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { getFacultyDeclineReasons } from "../../../../redux/declineReasons/declineReasonsActions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";

// styling for dropdown
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 230,
      width: 256,
    },
  },
};

function getStyles(reason, reasonForDecline, theme) {
  return {
    fontWeight:
      reasonForDecline.indexOf(reason) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyle = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  show: {
    display: "block",
  },
}));

const ResponseFormAutocomplete = (props) => {
  const classes = useStyle();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { onAutoClick, reasonForDecline, responseValue } = props;
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    props.getFacultyDeclineReasons();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.declineReasons.status !== undefined &&
      props.declineReasons.status !== 200
    ) {
      setOpenSuccess(true);
    }
  }, [props.declineReasons.status]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={15000}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      props.getFacultyDeclineReasons();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const declineReasonsList =
    props.declineReasons.length >= 1 ? (
      [
        ...props.declineReasons.slice(0, 4),
        ...props.declineReasons.slice(5),
        props.declineReasons[4],
      ].map(
        (reason) => (
          <MenuItem
            key={reason.id}
            value={reason.shortDescription}
            style={getStyles(reason, reasonForDecline, theme)}
          >
            {reason.shortDescription}
          </MenuItem>
        )
      )
    ) : (
      <MenuItem>{`There are currently no reasons for decline.`}</MenuItem>
    );

  return (
    <div
      data-testid="response-form-select-div"
      className={responseValue !== "Declined" ? classes.hide : classes.show}
    >
      <FormControl
        role="combobox"
        sx={{
          width: `${matches ? "256px" : "220px"}`,
          marginTop: "20px",
          }}
      >
        <InputLabel
          role="combobox"
          htmlFor="Reason for decline"
          aria-label="Reason for decline"
        >
          Reason for decline
        </InputLabel>
        <Select
          label="Reason for decline"
          role="combobox"
          data-testid="response-form-select"
          value={reasonForDecline}
          onChange={onAutoClick}
          MenuProps={MenuProps}
        >
          {declineReasonsList}
        </Select>
      </FormControl>
      {openSuccess
        ? renderSnackbar(
            "error",
            "Failed to retrieve solicitation decline reasons!"
          )
        : ""}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    declineReasons: state.declineReasons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFacultyDeclineReasons: () => dispatch(getFacultyDeclineReasons()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponseFormAutocomplete);
