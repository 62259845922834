import { GET_DEMOGRAPHICS, GET_FACULTY_CONTACTS } from "./actionTypes";
import { getFacultyDemographics } from "../../services/persons/v1/persons";
import { getFacultyContacts } from "../../services/contacts/v1/contacts";

export const getDemographics = (personId) => async (dispatch) => {
  await getFacultyDemographics(personId)
    .then((response) => {
      dispatch({ type: GET_DEMOGRAPHICS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_DEMOGRAPHICS, payload: error.request });
    });
};

export const getFacultyContactsInfo = (personId) => async (dispatch) => {
  await getFacultyContacts(personId)
    .then((response) => {
      dispatch({ type: GET_FACULTY_CONTACTS, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_FACULTY_CONTACTS, payload: error.request });
    });
};
