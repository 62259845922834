import { GET_NOTIFICATIONS } from "./actionTypes";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
    switch(action.type) {
       case GET_NOTIFICATIONS:
          return action.payload;
       default:
          return state;  
    }
  }