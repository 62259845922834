import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  welcomeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5%",
  },
  sun: {
    transform: "scale(.4)",
  },
  sunDiv: {
    display: "flex",
    height: "100px",
    width: "100px",
    alignItems: "center",
    justifyContent: "center",
  },

  // Mobile View
  welcomeContainerMobile: {
    display: "flex",
    alignItems: "center",
    marginTop: "40px",
    width: "327px",
  },
  // textContainer: {
  //   display: "flex",
  //   textAlign: "center",
  // },
  sunMobile: {
    transform: "scale(.25)",
  },
  sunDivMobile: {
    display: "flex",
    height: "45px",
    width: "45px",
    alignItems: "center",
    justifyContent: "center",
  },
});
