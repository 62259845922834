import { useMediaQuery, Box } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";

// necessary for maintaining vertical page spacing in mobile and tablet view
const DummyHeader = () => {
    const siteStripe = document.getElementById("site-stripe-container");

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const tablet = useMediaQuery(theme.breakpoints.between("sm","md"));
    const w = window.innerWidth

    const height = (mobile, tablet, siteStripe) => {
        // tablet view with site stripe
        if (tablet && siteStripe) {
            return "190px"
        }
        // tablet view without site stripe
        if (tablet) {
            return "110px"
        }
        // mobile view with site stripe
        if (mobile && siteStripe) {
            return "170px"
        }
        // mobile view without site stripe
        if (mobile) {
            return "90px"
        }
        // desktop
        return "0px"
    }

    return (
      <Box
        sx={{
          height: `${height(mobile, tablet, siteStripe)}`,
          background: "#F2F3F3",
        }}
      ></Box>
    );

}

export default DummyHeader;
