import { Typography, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import useStyles from "./RegisterButton.styles";
import Modal from "@mui/material/Modal";
import ConfirmationMessage from "./ConfirmationMessage.jsx";
import { useMemo, useState, useEffect } from "react";
import {
  updateMember,
  registerParticipant,
} from "../../../services/faculty/v1/faculty";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { romanTest } from "../../../utils/library.js";
import {
  getWorkshopList,
  getSectionsByIrn,
} from "../../../redux/workshops/workshopActions";

const RegisterButton = (props) => {
  const {
    pageType,
    withdrawn,
    setWithdrawn,
    registered,
    setRegistered,
    waitlisted,
    setWaitlisted,
    joinWaitlist,
    leftButtonLabel,
    rightButtonLabel,
    startDate,
    endDate,
    courseCode,
    courseTitle,
    rosterId,
    capacity,
    noOfParticipants,
    courseOfferingId,
    schedulingRole,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  // useEffect(() => {
  //   console.log("registered: " + registered);
  // }, [
  //   leftButtonLabel,
  //   rightButtonLabel,
  //   registered,
  //   waitlisted,
  //   withdrawn,
  // ]);

  const setOneYearAhead = () => {
    let endDate = new Date();
    const newYear = endDate.getYear() + 1;
    endDate.setFullYear(1900 + newYear);
    return endDate;
  };

  const workshopObjectToRetrieveOneYear = {
    fq: "upcomingWorkshops",
    startDate: new Date(
      new Date().setTime(new Date().getTime() + 5 * 86400000)
    ).toLocaleDateString("en-US", { timeZone: "America/Phoenix" }),
    //startDate: new Date().toISOString(),
    endDate: setOneYearAhead().toLocaleDateString("en-US", {
      timeZone: "America/Phoenix",
    }),
    courseCode: "",
    courseTitle: "",
    irn: "",
    username: "",
  };

  const courseRoster = useMemo(() => {
    const courseRoleId = {
      STUDENT: 2,
      FACULTY: 1,
      OBSERVER: 23,
    };
    return {
      courseOfferingId: courseOfferingId,
      courseRoleId: courseRoleId[schedulingRole],
      rosterStatusId: 4,
      createdBy: "",
      dateCreated: new Date(),
      modifiedBy: props.demographics.firstName + " " + props.demographics.lastName,
      dateModified: new Date(),
      irn: props.demographics.issuerId,
      personId: props.demographics.personId,
      
    };
  }, [courseOfferingId, props.demographics, schedulingRole]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleWithdraw = async () => {
    await updateMember(courseRoster, rosterId)
      .then((res) => {
        if (registered) {
          setSnackbarMessage("Withdrew from workshop.");
          props.getWorkshopList(workshopObjectToRetrieveOneYear);
          props.getSectionsByIrn(props.demographics.issuerId);
        }
        if (waitlisted) {
          setSnackbarMessage("Removed from waitlist.");
          props.getWorkshopList(workshopObjectToRetrieveOneYear);
          props.getSectionsByIrn(props.demographics.issuerId);
        }
        handleClose();
        setRegistered(false);
        setWaitlisted(false);
        setWithdrawn(true);
        setSnackbarType("success");
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarType("error");
        if (registered) {
          setSnackbarMessage("Failed to withdraw from workshop.");
        }
        if (waitlisted) {
          setSnackbarMessage("Failed to remove from waitlist.");
        }
        setOpenSuccess(true);
      });
  };

  const handleRegisterParticipant = async () => {
    await registerParticipant(
      startDate,
      endDate,
      courseCode,
      props.demographics.issuerId,
      props.demographics.firstName +" " + props.demographics.lastName,
    )
      .then((response) => {
        if (!withdrawn) {
          setSnackbarMessage("Registered for workshop.");
          setRegistered(true);
          props.getWorkshopList(workshopObjectToRetrieveOneYear);
          props.getSectionsByIrn(props.demographics.issuerId);
        }
        if (response.data.rosterStatusId === 7) {
          setSnackbarMessage("Registered for workshop.");
          setRegistered(true);
          props.getWorkshopList(workshopObjectToRetrieveOneYear);
          props.getSectionsByIrn(props.demographics.issuerId);
        }
        if (response.data.rosterStatusId === 12) {
          setSnackbarMessage("Added to waitlist.");
          setWaitlisted(true);
          props.getWorkshopList(workshopObjectToRetrieveOneYear);
          props.getSectionsByIrn(props.demographics.issuerId);
        }
        handleClose();
        setWithdrawn(false);
        setSnackbarType("success");
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage("Failed to be added to workshop.");
        setSnackbarType("error");
        setOpenSuccess(true);
      });
  };

  // Below code is to handle snackbars for successful or errored submissions
  // -------------------------------------------------------------------------------------------
  const renderSnackbar = () => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={snackbarType === "success" ? 10000 : 13000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );

  const handleCloseSnackBar = (event, reason) => {
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    switch (snackbarMessage) {
      case "Failed to withdraw from workshop":
        handleWithdraw();
        break;
      case "Failed to be added to workshop":
        handleRegisterParticipant();
        break;
      default:
        break;
    }
  };

  //Below code is to get deadline date for registering a workshop --- needs to be revisited to fix timezone issues
  // const isPastDeadlineDate = (courseStartDate) => {
  //   const todaysDate = new Date().toISOString().slice(0,10);

  //   const startDateObj = new Date(courseStartDate);
  //   startDateObj.setUTCHours(0, 0, 0, 0);
  //   startDateObj.setUTCDate(startDateObj.getUTCDate() - 5);
  //   console.log(startDateObj, todaysDate, courseStartDate);

  //   return todaysDate > startDateObj;
  // };
  const isPastDeadlineDate = (courseStartDate) => {
    // needs to be revisited to fix timezone issues
    const todaysDate = new Date();
    const startDateObj = new Date(courseStartDate);
    let deadlineDateForStartDate = new Date(
      startDateObj.setTime(startDateObj.getTime() - 5 * 86400000)
    );
    return deadlineDateForStartDate < todaysDate;
  };

  return (
    <>
      <Grid container xs={12} className={classes.buttonContainer}>
        {isPastDeadlineDate(startDate) && !(registered || waitlisted) ? (
          ""
        ) : (
          <Button
            className={
              joinWaitlist ? classes.outlinedButton : classes.filledButton
            }
            disabled={isPastDeadlineDate(startDate) || registered || waitlisted}
            aria-disabled={
              isPastDeadlineDate(startDate) || registered || waitlisted
            }
            aria-label={
              joinWaitlist
                ? `Join the waitlist ${courseCode}. Opens in new window`
                : registered
                ? `You're registered!`
                : `Register now. Opens in new window`
            }
            variant={joinWaitlist ? "outlined" : "contained"}
            onClick={handleOpen}
          >
            <Typography
              variant={
                joinWaitlist
                  ? ""
                  : registered || waitlisted
                  ? "muiDisabledButtonText"
                  : "muiButtonText"
              }
            >
              {leftButtonLabel}
            </Typography>
          </Button>
        )}

        {!props.faculty && // facilitator or observer should not see withdraw or withdrawn
        (registered || waitlisted) &&
        !isPastDeadlineDate(startDate) ? ( //display withdraw if registered or waitlisted only until deadline
        //(props.pageType === "myWorkshops" && withdrawn) || //display withdraw"n"  in myWorkshops page always
        //(props.pageType === "upcomingWorkshops" && withdrawn && isPastDeadlineDate(startDate)) //display withdrawn in upcomingWorkshops page after deadline
          <Button
            className={classes.outlinedButton}
            onClick={handleOpen}
            disabled={withdrawn}
            variant={withdrawn ? "contained" : "outlined"}
          >
            <Typography variant={withdrawn && "muiDisabledButtonText"}>
              {rightButtonLabel}
            </Typography>
          </Button>
        ) : (
          ""
        )}

        {joinWaitlist && (
          <Grid item xs={9}>
            <Typography variant={"body2"} className={classes.waitlistMessage}>
              This workshop is currently full. Join the waitlist and you will be
              notified if a spot opens up.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        role="dialog"
        aria-modal="true"
      >
        <Grid>
          {!withdrawn && rightButtonLabel === "Withdraw from workshop" ? (
            <ConfirmationMessage
              course={`${courseCode}: ${romanTest(courseTitle)}`}
              confirmationMessage={`Are you sure you want to withdraw from this workshop?`}
              acceptButtonLabel={`Confirm workshop withdraw`}
              acceptButtonAction={handleWithdraw}
              handleClose={handleClose}
            />
          ) : !withdrawn && rightButtonLabel === "Withdraw from waitlist" ? (
            <ConfirmationMessage
              course={`${courseCode}: ${romanTest(courseTitle)}`}
              confirmationMessage={`Are you sure you want to be removed from waitlist?`}
              acceptButtonLabel={`Confirm withdraw from waitlist`}
              acceptButtonAction={handleWithdraw}
              handleClose={handleClose}
            />
          ) : leftButtonLabel === "Register now" &&
            noOfParticipants < capacity ? (
            <ConfirmationMessage
              course={`${courseCode}: ${romanTest(courseTitle)}`}
              confirmationMessage={`Are you sure you want to register for this workshop?`}
              acceptButtonLabel={`Confirm workshop registration`}
              acceptButtonAction={handleRegisterParticipant}
              handleClose={handleClose}
            />
          ) : (leftButtonLabel === "Register now" ||
              leftButtonLabel === "Join the waitlist") &&
            noOfParticipants >= capacity ? (
            <ConfirmationMessage
              course={`${courseCode}: ${romanTest(courseTitle)}`}
              confirmationMessage={`Are you sure you want to be added to the waitlist for this workshop?`}
              acceptButtonLabel={`Confirm workshop waitlisting`}
              acceptButtonAction={handleRegisterParticipant}
              handleClose={handleClose}
            />
          ) : (
            <ConfirmationMessage
              course={`${courseCode}: ${romanTest(courseTitle)}`}
              confirmationMessage={`junk`}
              acceptButtonLabel={`Confirm workshop waitlisting`}
              acceptButtonAction={handleRegisterParticipant}
              handleClose={handleClose}
            />
          )}
        </Grid>
      </Modal>
      {openSuccess && renderSnackbar()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    // errorMessages: state.errorMessages,
    // workshops: state.workshops,
    // sectionsForIrn: state.sectionsForIrn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkshopList: (workshopObject) =>
      dispatch(getWorkshopList(workshopObject)),
    getSectionsByIrn: (irn) => dispatch(getSectionsByIrn(irn)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterButton);
