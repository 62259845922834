import { Link } from "@mui/material";
import { Typography } from "@material-ui/core";
import arrow from "../images/arrow.svg";
import useStyles from "./CardLink.styles";

const CardLink = (props) => {
  const { content, link, ariaId, ariaRead, externalLink, whiteText, nohref } =
    props;
  const classes = useStyles();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link
      className={classes.linkContainer}
      aria-labelledby={ariaId}
      data-testid="cardLink"
      href={nohref ? false : `${link}`}
      onClick={nohref ? handleScrollToTop : ""}
      underline="none"
      target={externalLink ? "_blank" : "_self"}
    >
      {/* <div id={ariaId} className={classes.hideDiv}>
        {ariaRead}
      </div> */}

      <Typography
        className={whiteText ? classes.linkTextWhite : classes.linkText}
        variant="body1"
      >
        {content}
      </Typography>

      <img
        className={whiteText ? classes.whiteArrowImage : classes.arrowImage}
        src={arrow}
        alt=""
      />
    </Link>

    // <Link
    //   className={classes.linkContainer}
    //   aria-labelledby={ariaId}
    //   data-testid="cardLink"
    //   underline="none"
    //   href="#top"
    // >
    //   <div id={ariaId} className={classes.hideDiv}>
    //     {ariaRead}
    //   </div>

    //   <Typography
    //     className={whiteText ? classes.linkTextWhite : classes.linkText}
    //     variant="body1"
    //   >
    //     {content}
    //   </Typography>

    //   <img
    //     className={whiteText ? classes.whiteArrowImage : classes.arrowImage}
    //     src={arrow}
    //     alt=""
    //   />
    // </Link>
  );
};

export default CardLink;
