import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // This component only for Desktop View
  container: {
    display: "flex",
    flexDirection: "column",
  },
  helpTitleContainer: {
    marginBottom: "22px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#000304",
    marginBottom: "10px",
  },
  blockList: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
  },
  listItem: {
    marginBottom: "15px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000304",
  },
  helpListItem: {
    marginBottom: "22px",
  },
  helpLink: {
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "20px",
    color: "#000000",
    borderBottom: "solid 1px",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default useStyles;
