import { STORED_ERRORS } from "./actionTypes";

// eslint-disable-next-line 
export default (state = [], action) => {
    switch(action.type) {
        case STORED_ERRORS:
            return removeDuplicateObjectFromArray([...state, action.payload])
        default:
            return state;
    }
}

const removeDuplicateObjectFromArray = (array) => {
    
    let check = new Set();
    return array.filter(obj => !check.has(obj.type.typeOfError) && check.add(obj.type.typeOfError));
  }