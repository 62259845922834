import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import styles from "./ResponseFormRadioGroup.styles";

const ResponseFormRadioGroup = (props) => {
  const { handleRadioChange, responseValue } = props;
  const classes = styles();
  return (
    <FormControl>
      <RadioGroup
        onChange={handleRadioChange}
        value={responseValue}
        row
        name="radio-buttons-group"
        className={classes.radioColor}
      >
        <FormControlLabel
          value="Accepted"
          label="Accept"
          htmlFor="accept-radio"
          control={
            <Radio
              id="accept-radio"
              color="default"
              className={classes.acccept}
            />
          }
        />
        <FormControlLabel
          value="Declined"
          label="Decline"
          htmlFor="decline-radio"
          control={
            <Radio
              id="decline-radio"
              color="default"
              className={classes.decline}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default ResponseFormRadioGroup;
