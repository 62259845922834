import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // Desktop View
  container: {
    margin: "15px 0px 0px 0px",
  },
  linkList: {
    listStyle: "none",
    display: "flex",
  },
  listItemCookie: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#001823",
    paddingLeft: "10px",
    paddingTop: ".5px",
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#001823",
    paddingLeft: "10px",
  },

  // Mobile View
  mobileContainer: {
    overflow: "hidden",
    margin: "0px",
    padding: "47px 0px 0px 0px",
  },
  mobileLinkList: {
    listStyle: "none",
  },
  mobileListItemContainer: {
    marginBottom: "16px",
    padding: "0",
  },
  mobileListItem: {
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#001823",
    position: "relative",
    left: "-30px",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
    whiteSpace: "pre-wrap",
  },
  mobileCopyRight: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#5e7078",
    position: "relative",
    left: "-30px",
    marginTop: "32px",
  },
}));

export default useStyles;
