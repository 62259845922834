import { ACCEPT_SOLICITATIONS, DECLINE_SOLICITATIONS } from "./actionTypes";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = [], action) => {
  switch (action.type) {
    case ACCEPT_SOLICITATIONS:
      return action.payload;
    case DECLINE_SOLICITATIONS:
      return action.payload;
    default:
      return state;
  }
};
