import styles from "./NotificationsCard.styles";
import facultyPortalStyle from "../../../styles";

import { useState } from "react";
import { Typography, Box, useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import CardLink from "../../../components/CardLink";

const NotificationsCard = (props) => {
  // Allow for MUI styling
  const classes = styles();
  const facultyClasses = facultyPortalStyle();

  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletMatches = useMediaQuery(theme.breakpoints.down("md"));

  const [expand, setExpand] = useState(false);

  return matches ? (
    <>
      <Box
        className={facultyClasses.notificationContainerMobile}
        onClick={() => {
          setExpand(!expand);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setExpand(!expand);
          }
        }}
        data-testId="NotificationContainer"
        tabIndex="0"
        role="button"
        aria-expanded={expand}
        aria-label={props.notificationAriaLabel}
      >
        <Box className={classes.infoContainerMobile}>
          {expand ? (
            <>
              <Box>
                <Box>
                  <Typography variant="body1" sx={{ color: "#000304", }} component="p">
                    {props.title}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.linkContainer}>
                <CardLink
                  externalLink={true}
                  content="View Message"
                  link={props.link}
                  ariaId={props.ariaLinkId}
                  ariaRead="View Notification Message"
                />
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography variant="body1" sx={{ color: "#000304", }} component="p">
                  {props.title.length >= 30
                    ? `${props.title.substring(0, 30)}...`
                    : props.title}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box className={classes.iconContainerMobile}>
          {expand ? (
            <ExpandMoreIcon
              classes={{ root: classes.expandIconRootExpanded }}
            />
          ) : (
            <ExpandMoreIcon 
              classes={{ root: classes.expandIconRoot }} 
            />
          )}
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Box
        className={tabletMatches ? facultyClasses.tabletNotificationContainer : facultyClasses.notificationContainer}
        onClick={() => {
          setExpand(!expand);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setExpand(!expand);
          }
        }}
        data-testId="NotificationContainer"
        tabIndex="0"
        role="button"
        aria-expanded={expand}
        aria-label={props.notificationAriaLabel}
      >
        <Box className={classes.infoContainer}>
          {expand ? (
            <>
              <Box>
                <Box>
                  <Typography variant="body1" sx={{ color: "#000304", }} component="p">
                    {props.title}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.linkContainer}>
                <Box className={classes.linkBox}>
                  <CardLink
                    externalLink={true}
                    content="View Message"
                    link={props.link}
                    ariaId={props.ariaLinkId}
                    ariaRead="View Notification Message"
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography variant="body1" sx={{ color: "#000304", }} component="p">
                  {props.title.length >= 90
                    ? `${props.title.substring(0, 90)}...`
                    : props.title}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box className={classes.iconContainer}>
          {expand ? (
            <ExpandMoreIcon
              classes={{ root: classes.expandIconRootExpanded }}
            />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.expandIconRoot }} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default NotificationsCard;
