import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  desktopCard: {
    marginBottom: "20px",
    fontFamily: "Roboto",
    maxWidth: "609px",
  },
  desktopCardContent: {
    margin: "12px 12px 20px 12px",
  },
  desktopTitle: {
    fontWeight: "700 !important",
    fontSize: "24px !important",
    color: "#000304 !important",
  },
  descriptionBoxContainer: {
    marginTop: "30px",
    marginBottom: "15px",
  },
  facultyWorkshopText: {
    margin: "16px 0px 8px 0px",
    color: "#DC3727",
  },
  workshopMaterialsLink: {
    marginTop: "30px",
    color: "#DC3727",
  },
  workshopDescription: {
    marginTop: "16px",
    marginBottom: "-16px",
    textAlign: "justify",
  },

  // Mobile View
  mobileCard: {
    maxWidth: "327px",
    marginBottom: "21px",
  },
  mobileCardContent: {
    margin: "0 5px",
  },
  mobileTitle: {
    fontWeight: "700 !important",
    fontSize: "21px !important",
    color: "#000304 !important",
  },
  mobileButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "16px",
    flexDirection: "column"
  },
  descriptionBoxContainerMobile: {
    marginTop: "15px",
    marginBottom: "35px",
  },
  workshopDescriptionMobile: {
    marginTop: "8px",
    marginBottom: "-48px",
    textAlign: "justify",
  },
  workshopMaterialsMobileLink: {
    marginTop: "15px",
    marginBottom: "15px",
    color: "#DC3727",
  },

  // Universal for both Desktop and Mobile View
  show: {
    display: "block",
    marginTop: "16px",
  },
  hide: {
    display: "none",
  },
});
