import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "./ClassButton.styles";

const ClassButton = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
    <div className= {classes.buttonContainer}>
    <Button
      sx={{
        borderRadius: "30px",
        //padding: "16px 48px",
        padding: `${matches ? "8px 32px" : "2px 16px"}`,
        marginRight:"16px",
        width: `${matches ? "253px" : "200px"}`,
        height: "51px",
        backgroundColor: "#DC3727",
        color: "white",
        textTransform: "none",
        "&:focus": {
          outline: "auto",
          outlineColor: "#000000",
        },
        "&:hover": {
          backgroundColor: `#DC3727`,
          outline: "auto",
          outlineColor: "#000000",
        },
      }}
      aria-label={`Go to class ${props.courseName}. Opens in new window`}
      variant="contained"
      startIcon={<LaunchIcon />}
      href={
        props.isCbedaCourse
          ? `${process.env.REACT_APP_D2LCLASS_URL}`
          // : `${process.env.REACT_APP_GOTOCLASS_COURSE_MATERIALS}${props.courseOfferingId}`
          : `${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseOfferingId=${props.courseOfferingId}&irn=${props.demographics.issuerId}`
        }
      target="_blank"
    >
      {`Go to class`} 
    </Button>
  
     {props.feocsInviteLink && props.feocsInviteLink.length > 0 && props.feocsInviteLink.map((course) => (
        props.courseOfferingId === ("OSIRIS:" + course.courOffNum) && 
    <Button 
        sx={{
        width: `${matches ? "160px" : "100px"}`,
        height: "40px",
        display: "flex",
        whiteSpace: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 32px",
        background: "#FFFFFF",
        border: "2px solid #DC3727",
        boxSizing: "border-box",
        borderRadius: "40px",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: `${matches ? "16px" : "12px"}`,
        lineHeight: "24px",
        textTransform: "none",
        "&:focus": {
          outline: "auto",
          outlineColor: "#000000",
        },
      }}
      aria-label={`Survey link ${props.courseName}. Opens in new window`}
       href= {course.invitationLink}
          
      target="_blank"
    >
      {`Course Survey`}
    </Button>
    ))}
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    feocsInviteLink: state.feocsInviteLink,
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(ClassButton);
