import { Typography } from "@mui/material";

const CardTitle = (props) => {
  const { title } = props;
  
  return (
    <Typography
      data-testid="cardTitle"
      component="h2"
      sx={{
        color: "#3A4F59",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "-0.01em",
        textTransform: "uppercase",
      }}
    >
      {title}
    </Typography>
  );
};

export default CardTitle;
