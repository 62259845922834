import React from "react";
import useStyles from "./Frame2.styles";
import { Link } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Typography } from "@material-ui/core";
import Logo from "../Logo";

const linkItem = [
  // {
  //   title: "Contact Customer Support",
  //   link: ``,
  // },
  {
    title: "Privacy Policy",
    link: `${process.env.REACT_APP_PRIVACY_POLICY_URL}`,
  },
  // {
  //   title: "Manage Cookie Preferences",
  // },
  {
    title: "Terms of use",
    link: `${process.env.REACT_APP_TERMS_OF_USE_URL}`,
  },
  {
    title: "Policy and Compliance",
    link: `${process.env.REACT_APP_POLICY_AND_COMPLIANCE_URL}`,
  },
  {
    title: "Help Center",
    link: `${process.env.REACT_APP_HELP_URL}`,
  },
  {
    title: "TeamViewer",
    link: `${process.env.REACT_APP_GO_TO_ASSIST_URL}`,
  },
  {
    title: "Share Your Screen",
    link: `${process.env.REACT_APP_SHARE_YOUR_SCREEN_URL}`,
  },
];

const Frame2 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));
  return matches ? (
    <>
      <div className={classes.container}>
        <ul className={classes.linkList}>
          {linkItem.map((item) => (
            <li key={item.title}>
              {item.title === "Manage Cookie Preferences" ? (
                <div className={classes.listItemCookie}>
                  <a
                    style={{ color: "#001823" }}
                    href="javascript:void(0)"
                    className="optanon-show-settings"
                    id="onetrust-pc-btn-handler"
                  >
                    Manage&nbsp;Cookie&nbsp;Preferences
                  </a>
                </div>
              ) : (
                <Link
                  className={classes.listItem}
                  href={item.link}
                  target="_blank"
                  underline="always"
                >
                  {item.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  ) : tabletMatches ? (
    <>
      <div className={classes.container}>
        <ul className={classes.linkList}>
          {linkItem.map((item) => (
            <li key={item.title}>
              {item.title === "Manage Cookie Preferences" ? (
                <div className={classes.listItemCookie}>
                  <a
                    style={{ color: "#001823" }}
                    href="javascript:void(0)"
                    className="optanon-show-settings"
                    id="onetrust-pc-btn-handler"
                  >
                    Manage&nbsp;Cookie&nbsp;Preferences
                  </a>
                </div>
              ) : (
                <Link
                  className={classes.listItem}
                  href={item.link}
                  target="_blank"
                  underline="always"
                >
                  {item.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  ) : (
    // Mobile View Below
    <>
      <div className={classes.mobileContainer}>
        <Logo />
        <div>
          <ul className={classes.mobileLinkList}>
            {linkItem.map((item) => (
              <li className={classes.mobileListItemContainer} key={item.title}>
                <Link
                  className={classes.mobileListItem}
                  href={item.link}
                  target="_blank"
                  underline="always"
                >
                  {item.title}
                </Link>
              </li>
            ))}
            <li>
              <Link
                className={classes.mobileListItem}
                href={`${process.env.REACT_APP_CA_RESIDENTS_URL}`}
                target="_blank"
                underline="always"
              >
                {"CA residents:\nDo not sell my personal information"}
              </Link>
            </li>
            <li>
              <Typography className={classes.mobileCopyRight} component="p">
                {`Copyright © 2020 University of Phoenix`}
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Frame2;
