import {
  GET_COURSES_PREFERENCES,
  SAVE_COURSES_PREFERENCES_STATE,
} from "./actionTypes";

// eslint-disable-next-line
export default (state = {}, action) => {
  switch (action.type) {
    case GET_COURSES_PREFERENCES:
      return action.payload;
    case SAVE_COURSES_PREFERENCES_STATE:
      return action.payload;
    default:
      return state;
  }
};
