import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  mainContainer: {
    display: "flex",
    width: "100%",
    background: "#5e7079",
  },
  containerContent: {
    display: "flex",
    width: "100%",
    padding: "16px 80px",
  },
  iconContainer: {
    display: "flex",
    height: "100%",
    marginRight: "5px",
  },
  infoIcon: {
    color: "#FFFFFF",
  },
  textContainer: {
    display: "flex",
    color: "#FFFFFF",
    alignItems: "flex-end",
    margin: "0 auto"
  },
  infoText: {
    display: "-webkit-Box",
    lineClamp: "2",
    boxOrient: "vertical",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: "10px",
  },
  readMoreButton: {
    color: "#FFFFFF",
    textDecoration: "underline",
    fontWeight: "bold",
    textTransform: "none",
    "&:hover": { background: "#5e7079", textDecoration: "underline" },
    whiteSpace: "nowrap",
    marginLeft: "15px",
    padding: "0px",
  },
  // Mobile View
  containerContentMobile: {
    display: "flex",
    width: "100%",
    padding: "16px 30px",
  },
  linkText: {
    "&:hover": {
      marginLeft: "5px",
      marginRight: "10px",
      transition: "all .2s",
    },
  },
  arrowImage: {
    marginLeft: "8px",
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(110%) contrast(101%)",
    "&:hover": {
      marginLeft: "18px",
      transition: "all .2s",
    },
  },
});
