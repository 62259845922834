import { DECLINED_COURSES_SOLICITATIONS } from "./actionTypes";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  switch (action.type) {
    case DECLINED_COURSES_SOLICITATIONS:
      return action.payload;
    default:
      return state;
  }
};
