import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import useStyles from "./PreferredCourses.styles";
import CoursePriorityCard from "./CoursePriorityCard";
import ResponseNotification from "../../../solicitations/components/newSolicitationCard/ResponseNotification";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";
import { connect } from "react-redux";
import {
  getCoursesPreferences,
  saveCoursesPreferencesState,
  registerChangeMade,
  navigateOffPage,
} from "../../../../redux/coursesPreferences/coursesPreferencesActions";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../../facultyPortalTheme";
import { storeErrorMessages } from "../../../../redux/displayApiErrors/displayApiErrorsActions";
import CardLink from "../../../../components/CardLink";

const CoursesByPriority = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [performEnable, setPerformEnable] = useState(false);
  const [savedChanges, setSavedChanges] = useState("");

  const [successMsg, setSuccessMsg] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorOccured, setErrorOccured] = useState(false);

  let count = 0;

  useEffect(() => {
    if (props.demographics.issuerId) {
      props.getCoursesPreferences(props.demographics.issuerId);
      // props.getCoursesPreferences("FAKEIRN"); // fake IRN for testing purposes
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.registerChange === true) {
      setPerformEnable(true);
      setSavedChanges("");
    }
    if (props.registerChange === false) {
      setPerformEnable(false);
    }
  }, [props.registerChange]);

  useEffect(() => {
    if (props.setChangedPreferences.length > 0) {
      if (props.coursesPreferences.length > 0 && props.setChangedPreferences) {
        for (let i = 0; i < props.setChangedPreferences.length; i++) {
          if (
            props.coursesPreferences[i].preferred !==
            props.setChangedPreferences[i].preferred
          ) {
            setSavedChanges("");
          }
        }
      }
    }
  }, [props.setChangedPreferences]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.coursesPreferences.status !== undefined &&
      props.coursesPreferences.status !== 200
    ) {
      props.storeErrorMessages(
        "Course preferences not found.",
        "course preferences"
      );
      setErrorOccured(true);
      setErrorMessage("Course preferences not found.");
    } else {
      setOpenSuccess(false);
    }
  }, [props.coursesPreferences.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "course preferences"
    ) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSavedClick = async (event) => {
    // sends the put request
    await props
      .saveCoursesPreferencesState(
        props.demographics.issuerId,
        //"fakeirn -- fake irn to test errors",
        props.coursesPreferences
      )
      .then((response) => {
        if (response === 200) {
          props.registerChangeMade(false);
          setOpenSuccess(true);
          setSavedChanges("Saved!");
          setErrorOccured(false);
          setSuccessMsg("Success! Your preferences have been updated.");
        } else {
          setOpenSuccess(true);
          setErrorOccured(true);
          setErrorMessage("Oops! Your preferences weren't updated.");
        }
      })
      .catch((error) => {});
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      props.getCoursesPreferences(props.demographics.issuerId);
    }
  };

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={snackbarType === "success" ? 3000 : null} // was 15000
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <div
        className={
          matches ? classes.mobileMainContainer : classes.mainContainer
        }
      >
        <div
          className={
            matches ? classes.mobilePaddingContainer : classes.paddingContainer
          }
        >
          <div
            className={
              matches ? classes.mobileTitleContainer : classes.titleContainer
            }
          >
            <Typography variant="subtitle1" color="secondary" component="h2">
              {`Preferred Courses`}
            </Typography>
          </div>
          <div
            className={
              matches
                ? classes.mobileSubtextContainer
                : classes.subtextContainer
            }
          >
            <Typography variant="body1" component="p">
              {`You’ve been approved to teach the courses below. Select the checkbox
            next to the courses you prefer to teach. These preferences will be
            considered when soliciting for new courses.`}
            </Typography>
          </div>
          {props.coursesPreferences.length >= 1 &&
            props.coursesPreferences.map((course) => (
              <div key={count++}>
                <CoursePriorityCard
                  headingId={`coursePriorityCard${count}`}
                  previewMaterialsButtonId={`previewCourseMaterials${count}`}
                  courseNumber={course.courseId}
                  courseName={course.courseDescription}
                  link={`${process.env.REACT_APP_NEW_VIEW_COURSE_MATERIALS}&courseId=${course.courseId}_preview&irn=${props.demographics.issuerId}`} // Conditional for D2L link or Blackboard link
                  preferred={course.preferred}
                />
              </div>
            ))}

          <div
            className={
              matches ? classes.mobileButtonContainer : classes.buttonContainer
            }
          >
            {savedChanges === "" ? (
              <Button
                className={
                  matches ? classes.mobileSaveButton : classes.saveButton
                }
                id="saveButton"
                disabled={performEnable ? false : true}
                onClick={handleSavedClick}
                variant={
                  performEnable ? "muiButtonContained" : "muiButtonDisabled"
                }
                tabIndex={performEnable ? 0 : -1}
              >
                <Typography
                  variant={
                    performEnable ? "muiButtonText" : "muiDisabledButtonText"
                  }
                >
                  {`Save changes`}
                </Typography>
              </Button>
            ) : (
              <Box
                className={
                  matches
                    ? classes.mobileSavedButtonBox
                    : classes.savedButtonBox
                }
              >
                <ResponseNotification value="Accepted" content="Saved!" />
              </Box>
            )}
          </div>

          <hr />
          <br />

          <div className={classes.credentialsLink}>
            <CardLink
              link={process.env.REACT_APP_UPDATE_YOUR_CREDENTIALS_URL}
              content="Update your credentials"
              externalLink={true}
            />
          </div>
          {errorOccured
            ? renderSnackbar("error", errorMessage)
            : renderSnackbar("success", successMsg)}
        </div>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    coursesPreferences: state.coursesPreferences,
    setChangedPreferences: state.setChangedPreferences,
    registerChange: state.registerChange,
    demographics: state.demographics,
    navigateOff: state.navigateOff,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoursesPreferences: (irn) => dispatch(getCoursesPreferences(irn)),
    saveCoursesPreferencesState: (irn, preferences) =>
      dispatch(saveCoursesPreferencesState(irn, preferences)),
    registerChangeMade: (change) => dispatch(registerChangeMade(change)),
    navigateOffPage: (change) => dispatch(navigateOffPage(change)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesByPriority);
