import { Card, CardContent, Popover, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResponseForm from "./ResponseForm";
import { Box } from "@mui/system";

import CardTitle from "../../../../components/CardTitle";

import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import styles from "./ResponsePopover.styles";

const ResponsePopover = (props) => {
  const {
    id,
    open,
    anchorEl,
    setAnchorEl,
    handleResponseDisplay,
    facultyScheduleId,
    setExpanded,
  } = props;
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(false);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      elevation={matches ? 0 : 1}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card className={matches ? classes.desktopCard : classes.mobileCard}>
        <CardContent>
          <IconButton
            aria-label="close solicitation detail"
            sx={{
              float: "right",
              padding: 0,
              width: "16px",
              color: "black",
              borderRadius: "50%",
              "&:focus": {
                outline: "auto",
                outlineColor: "#000000",
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <br />
          <Box className={classes.responseBox}>
            <CardTitle title={"Response:"} />
            <ResponseForm
              setAnchorEl={setAnchorEl}
              handleResponseDisplay={handleResponseDisplay}
              facultyScheduleId={facultyScheduleId}
            />
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};

export default ResponsePopover;
