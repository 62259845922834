import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  title: {
    textTransform: "none",
    fontSize: "32px",
    lineHeight: "40px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkboxText: {
    margin: "2px 15px 0 0",
  },
  greyText: {
    color: " #5E7079",
  },
  checkboxContainerMobile: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  hide: {
    display: "none",
  },
  icon: {
    marginLeft: "189px",
  },
  redText: {
    color: "#DB3725",
  },
  pointer: {
    "&hover": {
      cursor: "pointer",
    },
  },
  cardContainer: {
    paddingBottom: "30px",
  },
}));

export default useStyles;
