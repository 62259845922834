import {
  GET_COURSES_PREFERENCES,
  SET_COURSES_PREFERENCES_STATE,
  SAVE_COURSES_PREFERENCES_STATE,
  ERROR_MESSAGE,
  REGISTER_CHANGE,
  NAVIGATE_OFF,
} from "./actionTypes";
import {
  getFacultyCoursesPreferences,
  updateFacultyCoursesPreferences,
} from "../../services/faculty/v1/faculty";

export const getCoursesPreferences = (irn) => async (dispatch) => {
  await getFacultyCoursesPreferences(irn)
    .then((response) => {
      dispatch({ type: GET_COURSES_PREFERENCES, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: GET_COURSES_PREFERENCES, payload: error.request });
    });
};

export const setCoursesPreferencesState = (preferences) => async (dispatch) => {
  dispatch({ type: SET_COURSES_PREFERENCES_STATE, payload: preferences });
};

export const registerChangeMade = (change) => async (dispatch) => {
  dispatch({ type: REGISTER_CHANGE, payload: change });
};

export const navigateOffPage = (change) => async (dispatch) => {
  dispatch({ type: NAVIGATE_OFF, payload: change });
};

let retVal = 0;
export const saveCoursesPreferencesState =
  (irn, preferences) => async (dispatch) => {
    await updateFacultyCoursesPreferences(irn, preferences)
      .then((response) => {
        dispatch({
          type: SAVE_COURSES_PREFERENCES_STATE,
          payload: preferences,
        });
        retVal = response.status;
      })
      .catch((error) => {
        retVal = error.status;
        dispatch({
          type: ERROR_MESSAGE,
          payload: error,
        });
      });
    return retVal;
  };
