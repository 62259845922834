import { Typography, Grid, Button, Card, CardContent } from "@mui/material";
import useStyles from "./ConfirmationMessage.styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { connect } from "react-redux";

const ConfirmationMessage = (props) => {
  const {
    course,
    confirmationMessage,
    acceptButtonLabel,
    acceptButtonAction,
    handleClose,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Card className={matches ? classes.desktopCard : classes.mobileCard}>
        <CardContent
          className={
            matches ? classes.desktopCardContent : classes.mobileCardContent
          }
        >
          <Typography
            className={matches ? classes.desktopTitle : classes.mobileTitle}
            component="h3"
            sx={{ marginBottom: "10px" }}
          >
            {course}
          </Typography>

          <Typography sx={{ marginBottom: "20px" }}>
            {confirmationMessage}
          </Typography>
          <Grid
            container
            sx={
              matches
                ? {
                    display: "flex",
                    flexWrap: "nowrap",
                    columnGap: "15px",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                  }
            }
          >
            <Button
              sx={{
                marginBottom: "10px",
                width: "120px",
                height: "40px",
                display: "flex",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 32px",
                background: "#FFFFFF",
                border: "2px solid #DC3727",
                boxSizing: "border-box",
                borderRadius: "40px",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                "&:focus": {
                  outline: "auto",
                  outlineColor: "#000000",
                },
              }}
              onClick={handleClose}
            >
              {`Cancel`}
            </Button>
            <Button
              sx={{
                width: "273px",
                height: "40px",
                display: "flex",
                whiteSpace: "nowrap",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 32px",
                background: "#DC3727",
                color: "white",
                boxSizing: "border-box",
                borderRadius: "40px",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                "&:focus": {
                  outline: "auto",
                  outlineColor: "#000000",
                },
                "&:hover": {
                  backgroundColor: `#DC3727`,
                  outline: "auto",
                  outlineColor: "#000000",
                },
              }}
              onClick={acceptButtonAction}
            >
              {acceptButtonLabel}
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
  };
};

export default connect(mapStateToProps)(ConfirmationMessage);
