import styles from "./TipsCard.styles";
import { Typography } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@material-ui/core";

const AccessAndInstructionsCard = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return matches ? (
    <>
      <div className={classes.cardContainer}>
        <Typography className={classes.title} component="h2">
          {`Helpful Faculty Expense Tips`}
        </Typography>
        <div className={classes.bottomMargin}>
          <ul>
            <li
              className={classes.listItem}
            >{`Submit them soon, there is a 90-day window to submit for your activities.  If the end date of the activity is submitted after this timeframe, you could risk your expenses being rejected.`}</li>
            <li
              className={classes.listItem}
            >{`Submit them more often.  It is ok to submit them on a weekly basis.  You do not need to wait until your course/student teacher visits are complete.`}</li>
            <li
              className={classes.listItem}
            >{`You can include multiple course weeks/student teacher visits on one expense submission unless you prefer to track your expenses by each individual student.  However, if you are meeting with more than one student teacher each week, all that activity can be on one expense.  If submitting multiple courses, they must be under the same Campus and Learning Center.`}</li>
            <li
              className={classes.listItem}
            >{`If you have any questions or run into any issues with expenses, feel free to reach out to FacultyPayroll@phoenix.edu.`}</li>
          </ul>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={classes.mobileCardContainer}>
        <Typography className={classes.title} component="h2">
          {`Helpful Faculty Expense Tips`}
        </Typography>
        <div className={classes.bottomMargin}>
          <ul>
            <li
              className={classes.listItem}
            >{`Submit them soon, there is a 90-day window to submit for your activities.  If the end date of the activity is submitted after this timeframe, you could risk your expenses being rejected.`}</li>
            <li
              className={classes.listItem}
            >{`Submit them more often.  It is ok to submit them on a weekly basis.  You do not need to wait until your course/student teacher visits are complete.`}</li>
            <li
              className={classes.listItem}
            >{`You can include multiple course weeks/student teacher visits on one expense submission unless you prefer to track your expenses by each individual student.  However, if you are meeting with more than one student teacher each week, all that activity can be on one expense.  If submitting multiple courses, they must be under the same Campus and Learning Center.`}</li>
            <li
              className={classes.listItem}
            >{`If you have any questions or run into any issues with expenses, feel free to reach out to FacultyPayroll@phoenix.edu.`}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccessAndInstructionsCard;
