import React from "react";
import useStyles from "./HelpCard.styles";
import { Typography, Card } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardLink from "../../../components/CardLink";

const HelpCard = ({
  title,
  subtext,
  linkText,
  imgAltText,
  link,
  icon,
  biggerIcon,
  supervisorCard,
  ariaId,
  ariaRead,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // for smaller screens (e.g. mobile view)
  if (matches) {
    return (
      <>
        <Card className={classes.mobileCard}>
          <div className={classes.mobileTextAndLinkContainer}>
            <div className={classes.titleContainer}>
              <Typography data-testid="title" variant="h2" component="h2">
                {title}
              </Typography>
            </div>
            <div className={classes.subtextContainer}>
              <Typography data-testid="subtext" variant="body1" component="p">
                {subtext}
              </Typography>
            </div>
            <CardLink
              externalLink={true}
              altText={imgAltText}
              content={linkText}
              link={link}
              ariaId={ariaId}
              ariaRead={ariaRead}
            />
          </div>
        </Card>
      </>
    );
  }

  // for larger screens
  return (
    <>
      <Card className={classes.card}>
        <img
          data-testid="icon"
          src={icon}
          alt=""
          role="presentation"
          className={biggerIcon ? classes.bigIcon : classes.icon}
        />
        <div className={classes.textAndLinkContainer}>
          <div className={classes.titleContainer}>
            <Typography
              data-testid="title"
              // className={facultyClasses.secondaryPageHeading}
              variant="h2"
              component="h2"
            >
              {title}
            </Typography>
          </div>
          <div className={classes.subtextContainer}>
            <Typography
              data-testid="subtext"
              // className={facultyClasses.body1}
              variant="body1"
              component="p"
            >
              {subtext}
            </Typography>
          </div>
          <CardLink
            externalLink={true}
            supervisorEmail={supervisorCard}
            altText={imgAltText}
            content={linkText}
            link={link}
          />
        </div>
      </Card>
    </>
  );
};

export default HelpCard;
