import Button from "@mui/material/Button";
import styles from "./ResponseButton.styles";

const ResponseButton = (props) => {
  const { content, onClick, expanded} = props;
  const classes = styles();

  return (
    <Button variant="text" onClick={onClick} className={classes.button} aria-expanded={expanded}>
      {content}
    </Button>
  );
};

export default ResponseButton;
