import styles from "../styles";
import facultyPortalStyles from "../../../styles";
import { useState, useEffect } from "react";

import Notifications from "./Notifications";
import Welcome from "./Welcome";
import CourseCardDisplay from "./currentCourses/CourseCardDisplay";
import MyCoursesCard from "./MyCoursesCard";
import QuickLinks from "./quickLinksContainer/QuickLinks";
import SupervisorCard from "../../../components/SupervisorCard";
import AdminSupportCard from "../../../components/AdminSupportCard";
import { Grid } from "@material-ui/core";

import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Typography } from "@material-ui/core";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { connect } from "react-redux";
import WorkshopsCard from "./WorkshopsCard";

const Dashboard = (props) => {
  const classes = styles();
  const facultyClasses = facultyPortalStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [openSuccess, setOpenSuccess] = useState(false);
  const [update, setUpdate] = useState(0);
  const [wsUser, setWsUser] = useState(false);

  // Below code deals with Snackbar
  // -------------------------------------------------------------------------------------------------------------
  function renderSnackbar(snackbarType, message) {
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton
        />
      </Snackbar>
    </>;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (props.demographics.username) {
      process.env.REACT_APP_WS_USERS_USERNAME_LIST.includes(
        props.demographics.username
      )
        ? setWsUser(true)
        : setWsUser(false);
    }
  }, [props.demographics.username, update]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------------------------------------------------------------------

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <div
        className={
          matches
            ? facultyClasses.pageContainer
            : facultyClasses.pageContainerMobile
        }
      >
        <Grid
          container
          direction="column"
          className={matches ? classes.mainContent : classes.mainContentMobile}
          role="main"
          md={12}
          sm={11}
          xs={8}
        >
          <Grid
            item
            xs={11}
            sm={12}
            className={matches ? classes.desktopWelcome : classes.mobileWelcome}
          >
            <Welcome />
          </Grid>
          <Grid item sm={11} md={12}>
            <Notifications />
          </Grid>
          <Grid item className={matches ? classes.title : classes.titleMobile}>
            <Typography variant={matches ? "h2" : "h5"} component="h2">
              {`Current Courses/Workshops`}
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={7} md={8}>
              <CourseCardDisplay wsUser={wsUser} />
            </Grid>
            <Grid item sm={3} md={4}>
              <MyCoursesCard />
              {(new Date() >= new Date(process.env.REACT_APP_TOGGLE_DATE) ||
                wsUser) && (
                <WorkshopsCard setUpdate={setUpdate} update={update} />
              )}
              <SupervisorCard />
              <AdminSupportCard />
            </Grid>
          </Grid>
          <Grid item>
            <QuickLinks />
          </Grid>
        </Grid>
      </div>
      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    errorMessages: state.errorMessages,
  };
};

export default connect(mapStateToProps)(Dashboard);
