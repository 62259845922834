import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // Upcoming Workshops Card on Dashboard Page
  title: {
    textTransform: "none",
    fontSize: "32px",
    lineHeight: "40px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  greyText: {
    color: " #5E7079",
  },
  checkboxContainerMobile: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  mobileContainer: {
    alignItems: "center",
    margin: "60px auto 0 auto",
    paddingBottom: "60px",
  },
  mobileTitle: {
    fontWeight: 700,
    fontSize: "24px",
    marginBottom: "40px",
    alignSelf: "flex-start",
  },

  // Upcoming workshops view on Faculty Workshops page
  // Desktop View
  mainFilterContainer: {
    maxWidth: "609px",
    display: "flex",
    flexDirection: "column",
  },
  actionFiltersContainer: {
    width: "100%",
    display: "flex",
    marginBottom: "50px",
  },
  filterLabels: {
    color: "#000000",
    fontWeight: "700",
    whiteSpace: "nowrap",
    fontSize: "12px"
  },
  monthSelectFilterListBox: {
    position: "absolute",
    minWidth: "200px",
    zIndex: "1000",
    overflowY: "auto",
    backgroundColor: "#ffffff",
    padding: "8px 0px",
    margin: "0px",
    listStyle: "none",
    borderRadius: "4px",
    boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2),
                0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
                0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  },
  courseTitleInputContainer: {
    position: "relative",
    boxSizing: "border-box",
    width: "393px",
    minWidth: "256px",
    maxWidth: "393px",
    height: "56px",
    border: "1px solid #5E7079",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    padding: "0 15px",
    fontSize: "16px",
    color: "#000000",
  },
  courseTitleFilterListBox: {
    position: "absolute",
    width: "393px",
    minWidth: "256px",
    maxWidth: "393px",
    zIndex: "1000",
    overflowY: "auto",
    backgroundColor: "#ffffff",
    padding: "8px 0px",
    margin: "0px",
    listStyle: "none",
    borderRadius: "4px",
    boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2),
                0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
                0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  },

  // Mobile View
  mainFilterContainerMobile: {
    maxWidth: "327px",
    display: "flex",
    flexDirection: "column",
  },
  monthSelectFilterListBoxMobile: {
    position: "absolute",
    minWidth: "282px",
    zIndex: "1000",
    overflowY: "auto",
    backgroundColor: "#ffffff",
    padding: "8px 0px",
    margin: "0px",
    listStyle: "none",
    borderRadius: "4px",
    boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2),
                0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
                0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  },
  courseTitleInputContainerMobile: {
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
    minWidth: "282px",
    maxWidth: "327px",
    height: "56px",
    border: "1px solid #5E7079",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    padding: "0 15px",
    fontSize: "16px",
    color: "#000000",
  },
  courseTitleFilterListBoxMobile: {
    position: "absolute",
    minWidth: "282px",
    zIndex: "1000",
    overflowY: "auto",
    backgroundColor: "#ffffff",
    padding: "8px 0px",
    margin: "0px",
    listStyle: "none",
    borderRadius: "4px",
    boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2),
                0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
                0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  },
}));

export default useStyles;
