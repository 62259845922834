import {
  RECEIVE_TOKEN,
  REQUEST_TOKEN,
  REQUEST_TOKEN_ERROR,
  INVALIDATE_TOKEN,
} from './types';
import STATUS from '../globalStatuses';

function csrf(
  state = { csrf: {} },
  action,
) {
  switch (action.type) {
    case REQUEST_TOKEN:
      return { ...state, status: STATUS.FETCHING };
    case RECEIVE_TOKEN:
      // Todo: Format the data better
      return { ...state, status: STATUS.FETCHED };
    case REQUEST_TOKEN_ERROR:
      return {
        ...state,
        error: action.error,
        status: STATUS.ERROR,
      };
    case INVALIDATE_TOKEN:
      return { ...state, status: STATUS.INVALIDATED };
    default:
      return state;
  }
}

export default csrf;
