// export default (config) => {
//   const accessToken =
//     "";

//   if (accessToken !== null && accessToken !== undefined) {
//     const updatedConfig = config;
//     updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
//     return updatedConfig;
//   }
//   return config;
// };

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (config, getState) => {
  const { oidc: { user } = { user: {} } } = getState();
   const accessToken = user === null ? null : user.access_token;

  if (accessToken !== null && accessToken !== undefined) {
    const updatedConfig = config;
    updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
    return updatedConfig;
  }
  return config;
};
