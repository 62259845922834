import styles from "./ClassList.styles";
import { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { Pagination } from "@mui/material";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ClassContainer from "./ClassContainer";
import SearchContainer from "./SearchContainer";
import HeaderSortBox from "./HeaderSortBox";
import { connect } from "react-redux";
import {
  getCourseHistory,
  setModifiedCourseList,
} from "../../../../redux/courseHistory/courseHistoryActions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";
import { storeErrorMessages } from "../../../../redux/displayApiErrors/displayApiErrorsActions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#DC3727",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    button: {
      color: "#DC3727",
    },
  },
});

const ClassList = (props) => {
  const classes = styles();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const coursesPerPage = 5;
  let count = 0;

  // State to keep up with courses that are to be rendered and pages
  const [coursesToRender, setCoursesToRender] = useState([]);
  const [displayCourses, setDisplayCourses] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pages, setPages] = useState([]);
  const [initializedModifiedList, setInitializedModifiedList] = useState(false);
  const [initialCourseHistoryLength, setInitialCourseHistoryLength] =
    useState();
  const [openSuccess, setOpenSuccess] = useState(false);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const getMonthString = (month) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month - 1];
  };

  const updateDateFormat = (date) => {
    const [month, day, year] = date.split("/");
    return `${getMonthString(month)} ${day}, ${year}`;
  };

  // Use getCourseHistory action to call courseHistory api
  // and populate props.courseHistory
  useEffect(() => {
    if (props.demographics.issuerId) {
      props.getCourseHistory(props.demographics.issuerId);
      // props.getCourseHistory("FakeIRN"); // FAKE IRN For testing purposes
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.courseHistory.length < initialCourseHistoryLength) {
      // props.getCourseHistory(9018012886)
      props.getCourseHistory(props.demographics.issuerId);
    }
  }, [props.courseHistory.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.courseHistory.length > 0 && initializedModifiedList === false) {
      props.setModifiedCourseList(props.courseHistory);
      setInitializedModifiedList(true);
      setInitialCourseHistoryLength(props.courseHistory.length);
    }
  }, [props.courseHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update coursesToRender whenever course history list changes
  useEffect(() => {
    setCoursesToRender(props.modifiedCourseHistory);
  }, [props.modifiedCourseHistory]);

  useEffect(() => {
    if (
      props.courseHistory.status !== undefined &&
      props.courseHistory.status !== 200
    ) {
      props.storeErrorMessages("Course history not found.", "course history");
    } else {
      setOpenSuccess(false);
    }
  }, [props.courseHistory.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "course history"
    ) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages]);

  // Reset pageNum whenever coursesToRender changes.
  // Call getPages function to get pages to display for pagination
  useEffect(() => {
    setPages(getPages(coursesToRender));
    setPageNum(1);
  }, [coursesToRender]);

  // useEffect function renders new set of courses whenever pageNum and pages is updated.
  useEffect(() => {
    if (
      coursesToRender.length > 0 &&
      (coursesToRender.length % coursesPerPage === 0 ||
        pageNum !== pages[pages.length - 1])
    ) {
      setDisplayCourses(
        coursesToRender.slice(
          pageNum * coursesPerPage - coursesPerPage,
          pageNum * coursesPerPage
        )
      );
    }
    if (
      coursesToRender.length >= 1 &&
      pageNum === pages[pages.length - 1] &&
      coursesToRender.length % coursesPerPage !== 0
    ) {
      setDisplayCourses(
        coursesToRender.slice(pageNum * coursesPerPage - coursesPerPage)
      );
    }
  }, [pageNum, pages]); // eslint-disable-line react-hooks/exhaustive-deps

  // Function will return array of page numbers based on how many
  // pages will be needed for courseList and on how many courses per page to display
  const getPages = (courseList) => {
    let count = Math.ceil(courseList.length / coursesPerPage);
    let temp = [];
    for (let i = 1; i <= count; i++) {
      temp = [...temp, i];
    }
    return temp;
  };

  // Function to update pageNum whenever a page button is clicked.
  const handlePageClick = (event) => {
    if(isNaN(parseInt(event.target.innerText))) {
      return null
    } else {
        if (!event.target.innerText) {
          if (pageNum < Math.ceil(coursesToRender.length / coursesPerPage)) {
            setPageNum(pageNum + 1);
          }
        } else {
          setPageNum(parseInt(event.target.innerText));
        }
    }
  };

  // Function to update pageNum whenever next button is clicked.
  const handleNext = () => {
    if (pageNum < Math.ceil(coursesToRender.length / coursesPerPage)) {
      setPageNum(pageNum + 1);
    }
  };

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      props.getCourseHistory(props.demographics.issuerId);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  return (
    <>
      <Box className={classes.searchContainer}>
        <SearchContainer />
      </Box>
      <Box
        className={
          matches
            ? classes.classListContainer
            : classes.classListContainerMobile
        }
      >
        <HeaderSortBox />
        {coursesToRender.length > 0 ? (
          <ul className={classes.courseContainerList}>
            {displayCourses.map((item) => (
              <li
                className={classes.courseContainer}
                key={item.facultyScheduleId}
              >
                <hr />
                <ClassContainer
                  courseOfferingId={item.courseOfferingId}
                  // courseOfferingType={item.offeringType}
                  isCbedaCourse={item.cbeda}
                  courseId={item.courseId != null && item.courseId}
                  headingId={`courseIdentity${++count}`}
                  viewCourseDetailsId={`viewCourseDetails${count}`}
                  courseTitle={item.courseTitle}
                  dates={
                    item.startDate &&
                    item.endDate != null &&
                    `${updateDateFormat(
                      item.startDate.split(" ")[0]
                    )} - ${updateDateFormat(item.endDate.split(" ")[0])}`
                  }
                  learningCenter={toTitleCase(item.orgName)}
                  role={item.schedulingRole}
                  groupId={item.groupId}
                  ariaLabel={`${item.courseId}: ${item.courseTitle}`}
                />
              </li>
            ))}
          </ul>
        ) : (
          <Typography sx={{ fontFamily: "Roboto" }}>
            {"No Courses to Display"}
          </Typography>
        )}
        {pages.length > 1 ? (
          <>
            <Box>
              <hr />
            </Box>
            <ThemeProvider theme={theme}>
              <Box className={classes.pageSetter} role="navigation">
                <Pagination
                  id="pageNumber"
                  count={Math.ceil(coursesToRender.length / coursesPerPage)}
                  page={pageNum}
                  size="small"
                  shape="rounded"
                  color="primary"
                  boundaryCount={0}
                  siblingCount={1}
                  hidePrevButton={true}
                  hideNextButton={true}
                  onChange={handlePageClick}
                  classes={{
                    root: classes.pageNumbersRoot,
                  }}
                />
                <Button
                  id="nextButton"
                  data-testid="nextButton"
                  className={classes.nextButton}
                  variant="text"
                  onClick={handleNext}
                >
                  {`Next`}
                  <NavigateNextIcon />
                </Button>
              </Box>
            </ThemeProvider>
          </>
        ) : null}
        {openSuccess &&
          (props.errorMessages.length === 1
            ? renderSnackbar("error", "Course history not found.")
            : "")}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    courseHistory: state.courseHistory,
    modifiedCourseHistory: state.modifiedCourseHistory,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCourseHistory: (irn) => dispatch(getCourseHistory(irn)),
    setModifiedCourseList: (courses) =>
      dispatch(setModifiedCourseList(courses)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassList);
