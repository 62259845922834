import { useState } from "react";
import { Box } from "@mui/system";
import {
  acceptFacultySolicitations,
  declineFacultySolicitations,
} from "../../../../services/faculty/v1/faculty";
import ResponseFormRadioGroup from "./ResponseFormRadioGroup";
import ResponseFormAutocomplete from "./ResponseFormAutocomplete";
import ResponseFormSubmitButton from "./ResponseFormSubmitButton";
import { connect } from "react-redux";

const ResponseForm = (props) => {
  const { setAnchorEl, handleResponseDisplay, facultyScheduleId } = props;

  const [responseValue, setResponseValue] = useState(null);
  const [submitDisplay, setSubmitDisplay] = useState("none");
  const [bg, setBg] = useState("");
  const [submitVal, setSubmitVal] = useState(false);
  const [reasonForDecline, setReasonForDecline] = useState("");

  // when user toggles between accept and decline
  const handleRadioChange = (event) => {
    setResponseValue(event.target.value);
    submitDisplay !== "block" && setSubmitDisplay("block");
    reasonForDecline !== "" && setReasonForDecline("");
    if (event.target.value === "Accepted") {
      setBg("#DC3727");
      setSubmitVal(true);
    } else {
      setBg("#E3E6E7");
      setSubmitVal(false);
    }
  };

  // when user clicks submit button
  const onSubmitClick = (event) => {
    if (responseValue === "Accepted") {
      event.preventDefault();
      acceptFacultySolicitations(facultyScheduleId, responseValue)
        .then((response) => {
          const success = {
            status: response.status,
            statusDescription: response.data.statusDescription,
            message: "Success! Solicitation accepted.",
          };
          handleResponseDisplay(success);
        })
        .catch((error) => {
          const catchError = {
            status: error.status,
            message: "Oops! Your solicitation response wasn't saved.",
          };
          handleResponseDisplay(catchError);
        });
    }
    if (responseValue === "Declined" && reasonForDecline !== "") {
      event.preventDefault();
      declineFacultySolicitations(
        facultyScheduleId,
        reasonForDecline,
        "Declined_By_Faculty"
      )
        .then((response) => {
          const success = {
            status: response.status,
            statusDescription: response.data.statusDescription,
            message: "Got it! Solicitation declined.",
          };
          handleResponseDisplay(success);
        })
        .catch((error) => {
          const catchError = {
            status: error.status,
            message: "Oops! Your solicitation response wasn't saved.",
          };
          handleResponseDisplay(catchError);
        });
    }
    setAnchorEl(null);
  };

  // when user clicks on a reason for decline
  const onAutoClick = (event, value, reason) => {
    setReasonForDecline(value.props.value);
    if (reason !== "clear") {
      setSubmitVal(true);
      setBg("#DC3727");
    } else {
      setSubmitVal(false);
      setBg("#E3E6E7");
    }
  };

  return (
    <Box data-testid="response-form" component="form" onSubmit={onSubmitClick}>
      <ResponseFormRadioGroup
        handleRadioChange={handleRadioChange}
        responseValue={responseValue}
      />
      <ResponseFormAutocomplete
        onAutoClick={onAutoClick}
        reasonForDecline={reasonForDecline}
        responseValue={responseValue}
      />
      <ResponseFormSubmitButton
        submitDisplay={submitDisplay}
        submitVal={submitVal}
        bg={bg}
        responseValue={responseValue}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    solicitations: state.solicitations,
    declineReasons: state.declineReasons,
  };
};

export default connect(mapStateToProps)(ResponseForm);
