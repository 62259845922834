import {
  StyledFeedback,
  ButtonText,
  ButtonContainer,
} from "./FeedbackButton.styles"

function FeedbackButton({ open = () => {} }) {
  return (
    <ButtonContainer>
      <StyledFeedback
        id="modal-title"
        aria-label="Feedback"
        data-testid="feedback-button"
        variant="contained"
        type="button"
        onClick={() => open()}
      >
        <ButtonText>FEEDBACK</ButtonText>
      </StyledFeedback>
    </ButtonContainer>
  );
}

export default FeedbackButton;
