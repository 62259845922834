import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Used for both Desktop and Mobile View
  button: {
    paddingTop: "0 !important",
    color: "#000000 !important",
    fontWeight: "normal !important",
    textTransform: "none !important",
    textDecoration: "underline !important",
    textDecorationColor: "#000000 !important",
    textDecorationThickness: "3px !important",
    textUnderlineOffset: "1.5px !important",
    height: "21px !important",
    "&:focus": {
      outline: "auto",
      outlineColor: "#000000",
    },
  },
});
