import styles from "./SearchContainer.styles";

import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import * as filterFunctions from "../../../../utils/courseHistoryFilterFunctions";

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import filter from "../../../../images/filter.svg";

import { connect } from "react-redux";
import { setModifiedCourseList } from "../../../../redux/courseHistory/courseHistoryActions";

const SearchContainer = (props) => {
  const classes = styles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // State used in providing functionality for search bar and filter
  const [activateFilter, setActivateFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("allDates");
  const [selectExpand, setSelectExpand] = useState(false);

  useEffect(() => {
    switch (filterValue) {
      case "allDates":
        props.setModifiedCourseList(props.courseHistory);
        break;
      case "sixMonths":
        props.setModifiedCourseList(
          filterFunctions.filterSixMonths(props.courseHistory)
        );
        break;
      case "twelveMonths":
        props.setModifiedCourseList(
          filterFunctions.filterTwelveMonths(props.courseHistory)
        );
        break;
      default:
        return props.modifiedCourseHistory;
    }
  }, [filterValue]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to handle click action for show or hide filter
  const handleFilterClick = () => {
    setActivateFilter(!activateFilter);
  };

  // function to handle option change for filter
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  // This function helps populate the course options given to users
  // when they use the search bar
  const setOptionsArray = () => {
    if (props.courseHistory !== {} || props.courseHistory.length > 0) {
      const mySet = new Set();
      mySet.add("All Courses");
      for (let i = 0; i < props.courseHistory.length; i++) {
        let newCourse = `${props.courseHistory[i].courseId}: ${props.courseHistory[i].courseTitle}`;
        mySet.add(newCourse);
      }
      return [...mySet];
    }
  };

  // Update reducer state for search courses and pass in array of
  // courses with the same courseId and courseTitle as the one selected.
  const optionSelect = (event, value) => {
    if (value === null) {
      return null;
    }
    if (value !== undefined) {
      if (value === "All Courses") {
        props.setModifiedCourseList(props.courseHistory);
      } else {
        let courses = [];
        const selectedCourse = value.split(" ").slice(1).join(" ");
        for (let course of props.courseHistory) {
          if (selectedCourse === course.courseTitle) {
            courses.push(course);
          }
        }
        props.setModifiedCourseList(courses);
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      md={12}
      sm={12}
      xs={12}
      className={matches ? classes.mainContainerMobile : classes.mainContainer}
    >
      <div className={classes.searchBarPaperContainer}>
        <Autocomplete
          freeSolo
          id="AutocompleteSearchBar"
          disableClearable
          options={setOptionsArray()}
          onChange={(event, value) => optionSelect(event, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter Course Name or ID (format: ENG/100)"
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <Button
                    aria-label="search"
                    size="large"
                    className={classes.searchButton}
                    onClick={(event, value) => optionSelect(event, value)}
                  >
                    <SearchIcon
                      className={classes.searchIcon}
                      data-testid="searchIcon"
                    />
                  </Button>
                ),
              }}
              style={{ background: "#FFFFFF" }}
            />
          )}
        />
      </div>

      <br />
      <Box
        className={
          matches ? classes.filterContainerMobile : classes.filterContainer
        }
      >
        <Box className={classes.toggleButtonContainer}>
          <Button
            className={classes.filterButton}
            aria-expanded={activateFilter}
            variant="text"
            data-testid="filterToggleButton"
            onClick={handleFilterClick}
            classes={{
              root: classes.filterButtonRoot,
            }}
          >
            <img
              src={filter}
              alt=""
              className={classes.filterIcon}
              data-testid="filterIcon"
            />
            {activateFilter ? `Hide Filters` : `Show Filters`}
          </Button>
        </Box>
        <Box className={classes.selectContainer}>
          {activateFilter && (
            <FormControl
              className={
                matches
                  ? classes.filterSelectFormMobile
                  : classes.filterSelectForm
              }
              variant="standard"
            >
              <Select
                aria-label="filter-options-select-bar"
                aria-expanded={selectExpand}
                onOpen={() => {
                  setSelectExpand(true);
                }}
                onClose={() => {
                  setSelectExpand(false);
                }}
                id="filterSelectBar"
                data-testid="filterSelectBar"
                value={filterValue}
                onChange={handleFilterChange}
                className={classes.filterSelect}
                classes={{
                  root: classes.filterSelectRoot,
                  icon: classes.filterDropDownIcon,
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  value="allDates"
                  name="allDates"
                  data-testid="filterMenuItem"
                >
                  {`All Start Dates`}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  value="sixMonths"
                  data-testid="filterMenuItem"
                >
                  {`Last 6 Months`}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  value="twelveMonths"
                  data-testid="filterMenuItem"
                >
                  {`Last 12 Months`}
                </MenuItem>
              </Select>
              <FormHelperText className={classes.formHelper}>
                {`Start Date`}
              </FormHelperText>
            </FormControl>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    courseHistory: state.courseHistory,
    modifiedCourseHistory: state.modifiedCourseHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModifiedCourseList: (courses) =>
      dispatch(setModifiedCourseList(courses)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
