import {
  ACCEPT_SOLICITATIONS,
  GET_SOLICITATIONS,
  DECLINE_SOLICITATIONS
} from "./actionTypes";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  switch (action.type) {
    case GET_SOLICITATIONS:
      return action.payload;
    // case ACCEPT_SOLICITATIONS:
    //   console.log(action.payload)
    //   return action.payload;
    // case DECLINE_SOLICITATIONS:
    //   console.log(action.payload)
    //   return action.payload;
    default:
      return state;
  }
};
