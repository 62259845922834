import styles from "./NoSolicitationsCard.styles";

import {
  Typography,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";

import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@material-ui/core";

const NoSolicitationsCard = (props) => {
  const classes = styles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  let available = props.available;

  return matches ? (
    <Box
      className={classes.mainContainerMobile}
      sx={
        available
          ? {
              borderLeft: "5px solid #DC3727",
            }
          : {
              borderLeft: "5px solid #98A3A9",
            }
      }
    >
      <Card>
        <CardContent>
          <Box className={classes.responseMobile}>
            <Typography>{props.content}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <Box
      className={classes.mainContainer}
      sx={
        available
          ? {
              borderLeft: "5px solid #DC3727",
            }
          : {
              borderLeft: "5px solid #98A3A9",
            }
      }
    >
      <Box className={classes.infoContainer}>
        <Typography>{props.content}</Typography>
      </Box>
    </Box>
  );
};

export default NoSolicitationsCard;
