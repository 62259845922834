import { connect } from "react-redux";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import facultyPortalStyles from "../../../styles";
import useStyles from "../styles";
import CoursePrefCard from "./CoursePrefCard";
import ContactCard from "../../../components/SchedulerCard";
import CoursesByPriority from "./coursesByPriority/PreferredCourses";
import { navigateOffPage } from "../../../redux/coursesPreferences/coursesPreferencesActions";
import BlackArrow from "../../../images/BlackArrow.svg";
import facultyPortalTheme from "../../../facultyPortalTheme";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";


//const anchor = document.querySelector("#courseHistoryBox");
const CoursePreferences = (props) => {
  const classes = useStyles();
  const facultyClasses = facultyPortalStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const desktopView = useMediaQuery(theme.breakpoints.up("md"));

  document.title = "Faculty Portal Course Preferences";

  //const [navigateOff, setNavigateOff] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    if(props.errorMessages.length > 1) {
      setOpenSuccess(true);
    }
  }, [props.errorMessages])

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          variant={snackbarType}
          message={message}
          noRetryButton="true"
        />
      </Snackbar>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleBackClick = () => {
    if (props.registerChange === true) {
      let confirmAction = window.confirm(
        "\nYou have unsaved changes! \n\nAre you sure you wish to navigate off this page?"
      );
      if (confirmAction === false) {
        props.navigateOffPage(false);
      }
      if (confirmAction) {
        props.navigateOffPage(true);
      }
    }
    if (props.registerChange === false) {
      props.navigateOffPage(true);
    }
  }

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <div
        className={
          matches
            ? facultyClasses.pageContainer
            : facultyClasses.pageContainerMobile
        }
        id="main"
        role="main"
      >
        <Grid
          className={matches ? classes.linkBox : classes.linkBoxMobile}
          onClick={handleBackClick}
        >
          <Link
            underline="none"
            sx={{
              color: "#001823",
              display: "flex",
              justifyContent: "space-between",
              "&hover": {
                cursor: "pointer",
              },
            }}
            href={props.navigateOff ? "/" : null}
          >
            <img
              className={classes.arrowImage}
              src={BlackArrow}
              alt="arrowImage"
            />
            <Typography>{`Back to Course Dashboard`}</Typography>
          </Link>
        </Grid>
        <Grid
          container
          md={9}
          sm={12}
          xs={11}
          direction="column"
          className={matches ? classes.mainContent : classes.mainContentMobile}
        >
          <Grid item>
            <Typography
              variant="h1"
              component="h1"
              className={matches ? classes.title : classes.mobileTitle}
            >
              {`Course Preferences`}
            </Typography>
          </Grid>

          <Grid
            container
            spacing={3}
            className={matches ? classes.subContent : classes.mobileSubContent}
          >
            <Grid item md={8}>
              <CoursesByPriority />
            </Grid>
            <Grid
              item
              md={2}
              className={!matches ? classes.mobileCardContainer : ""}
            >
              <CoursePrefCard
                title="Course History"
                content="Visit My Courses page to view course history."
                linkName="View course history"
                link="/mycourses"
              />
              <CoursePrefCard
                title="Time Off"
                content="Contact your scheduler or email Online Faculty Scheduling with questions."
                linkName="Human Resources"
                link={`${process.env.REACT_APP_HUMAN_RESOURCES_URL}`}
              />
              <ContactCard />
            </Grid>
          </Grid>
        </Grid>
      </div>
      {openSuccess &&
        (props.errorMessages.length > 1
          ? renderSnackbar("error", "Oops! Something went wrong.")
          : "")}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    registerChange: state.registerChange,
    navigateOff: state.navigateOff,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateOffPage: (change) => dispatch(navigateOffPage(change)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursePreferences);
