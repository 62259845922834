import { useState, useEffect, useMemo, useCallback } from "react";
import { Typography, Grid } from "@mui/material";
import useStyles from "./MyWorkshops.styles";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Checkbox } from "@mui/material";
import { connect } from "react-redux";
import NoWorkshopsCard from "./NoWorkshopsCard";
import CourseCard from "../../dashboard/components/currentCourses/CourseCard";
import FormControlLabel from "@mui/material/FormControlLabel";

const MyWorkshops = (props) => {
  const { setValue, setTitle } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [noWorkshops, setNoWorkshops] = useState(true);
  const [checkboxState, setCheckboxState] = useState({
    viewAll: true,
    completed: false,
    registered: false,
    waitlisted: false,
    facilitated: false,
    observed: false
    //withdrawn: false,
  });

  const [workshopsToDisplay, setWorkshopsToDisplay] = useState([]);

  const handleDiscardWithdrawn = (sectionsForIrnList) => {
    const tempList = sectionsForIrnList.filter((section) => {
      if (section.status !== "Dropped" && section.active) {
        return section;
      }
      return null;
    });
    return tempList;
  };

  const handleFutureSectionsForIrn = (sectionsForIrnList, futureUpcomingWS) => {
    const irnSectionsMinusWithrawn = handleDiscardWithdrawn(sectionsForIrnList);
    const tempList = irnSectionsMinusWithrawn.map((section) => {
      if (new Date(section.startDate) > new Date()) {
        //for future sections, get the capacity for displaying register now or join the waitlist, if needed later
        for (let i = 0; i < futureUpcomingWS.length; i++) {
          if (
            section.courseCode === futureUpcomingWS[i].courseCode &&
            section.startDate === futureUpcomingWS[i].startDate
          ) {
            //myWorkshopFound = true;
            section.capacity = futureUpcomingWS[i].capacity;
            section.noOfParticipants = futureUpcomingWS[i].noOfParticipants;
            return section;
          }
        }
        //myWorkshopFound = false
      }
      return section;
    });
    return tempList;
  };

  useEffect(() => {
    if (props.sectionsForIrn.length > 0) {
      setNoWorkshops(false);
    }
  }, [props.sectionsForIrn]);

  // useEffect(() => {
  //   if (!completed && !registered && !facilitated && !observed && !withdrawn) {
  //     setViewAll(true);
  //   }
  // }, [completed, registered, facilitated, observed, withdrawn]);

  useEffect(() => {
    if (
      props.workshops !== undefined &&
      props.sectionsForIrn !== undefined &&
      props.workshops.length > 0 &&
      props.sectionsForIrn.length > 0
    ) {
      setWorkshopsToDisplay(
        handleFutureSectionsForIrn(props.sectionsForIrn, props.workshops)
      );

      console.log("WorkshopsToDisplay" + JSON.stringify(workshopsToDisplay));
    }
  }, [props.workshops, props.sectionsForIrn]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    viewAll,
    completed,
    registered,
    waitlisted,
    facilitated,
    observed//,
    //withdrawn,
  } = checkboxState;

  const handleChange = useCallback((event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      viewAll: name === "viewAll" ? checked : false,
      completed: name === "viewAll" ? false : prevState.completed,
      registered: name === "viewAll" ? false : prevState.registered,
      waitlisted: name === "viewAll" ? false : prevState.waitlisted,
      facilitated: name === "viewAll" ? false : prevState.facilitated,
      observed: name === "viewAll" ? false : prevState.observed,
      //withdrawn: name === "viewAll" ? false : prevState.withdrawn,
      [name]: checked
    }));
  }, []);

  const filterWorkshops = useCallback(
    (workshops) => {
      if (workshops !== undefined && workshops.length > 0) {
        if (
          !completed &&
          !registered &&
          !waitlisted &&
          !facilitated &&
          !observed /*&&
          !withdrawn*/
        ) {
          return workshops;
        }
        const filters = [
          { condition: completed, status: "Completed" },
          { condition: registered, status: "Scheduled", role: "STUDENT" },
          {
            condition: waitlisted,
            status: "Waitlisted",
          },
          { condition: facilitated, role: "FACULTY" },
          { condition: observed, role: "OBSERVER" }//,
          //{ condition: withdrawn, status: "Dropped" },
        ];
        return filters.reduce((filteredWorkshops, filter) => {
          if (filter.condition) {
            const newWorkshops = workshops.filter((workshop) => {
              if (filter.status === "Waitlisted or Queued to be scheduled") {
                return workshop.status === filter.status;
              }
              if (filter.status === "Scheduled") {
                return (
                  workshop.status === filter.status &&
                  workshop.role === filter.role
                );
              }
              /*
              if (filter.status === "Dropped") {
                return workshop.status === filter.status;
              }*/
              return (
                workshop.status === filter.status ||
                (workshop.role === filter.role && workshop.status !== "Dropped")
              );
            });
            return [
              ...filteredWorkshops,
              ...newWorkshops.filter(
                (workshop) => !filteredWorkshops.includes(workshop)
              ),
            ];
          }
          return filteredWorkshops;
        }, []);
      }
    },
    [
      viewAll,
      completed,
      registered,
      waitlisted,
      facilitated,
      observed
    ]
  );

  const sortWorkshopsByStartDate = useCallback((workshops) => {
    if (workshops !== undefined && workshops.length > 0) {
      return [...workshops].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );
    }
  }, []);

  const sortedWorkshops = useMemo(() => {
    return sortWorkshopsByStartDate(filterWorkshops(workshopsToDisplay));
  }, [sortWorkshopsByStartDate, filterWorkshops, workshopsToDisplay]);

  useEffect(() => {
    if (
      props.sectionsForIrn !== undefined &&
      props.sectionsForIrn.length === 0
    ) {
      setWorkshopsToDisplay(props.workshops);
    }
  }, [props.workshops, props.sectionsForIrn]); // eslint-disable-line react-hooks/exhaustive-deps

  return matches ? (
    <>
      <Typography
        component="h2"
        sx={{ fontWeight: 700, fontSize: "32px", marginBottom: "40px" }}
      >{`My workshops`}</Typography>
      <Typography>{`Filter results: `} </Typography>
      <Grid container>
        {/* <Grid item className={classes.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                id="viewAllCheckbox"
                name="viewAll"
                color="black"
                defaultChecked
                checked={viewAll}
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={viewAll ? "" : classes.greyText}
                sx={{ marginTop: "2px" }}
              >
                View all
              </Typography>
            }
          />
        </Grid> */}

        <Grid container className={classes.checkboxContainer}>
          <Grid item className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  id="completedCheckbox"
                  name="completed"
                  color="black"
                  checked={completed}
                  onClick={handleChange}
                />
              }
              label={
                <Typography
                  className={completed ? "" : classes.greyText}
                  sx={{ margin: "2px 9px 0 0" }}
                >
                  Completed
                </Typography>
              }
            />
          </Grid>
          <Grid item className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  id="registeredCheckbox"
                  name="registered"
                  color="black"
                  checked={registered}
                  onClick={handleChange}
                />
              }
              label={
                <Typography
                  className={registered ? "" : classes.greyText}
                  sx={{ margin: "2px 9px 0 0" }}
                >
                  Registered
                </Typography>
              }
            />
          </Grid>

          <Grid item className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  id="waitlistedCheckbox"
                  name="waitlisted"
                  color="black"
                  checked={waitlisted}
                  onClick={handleChange}
                />
              }
              label={
                <Typography
                  className={waitlisted ? "" : classes.greyText}
                  sx={{ margin: "2px 9px 0 0" }}
                >
                  Waitlisted
                </Typography>
              }
            />
          </Grid>

          {/* <Grid item className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  id="withdrawnCheckbox"
                  name="withdrawn"
                  color="black"
                  checked={withdrawn}
                  onClick={handleChange}
                />
              }
              label={
                <Typography
                  className={withdrawn ? "" : classes.greyText}
                  sx={{ margin: "2px 9px 0 0" }}
                >
                  Withdrawn
                </Typography>
              }
            />
          </Grid> */}

          <Grid item className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  id="facilitatedCheckbox"
                  name="facilitated"
                  color="black"
                  checked={facilitated}
                  onClick={handleChange}
                />
              }
              label={
                <Typography
                  className={facilitated ? "" : classes.greyText}
                  sx={{ margin: "2px 9px 0 0" }}
                >
                  Facilitated
                </Typography>
              }
            />
          </Grid>
          <Grid item className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  id="observedCheckbox"
                  name="observed"
                  color="black"
                  checked={observed}
                  onClick={handleChange}
                />
              }
              label={
                <Typography
                  className={observed ? "" : classes.greyText}
                  sx={{ margin: "2px 9px 0 0" }}
                >
                  Observed
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {noWorkshops ? (
        <Grid item sx={{ padding: "50px 20px 100px 0" }}>
          <NoWorkshopsCard setValue={setValue} />
        </Grid>
      ) : (
        <Grid
          item
          sm={12}
          md={12}
          lg={10}
          xl={7}
          sx={{ padding: "50px 20px 100px 0" }}
        >
          {sortedWorkshops !== undefined &&
            sortedWorkshops.length > 0 &&
            sortedWorkshops.map((workshop) => (
              <>
                <CourseCard
                 type="myWorkshops"
                 currentCourse={{
                    courseId: workshop.courseCode,
                    courseOfferingId: workshop.id,
                    courseTitle: workshop.courseTitle,
                    description: workshop.templateDesc,
                    endDate: workshop.endDate,
                    offeringType: "WS",
                    rosterId: workshop.rosterId,
                    schedulingRole: workshop.role,
                    groupId: workshop.groupId,
                    status: workshop.status,
                    startDate: workshop.startDate,
                    capacity: workshop.capacity,
                    noOfParticipants: workshop.noOfParticipants,
                    facultyName: `${props.demographics.firstName} ${props.demographics.lastName}`,
                  }}
                  workshopPageCard={true}
                  wsUser={props.wsUser}
                />
              </>
            ))}
        </Grid>
      )}
    </>
  ) : (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        component="h2"
        sx={{
          fontWeight: 700,
          fontSize: "24px",
          margin: "52px 0px 30px 18px",
        }}
      >{`My workshops`}</Typography>
      <Typography sx={{ marginLeft: "18px" }}>{`Filter results: `} </Typography>
      <Box sx={{ marginLeft: "20px" }}>
        {/* <Box className={classes.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                id="viewAllCheckbox"
                name="viewAll"
                color="black"
                defaultChecked
                checked={viewAll}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={viewAll ? "" : classes.greyText}
                sx={{ marginTop: "2px" }}
              >
                View all
              </Typography>
            }
          />
        </Box> */}
        <Box className={classes.checkboxContainerMobile}>
          <FormControlLabel
            control={
              <Checkbox
                id="registeredCheckbox"
                name="registered"
                color="black"
                checked={registered}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={registered ? "" : classes.greyText}
                sx={{ margin: "2px 25px 0 0" }}
              >
                Registered
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                id="completedCheckbox"
                name="completed"
                color="black"
                checked={completed}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={completed ? "" : classes.greyText}
                sx={{ margin: "2px 25px 0 0" }}
              >
                Completed
              </Typography>
            }
          />
        </Box>

        <Box className={classes.checkboxContainerMobile}>
          <FormControlLabel
            control={
              <Checkbox
                id="waitlistedCheckbox"
                name="waitlisted"
                color="black"
                checked={waitlisted}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={waitlisted ? "" : classes.greyText}
                sx={{ margin: "2px 29px 0 0" }}
              >
                Waitlisted
              </Typography>
            }
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                id="withdrawnCheckbox"
                name="withdrawn"
                color="black"
                checked={withdrawn}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={withdrawn ? "" : classes.greyText}
                sx={{ margin: "2px 25px 0 0" }}
              >
                Withdrawn
              </Typography>
            }
          /> */}
        </Box>

        <Box className={classes.checkboxContainerMobile}>
          <FormControlLabel
            control={
              <Checkbox
                id="facilitatedCheckbox"
                name="facilitated"
                color="black"
                checked={facilitated}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={facilitated ? "" : classes.greyText}
                sx={{ margin: "2px 25px 0 0" }}
              >
                Facilitated
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                id="observedCheckbox"
                name="observed"
                color="black"
                checked={observed}
                size="large"
                onClick={handleChange}
              />
            }
            label={
              <Typography
                className={observed ? "" : classes.greyText}
                sx={{ margin: "2px 25px 0 0" }}
              >
                Observed
              </Typography>
            }
          />
        </Box>
      </Box>

      {noWorkshops ? (
        <Grid item sx={{ padding: "50px 20px 100px 0px" }}>
          <NoWorkshopsCard setValue={setValue} setTitle={setTitle} />
        </Grid>
      ) : (
        <Box sx={{ padding: "50px 0px 100px 0px" }}>
          {sortedWorkshops !== undefined &&
            sortedWorkshops.length > 0 &&
            sortedWorkshops.map((workshop) => (  
              <CourseCard
                type="myWorkshops"
                currentCourse={{
                  courseId: workshop.courseCode,
                  courseOfferingId: workshop.id,
                  courseTitle: workshop.courseTitle,
                  description: workshop.templateDesc,
                  endDate: workshop.endDate,
                  offeringType: "WS",
                  rosterId: workshop.rosterId,
                  schedulingRole: workshop.role,
                  groupId: workshop.groupId,
                  status: workshop.status,
                  startDate: workshop.startDate,
                  capacity: workshop.capacity,
                  noOfParticipants: "",
                  facultyName: `${props.demographics.firstName} ${props.demographics.lastName}`,
                }}
                workshopPageCard={true}
                wsUser={props.wsUser}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    sectionsForIrn: state.sectionsForIrn,
    workshops: state.workshops,
  };
};

export default connect(mapStateToProps)(MyWorkshops);
