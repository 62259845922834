import { GET_FEOCSINVITE } from "./actionTypes";

// eslint-disable-next-line 
export default (state = {}, action) => {
    switch(action.type) {
       case GET_FEOCSINVITE:
          return action.payload;
       default:
          return state;  
    }
  }