import styles from "./Accordion.styles";
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@material-ui/core";
import ClassList from "./ClassList";

const Accordion = () => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      className={matches ? classes.mainContainer : classes.mainContainerMobile}
    >
      <Box>
        <ClassList data-testid="courseListSection" />
      </Box>
    </Box>
  );
};

export default Accordion;
