import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // Desktop View
  desktopCard: {
    width: "257px",
    fontFamily: "Roboto",
    marginBottom: "7%",
  },
  desktopCardContent: {
    margin: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  desktopLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "5%",
  },
  noSolicitCard: {
    width: "257px",
    fontFamily: "Roboto",
    marginBottom: "7%",
  },
  noSolicitCardContent: {
    margin: "20px 9px 4px 8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "180px",
  },

  // Mobile View
  mobileCard: {
    width: "327px",
    marginBottom: "24px",
  },
  mobileCardContent: {
    padding: "0px !important",
    margin: "25px",
  },
  mobileLink: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: "12px",
  },

  // Universal for Desktop and Mobile View
  linkBox: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
