// import Icon from '@/components/atoms/icon';
// import { withTransientProps } from '@/helpers/with-transient-props';
import { Box, Dialog, styled, InputLabel, FilledInput, Typography, Button, Icon } from '@mui/material';
import { filledInputClasses } from '@mui/material/FilledInput';
// import StandardButton from '@/components/atoms/standard-button';

export const StyledDialog = styled(Dialog)(() => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}));

export const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: theme.spacing(),
  marginTop: theme.spacing(1),
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 0.8, 0.6),
  fontSize: theme.spacing(1.6),
  color: "black",
  fontWeight: 400,
  letterSpacing: 'normal',
  outline: 'none',
  borderRadius: theme.spacing(0.25),
  minWidth: 'unset',
  '&:hover': {
    // backgroundColor: theme.palette.action.hover,
  },
}));

export const CloseButtonLabel = styled('span')(({ theme }) => ({
  width: 'auto',
  borderBottom: `${theme.spacing(0.3)} solid black}`,
  '&:hover': {
    // borderBottomColor: theme.palette.primary.main,
  },
}));

export const TitleTypography = styled('h2')(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  fontFamily: "Roboto",
  color: "#001823",
  fontSize: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(2.4),
  },
}));

export const SubtitleTypography = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(2.0),
  marginBottom: theme.spacing(1.4),
  marginTop: theme.spacing(1.6),
  fontFamily: 'Roboto',
  color: "#001823",
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2.4),
  },
}));

export const EmotionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'nowrap',
  [theme.breakpoints.up('xs')]: {
    gap: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    gap: 'unset',
    flexWrap: 'wrap',
  },
}));

export const EmotionButton = styled(Button)(({ theme }) => ({
  background: "transparent",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  cursor: "pointer",
  height: theme.spacing(10),
  minWidth: "unset",
  borderRadius: theme.spacing(0.4),
  border: `#5E7079 solid 1px`,
  color: "#5E7079",
  fontSize: "36px",
  "&:hover": {
    background: "transparent",
  },
  "&:focus": {
    borderColor: "#DB3725",
    color: "#DB3725",
  },
  "& .MuiTouchRipple-child": {
    backgroundColor: "#2D2D2D",
    "&:not(:active)": {
      backgroundColor: "#2D2D2D",
    },
  },
  [theme.breakpoints.up("xs")]: {
    flex: "1 1 0",
  },
  [theme.breakpoints.up("sm")]: {
    flex: "unset",
    borderStyle: "solid",
    // borderWidth: $selected ? theme.spacing(0.2) : theme.spacing(0.1),
    // borderColor: $selected ? theme.palette.primary.dark : theme.palette.almostBlack.l50,
    minWidth: theme.spacing(9),
    borderRadius: theme.spacing(0.4),
  },
}));

export const EmotionIcon = styled(Icon)(({ theme }) => ({
  height: theme.spacing(2.5),
  width: theme.spacing(2.5),
  // fill: theme.palette.primary.dark,
}));

export const EmotionLabel = styled('span')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  color: "#5E7079",
  fontSize: theme.spacing(1.6),
}));

export const FeedbackLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  // color: theme.palette.almostBlack.l30,
  [theme.breakpoints.up('xs')]: {
    fontSize: theme.spacing(1.8),
  },
}));

export const FeedbackTextField = styled(FilledInput)(({ theme }) => ({
  // disabled but left here in case functionality is desired
  // minHeight: theme.spacing(10),
  [`&.${filledInputClasses.root}`]: {
    backgroundColor: "white",
    border: "#5E7079 1px solid",
    borderRadius: theme.spacing(0.4),
    [theme.breakpoints.up('xs')]: {
      fontSize: theme.spacing(1.6),
    },
  },
  '&:hover': {
    // outline: `${theme.spacing(0.1)} solid ${theme.palette.almostBlack.main}`,
  },
  [`&.${filledInputClasses.root}.${filledInputClasses.focused}`]: {
    // outline: `${theme.spacing(0.2)} solid ${theme.palette.almostBlack.main}`,
  },
}));

export const ContactLabel = styled("p")(({ theme }) => ({
  fontSize: theme.spacing(1.7),
  lineHeight: theme.spacing(3),
  fontWeight: "700",
  color: "#001823",
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingRight: theme.spacing(0.3),
    fontSize: theme.spacing(1.6),
    color: "#001823",
  },
}));
