import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: "32px",
    marginBottom: "40px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkboxText: {
    margin: "2px 15px 0 0",
  },
  greyText: {
    color: " #5E7079",
  },
  checkboxContainerMobile: {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  },
  hide: {
    display: "none",
  },
  icon: {
    marginLeft: "189px",
  },
  redText: {
    color: "#DB3725",
  },
  pointer: {
    "&hover": {
      cursor: "pointer",
    },
  },
  mobileContainer: {
    // margin: "18px",
    // width: "327px",
    alignItems: "center",
    // marginTop: "60px",
    margin: "60px auto 0 auto",
    paddingBottom: "60px",
  },
  mobileTitle: {
    fontWeight: 700,
    fontSize: "24px",
    marginBottom: "40px",
    alignSelf: "flex-start",
  },
}));

export default useStyles;
