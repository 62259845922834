import { makeStyles, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";

import SortByBar from "./SortByBar";
import { connect } from "react-redux";

const useStyles = makeStyles({
  // Desktop View
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    margin: "0px 0px 30px 0px",
  },
  sortContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "top",
  },

  // Mobile View
  headerMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    margin: "0px 0px 30px 0px",
  },
  sortContainerMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },

  // Universal for both Desktop and Mobile View
  courseAmount: {
    alignItems: "top",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#000000",
    margin: "0px 0px 20px 0px",
  },
});

const HeaderSortBox = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [numOfCourses, setNumOfCourses] = useState("");

  useEffect(() => {
    setNumOfCourses("All Courses");
  }, []);

  useEffect(() => {
    if (props.modifiedCourseHistory.length === 0) {
      setNumOfCourses("0 Courses");
    }
    if (props.modifiedCourseHistory.length > 0) {
      setNumOfCourses(
        props.modifiedCourseHistory.length === 1
          ? `${props.modifiedCourseHistory.length} Course`
          : `${props.modifiedCourseHistory.length} Courses`
      );
    }
    if (props.modifiedCourseHistory.length === props.courseHistory.length) {
      setNumOfCourses("All Courses");
    }
  }, [props.modifiedCourseHistory.length, props.courseHistory.length]);

  return (
    <Box className={matches ? classes.headerMobile : classes.header}>
      <Typography
        aria-label="number-of-courses-displayed"
        id="numOfCourses"
        data-testid="numOfCourses"
        component="h2"
        className={classes.courseAmount}
      >
        {`${numOfCourses}`}
      </Typography>
      <Box
        className={
          matches ? classes.sortContainerMobile : classes.sortContainer
        }
      >
        <SortByBar />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    courseHistory: state.courseHistory,
    modifiedCourseHistory: state.modifiedCourseHistory,
  };
};

export default connect(mapStateToProps)(HeaderSortBox);
