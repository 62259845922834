import { createUserManager, } from 'redux-oidc';
import { WebStorageStateStore, Log, } from 'oidc-client';

if (process.env.REACT_APP_OIDC_DEBUG === 'true') {
  	Log.logger = console;
  	Log.level = Log.DEBUG;
}

const userManagerConfig = {
  	authority: process.env.REACT_APP_OAUTH_AUTHORITY,
	client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
	redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
	silent_redirect_uri: process.env.REACT_APP_OAUTH_SILENT_RENEW_URI,

	post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_URL,
	response_type: "code",
	loadUserInfo: true,
	scope: process.env.REACT_APP_OAUTH_SCOPE,
	filterProtocolClaims: true,
	automaticSilentRenew: true,
	revokeAccessTokenOnSignout: true,
	// The time in seconds to refresh before the token expired time in seconds
    accessTokenExpiringNotificationTime: 300,
    stateStore: new WebStorageStateStore({ prefix: 'faculty-ui.', store: window.sessionStorage }),
	// Remove the default storing of token information in session storage
	/* userStore: new WebStorageStateStore({
		store: {
		  getItem: () => null,
		  setItem: () => null,
		  removeItem: () => null,
		},
	}), */
	monitorSession: false,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;