import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    marginBottom: "20px",
  },
  description: {
    marginBottom: "20px",
  },
  mobileCard: {
    margin: "0 18px 0 18px",
  },
}));

export default useStyles;
