import styles from "./AccessAndInstructionsCard.styles";
import { Typography, Button } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@material-ui/core";

const AccessAndInstructionsCard = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return matches ? (
    <>
      <div className={classes.cardContainer}>
        <Typography className={classes.title} component="h2">
          {`ACCESS AND INSTRUCTIONS`}
        </Typography>
        <Typography className={classes.subHeading} component="h3">
          {`If you meet any of the scenarios below and do not have access to submit, you may be eligible to request access to submit expenses:`}
        </Typography>
        <div className={classes.bottomMargin}>
          <ul>
            <li
              className={classes.listItem}
            >{`Traveled 30 miles or greater one way for teaching`}</li>
            <li
              className={classes.listItem}
            >{`You have received prior approval from your supervisor to submit for reimbursement (meals, hotel, etc.)`}</li>
          </ul>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.emailButton}
            aria-label="open email for expense access"
            href={process.env.REACT_APP_EMAIL_SUBMIT_EXPENSE_ACCESS_URL}
          >
            <Typography className={classes.emailButtonText}>
              {`Email for access`}
            </Typography>
          </Button>
          <Button
            className={classes.submitExpenseButton}
            aria-label="Go to submit expenses"
            href={process.env.REACT_APP_SUBMIT_EXPENSES_URL}
            target="_blank"
          >
            <Typography className={classes.submitButtonText}>
              {`Submit expenses`}
            </Typography>
          </Button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={classes.mobileCardContainer}>
        <Typography className={classes.title} component="h2">
          {`ACCESS AND INSTRUCTIONS`}
        </Typography>
        <Typography className={classes.subHeading} component="h3">
          {`If you meet any of the scenarios below and do not have access to submit, you may be eligible to request access to submit expenses:`}
        </Typography>
        <div className={classes.bottomMargin}>
          <ul>
            <li
              className={classes.listItem}
            >{`Traveled 30 miles or greater one way for teaching`}</li>
            <li
              className={classes.listItem}
            >{`You have received prior approval from your supervisor to submit for reimbursement (meals, hotel, etc.)`}</li>
          </ul>
        </div>

        <div className={classes.centerButtonContainerMobile}>
          <div>
            <Button
              className={classes.emailButtonMobile}
              aria-label="open email for expense access"
              href={process.env.REACT_APP_EMAIL_SUBMIT_EXPENSE_ACCESS_URL}
            >
              <Typography className={classes.emailButtonText}>
                {`Email for access`}
              </Typography>
            </Button>
            <Button
              className={classes.submitExpenseButtonMobile}
              aria-label="Go to submit expenses"
              href={process.env.REACT_APP_SUBMIT_EXPENSES_URL}
              target="_blank"
            >
              <Typography className={classes.submitButtonText}>
                {`Submit expenses`}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessAndInstructionsCard;
