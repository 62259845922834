import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";


export const StyledFeedback = styled(Button)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    outline: "none",
    width: "80px",
    boxShadow:
      "0.1rem 0.1rem 0.8rem -0.3rem rgba(0,0,0,0.4), 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.05)",
    "&:hover": {
      backgroundColor: "#E5EAED",
    },
  },
  zIndex: "9",
  height: "30px",
  minWidth: "2rem",
  backgroundColor: "white",
  boxShadow:
    "0.1rem 0.1rem 0.8rem -0.3rem rgba(0,0,0,0.4), 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.05)",
  transform: "rotate(-90deg)",
  position: "fixed",
  bottom: "50%",
  right: "-2.3rem",
  [theme.breakpoints.up("md")]: {
    "&.MuiButtonBase-root": {
      right: "-2rem",
      width: "101px",
      height: "44px",
      minWidth: "3.0rem",
      bottom: "50%",
      outline: "none",
      boxShadow:
        "0.1rem 0.1rem 0.8rem -0.3rem rgba(0,0,0,0.4), 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.05)",
      "&:hover": {
        backgroundColor: "#E5EAED",
      },
    },
  },
}));
export const ButtonText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: "0.7rem",
    marginBottom: "13px",
    color: "#3A4F59"
  },
  fontFamily: "Roboto",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    "&.MuiTypography-root": {
      fontSize: "14px",
      marginBottom: "0"
    },
    fontFamily: "Roboto",
  },
}));
export const ButtonContainer = styled("div")(() => ({
  position: "relative",
}));
