import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  // Universal used for both Desktop and Mobile View
  container: {
    float: "right",
    width: "289px",
    height: "100%",
    background: "#FFFFFF",
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "50px",
    padding: "10px 10px 0px 10px",
  },
  closeButton: {
    width: "20%",
    height: "50%",
    "&:focus": {
      border: "2px solid #000000",
      borderRadius: "1px",
    },
  },
  closeIcon: {
    color: "#001823",
    transform: "scale(1.5)",
  },
  sectionContainer: {
    paddingBottom: "20px",
    paddingLeft: "34px",
  },
  title: {
    width: "222px",
    height: "16px",
    fontWeight: "bold",
    letterSpacing: "0.01em",
    textTransform: "uppercase",
    color: "#DC3727",
    paddingBottom: "15px",
    fontSize: "16px",
  },
  link: {
    "&:hover":{
      textDecorationColor: "black",
    }

  },
  linkText: {
    //fontFamily: "Roboto",
    //fontStyle: "normal",
    paddingBottom: "2px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "16px",
    color: "#001823",
  },
  linkContainer: {
    paddingBottom: "4px",
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
  },
  iconText: {
    paddingLeft: "10px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "16px",
    paddingBottom: "2px",
    color: "#001823",
  },
  bottomContainer: {
    paddingLeft: "34px",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "5px",
    color: "#001823",
  },
  unorderedList: {
    listStyleType: "none",
    margin: "0",
    padding: "0",
  },
}));

export default useStyles;
